import { AccountImage404 } from "@Helpers/ImageRoutes/Assets";
import { Button } from "@material-ui/core";
import { REST } from "@Utils";
import React, { useEffect, useState } from "react";
import { rURL } from "../../../../Config/Axios/config";


const ProfilePhoto = (props) => {
  const [imgSrc, setImgSrc] = useState();
  // eslint-disable-next-line
  const [imgBase64, setImgBase64] = useState("");
  // eslint-disable-next-line
  const [coded, setCoded] = useState();
  // eslint-disable-next-line
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState({ message: null });

  const getData = async (event) => {
    await REST.get(`/user/profile`)
      .then((answer) => {
        console.log("ANS PHOTO: ", answer.data);
        if (answer.data.body.imageUrl.length) {
          setImgSrc(`${rURL}` + answer.data.body.imageUrl);
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          // const { message } = err.response.data;
          // console.log("ERORR PHOTO:  ", message);
        }
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 3000);
    // eslint-disable-next-line
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      if (file.type.match("image.*")) {
        reader.onload = () => {
          let codedURL = reader.result;
          setCoded(codedURL);

          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          setHasLoaded(true);
        };
      } else {
        setError({ message: "Please select a valid image file" });
      }
      reader.onerror = (error) => reject(error);
    });
  };

  const getTheFile = async () => {
    const file = document.querySelector("#upload-photo").files[0];
    getBase64(file).then((answer) => {
      updateData(answer);
    });
    console.log("base64 img get the file: ", imgBase64);
  };

  const updateData = async (fileBase64String) => {
    var body = { file: fileBase64String };
    await REST.post(`/user/changeProfilePicture`, body)
      .then((answer) => {
        console.log("PROFILE PHOTO UPDATED", answer);
        getData();
        setError({ message: null });
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("ERROR UPLOAD NEW PHOTO: ", message);
        }
      });
  };
  const fileInputClicked = (event) => {
    let file = event.target.files[0];
    if (file) {
      getTheFile();
    } else {
      setError({ message: "Please select a valid image file" });
    }
  };

  return (
    <div className={"Column--3"}>
      <div>
        <div className={"PhotoTitleContainer"}>
          <p className={"PhotoTitle"}>Profile Photo</p>
        </div>
        <div className={"PhotoContainer"}>
          {/* <Button className={"PlusSign"} onClick={handleOnClick}> 
                        <AddIcon />
                    </Button> */}
          <img className={"ProfilePhoto"} src={imgSrc || AccountImage404} alt="" />
        </div>
      </div>
      <div className={"ButtonContainer"}>
        <Button className={"EditButton"}>EDIT</Button>
        <input
          className={"UploadFile"}
          id="upload-photo"
          type="file"
          onChange={fileInputClicked}
        ></input>
        {error && error.message && <p className={"error"}>{error.message}</p>}
      </div>
    </div>
  );
};

export default ProfilePhoto;
