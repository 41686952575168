import React from 'react';

const Agreements = () => {
  return (
    <div className={'agreements-container'}>
      <div className={'agreement-info'}>
        <div className={'agreement-title'}>RIGHTS COLLECTION AGREEMENT</div>
        <div className={'agreement-content'}>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here',
        </div>
        <div className={'read-more'}>
          <a href="/#">READ MORE</a>
        </div>
      </div>

      <div className={'agreement-info'}>
        <div className={'agreement-title'}>COLLECTION ASSIGNMENT AGREEMENT</div>
        <div className={'agreement-content'}>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here',
        </div>
        <div className={'read-more'}>
          <a href="/#">READ MORE</a>
        </div>
      </div>

      <div className={'agreement-info'}>
        <div className={'agreement-title'}>
          ACCOUNT DISTRIBUTION ASSIGNMENT AGREEMENT
        </div>
        <div className={'agreement-content'}>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here',
        </div>
        <div className={'read-more'}>
          <a href="/#">READ MORE</a>
        </div>
      </div>
    </div>
  );
};

export default Agreements;
