import { makeStyles } from '@material-ui/core/styles';
import { theme as ThemeForMUI } from '@Styled/Theme';

export const SignupMUIStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    '& h1': {
      textTransform: 'uppercase',
      margin: `2rem 0 .5rem`,
    },
  },
  signUpArtistsWrap: {
    maxWidth: '960px',
    margin: '0 auto',
  },

  signUpArtists: {
    cursor: 'pointer',
    height: "240px",

    fontSize: ThemeForMUI.fontSizes.body,
    margin: '.8rem',
    textAlign: 'center',
    '& img': {
      border: `3px solid ${ThemeForMUI.colors.primary}`,
      borderRadius: `20px`,
    },
  },

  selectAccType: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  }
}));
