import React from "react";
import { useRouter } from "@Hooks";
import { katasticLogo } from "@Helpers/ImageRoutes/StreamerDetails/";

const Services = () => {
  const Router = useRouter();
  const readMore = () => {
    Router.push({
      pathname: `/marketServices2`
    });
  };

  return (
    <div className={"servicesComponent-root"}>
      <div className={"logo-container"}>
        <img src={katasticLogo} alt="logo" className={"logo"} />
      </div>
      <div className={"info-container"}>
        <h2>KATASTIC - RIGHTS COLLECTION</h2>
        <div className={"rights-container"}>
          <h6 className={"info"}>Info</h6>
          <label className={"content"}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </label>
        </div>
        <div className={"read-more"} onClick={readMore}>
          <h5 className={"btn-text"}>READ MORE</h5>
        </div>
      </div>
    </div>
  );
};

export default Services;
