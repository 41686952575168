import React from 'react';

import { Grid } from '@material-ui/core';
import AccountHeader from './AccountHeader';
import TabsButtons from './TabsButtons';

const Header = () => {
  return (
    <Grid item md={4} lg={4}>
      <div className={'Column--q'}>
        <AccountHeader />
        <TabsButtons />
      </div>
    </Grid>
  );
};

export default Header;
