import React from 'react';

export const LoadingOnPage = props => {
  const { isVisible } = props;
  return (
    <div className={`${isVisible ? '' : 'hide'} localLoading`}>
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>
  );
};
