import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dropdownArrow from '@Images/drop-down-arrow.png';
import { useCloseIntervalSelector } from '@Hooks';
import { v4 as uuidv4 } from 'uuid';

const columns = [
    {
        id: 'artist',
        label: 'ARTIST',
        minWidth: 20
    },
    {
        id: 'pn',
        label: 'PROJECT NAME',
        minWidth: 100
    },
    {
        id: 'pt',
        label: 'PROJECT TYPE',
        minWidth: 70,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'rights',
        label: 'RIGHTS (UNITS)',
        minWidth: 70,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'settled',
        label: 'SETTLED RIGHTS (UNITS)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'unavailable',
        label: 'UNAVAILABLE RIGHTS (UNITS)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'available',
        label: 'AVAILABLE RIGHTS (UNITS)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'average',
        label: 'AVERAGE PRICE',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'invested',
        label: 'INVESTED AMMOUNT',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'date',
        label: 'DATE',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'marketP',
        label: 'MARKET PIRCE',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'percentage',
        label: 'PERCENTAGE CHANGE IN PRICE',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'marketV',
        label: 'MARKET VALUE',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'unitsS',
        label: 'UNITS SOLD',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'ppu',
        label: 'PRICE PER UNIT',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'profitLoss',
        label: 'PROFIT / LOSS',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
    {
        id: 'dailyP',
        label: 'DAILY PRECENTAGE VARIATION',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
];

// function createData(name, code, population, size) {
//     const density = population / size;
//     return { name, code, population, size, density };
// }


export default function RightsEntryTable(props) {
    const { data, tableInterval, handleTableInterval, showChart, handleProjectId, setSelectedEntry, selectedEntry } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    const handleExpandedFilter = () => {
        setIsExpanded(!isExpanded);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useCloseIntervalSelector('intervalSelector', setIsExpanded, false);

    const ExpandedInterval = () => {
        return (
            <div className={'expandedInterval intervalSelector'} style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", marginBottom: "1.75rem" }} onClick={() => handleExpandedFilter()}>
                <div className={`expanded-interval intervalSelector`}>
                    <ul className={`category-list intervalSelector`}>
                        <li className={`category-interval`} onClick={() => { handleTableInterval("1h"); setIsExpanded(false) }}>1h</li>
                        <li className={`category-interval`} onClick={() => { handleTableInterval("1d"); setIsExpanded(false) }}>1d</li>
                        <li className={`category-interval`} onClick={() => { handleTableInterval("1w"); setIsExpanded(false) }}>1w</li>
                        <li className={`category-interval`} onClick={() => { handleTableInterval("1m"); setIsExpanded(false) }}>1m</li>
                        <li className={`category-interval`} onClick={() => { handleTableInterval("1y"); setIsExpanded(false) }}>1y</li>
                    </ul>
                </div>
            </div>
        )
    }

    const IntervalBtn = () => {
        return (
            <div className={"interval intervalSelector"} onClick={() => handleExpandedFilter()}>
                <div className={`portfolio_table_header`}>
                    {tableInterval}
                </div>
                <img src={dropdownArrow} alt="" className={"arrow intervalSelector"} style={{ height: 15, width: 15, marginLeft: "0.5rem" }} />
            </div>
        )
    }

    const handleSelect = (id) => {
        setSelectedEntry(id);
        showChart(id);
        if (window.location.pathname === "/portofolio-rights") {
            handleProjectId(id)
        }

        setIsSelected(!isSelected);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', backgroundColor: "#0d0806" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5", padding: 10, backgroundColor: "#0d0806" }}
                                >
                                    {column.id === "profitLoss" ?
                                        <div>
                                            <div>
                                                {isExpanded ? <ExpandedInterval /> : <IntervalBtn />}
                                            </div>
                                            <div className={`portfolio_table_header`}>
                                                PROFIT / LOSS
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {column.label}
                                        </div>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.map((row) => {
                            return (
                                <TableRow hover selected={true} role="checkbox" tabIndex={-1} key={`${row.id}_${uuidv4()}`} onClick={() => handleSelect(row.id)} style={{ backgroundColor: isSelected && selectedEntry === row.id ? "#ff3366" : "#0d0806" }}>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        {row.artist.name}
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        {row.projectType}
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        {row.numberOfUnitsBuyed}
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        settled
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        unavailable
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        available
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        {row.pricePerUnit}
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        invested ammount
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        date
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        market price
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        % change in price
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        market val
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        units sold
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        $/unit
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        profit / loss
                                    </TableCell>
                                    <TableCell key={`${row.id}_${uuidv4()}`} align={"center"} style={{ textAlign: "center", fontSize: 12, color: "#e8cfc5" }}>
                                        daily % variation
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
