import Grid from "@material-ui/core/Grid";
import { RESTjava } from "@Utils";
import React, { useEffect, useState } from "react";
import Banner from "./Components/Banner/Banner";
import Services from "./Components/Services/Services";


export const MarketServices = () => {
  // eslint-disable-next-line
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getServices = async () => {
    await RESTjava.get(`/api/market/service/getAllServices?page=0&size=100`)
      .then(answer => {
        var data = answer.data.content;
        setServices(data);
        setIsLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  const goToCategory = category => {
    // Router.push({
    //   pathname: `/${category}`
    //   // query: { tab: tab, selectedCategory: selectedCategory },
    // });

    window.location.href = `/${category}`;
  };

  if (isLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container className={"services-root"}>
        {/* <Grid item xs={12} md={12} lg={12} xl={12}>
        <MarketFilters />
      </Grid> */}

        <Grid item xs={12} md={12} lg={12} xl={12} className={"services-body"}>
          <Grid item xs={6} md={6} lg={6} xl={6}>
            <Services />
          </Grid>

          <Grid item xs={6} md={6} lg={6} xl={6}>
            <Banner />
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          xl={12}
          className={"bottom-container"}
        >
          <Grid item xs={3}>
            <div
              className={"ListItem"}
              style={{ borderRadius: "15px" }}
              onClick={() => goToCategory("label")}
            >
              <div>
                <img
                  src="https://images.surferseo.art/2d256325-9caa-4bcb-b252-6ca0542bc2cb.jpeg"
                  style={{
                    height: "250px",
                    width: "250px",
                    borderRadius: "10px",
                    cursor: "pointer"
                  }}
                  alt=""
                />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>LABEL</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={"ListItem"}
              style={{ borderRadius: "15px" }}
              onClick={() => goToCategory("eventOrganizer")}
            >
              <div>
                <img
                  src="https://d3vhc53cl8e8km.cloudfront.net/hello-staging/wp-content/uploads/2017/12/22223742/Events-1200x630.jpg"
                  style={{
                    height: "250px",
                    width: "250px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    resizeMode: "contain"
                  }}
                  alt=""
                />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                EVENT ORGANIZER
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={"ListItem"}
              style={{ borderRadius: "15px" }}
              onClick={() => goToCategory("filmStudio")}
            >
              <div>
                <img
                  src="https://i.pinimg.com/originals/92/5d/63/925d63ad48dff726b775d50a49bd0a8c.jpg"
                  style={{
                    height: "250px",
                    width: "250px",
                    borderRadius: "10px",
                    cursor: "pointer"
                  }}
                  alt=""
                />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                FILM STUDIO
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              className={"ListItem"}
              style={{ borderRadius: "15px" }}
              onClick={() => goToCategory("producer")}
            >
              <div>
                <img
                  src="https://images.unsplash.com/photo-1577190651915-bf62d54d5b36?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvZHVjZXJ8ZW58MHx8MHx8fDA%3D"
                  style={{
                    height: "250px",
                    width: "250px",
                    borderRadius: "10px",
                    cursor: "pointer"
                  }}
                  alt=""
                />
              </div>
              <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                PRODUCER
              </div>
            </div>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} md={12} lg={12} xl={12} className={'bottom-container'}>
          <MerchList services={services} />
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12} className={'bottom-container'}>
          <MerchList services={services} />
        </Grid> */}
      </Grid>
    );
  }
};

export default MarketServices;
