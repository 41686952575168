import List from '@material-ui/core/List';
import { RESTjavaInApp } from "@Utils";
import React, { useEffect, useState, useContext } from 'react';
import { artistRoutes, routes } from './links';
import {
  ShowHeadLink,
  ShowPrimaryLink,
  ShowSecondaryLink
} from './ShowLinkTypes';
import { UserProfileContext } from "src/context/userProfileContext";

export const SideMenu = props => {
  const { dispatch } = useContext(UserProfileContext);
  const { currentPage, asPath } = props;
  const [user, setUser] = useState(null);

  const getData = async () => {
    await RESTjavaInApp.get(`/api/v1/users/getUserProfile`)
      .then((answer) => {
        setUser(answer.data)
        dispatch({ type: "SET_USER", payload: answer.data });
        console.log(`user.userType >>> `, answer)
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
  };

  useEffect(() => {
    getData();
  }, [])

  const routesLinks = user && user.userType === "ARTIST" || user && user.userType === "MANAGER" ? artistRoutes : routes;


  return (
    <div className={`menuDrawer`}>
      <List>
        {routesLinks.map((item, index) => {
          switch (item.type) {
            case 'head':
              return (
                <ShowHeadLink
                  item={item}
                  key={index}
                  index={index}
                  currentPage={currentPage}
                  asPath={asPath}
                />
              );
            case 'primary':
              return (
                <ShowPrimaryLink
                  item={item}
                  key={index}
                  index={index}
                  currentPage={currentPage}
                  asPath={asPath}
                />
              );
            case 'secondary':
              return (
                <ShowSecondaryLink
                  item={item}
                  index={index}
                  key={index}
                  currentPage={currentPage}
                  asPath={asPath}
                />
              );
            default: return null
          }
        })}
      </List>
    </div>
  );
};

// export default SideMenu;
