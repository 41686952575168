import React from "react";
import { Line } from "react-chartjs-2";

// Function to generate random number
function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

// eslint-disable-next-line
export default props => {
  const { dataset, chartLastValue } = props;
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "September",
      "October",
      "November"
    ],
    options: {
      legend: "none"
    },

    datasets: [
      {
        label: "Values",
        labels: [""],
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(255,51,102,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          randomNumber(chartLastValue - 0.1, chartLastValue - 0.2).toFixed(2),
          randomNumber(chartLastValue - 0.1, chartLastValue - 0.2).toFixed(2),
          randomNumber(chartLastValue - 0.1, chartLastValue - 0.2).toFixed(2),
          randomNumber(chartLastValue - 0.1, chartLastValue - 0.2).toFixed(2),
          randomNumber(chartLastValue - 0.1, chartLastValue - 0.2).toFixed(2),
          randomNumber(chartLastValue - 0.1, chartLastValue - 0.2).toFixed(2),
          randomNumber(chartLastValue - 0.1, chartLastValue - 0.2).toFixed(2),
          randomNumber(chartLastValue - 0.1, chartLastValue - 0.2).toFixed(2),
          randomNumber(chartLastValue - 0.1, chartLastValue - 0.2).toFixed(2),
          chartLastValue,
          dataset
        ]
      }
    ]
  };

  return (
    <div>
      <h2>Evolution</h2>
      <Line data={data} />
    </div>
  );
};
