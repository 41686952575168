import { IsSelectedImage } from "@Helpers/ImageRoutes/Assets";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";

export const ShowPrimaryLink = props => {
  const { item, index, asPath } = props;

  const redirect = () => {
    if (item.hardRefresh) {
      window.location.href = item.link;
      return;
    }

    window.location.href = item.link;
  };

  return (
    <div>
      <ListItem button onClick={redirect} key={`menu-link-${index}`}>
        {asPath === item.link ? (
          <img className="imageSelected" src={IsSelectedImage} alt="" />
        ) : null}
        <ListItemText
          className={item.listItemClassName}
          primary={item.text}
          style={item.textExtraStyle}
          key={`menu-link-text-${index}`}
        />
      </ListItem>
    </div>
  );
};
