import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import MediaCarouselListItem from "@Components/MediaCarouselListItem";
import { useRouter } from "@Hooks";
import { Grid } from "@material-ui/core";
import React from "react";


const MediaTopStoriesList = props => {
  const Router = useRouter();
  const { forYouList } = props;

  const goToMedia = mediaID => {
    console.log("GOTO MEDIA DETAILS FOR INVEST ID: ", mediaID);
    Router.push({
      pathname: `/mediaItem/${mediaID}`
    });
  };

  return (
    <Grid container className="MediaTopStoriesList">
      <Grid item xs={12} className="carouselContainer">
        <Carousel
          className={"carouselName"}
          plugins={["arrows"]}
          slidesPerPage={8}
        >
          {forYouList.map(item => (
            <MediaCarouselListItem
              item={item}
              key={item.id}
              navigateOn={goToMedia}
            />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default MediaTopStoriesList;
