import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { ButtonPink } from '@Components/ButtonPink';

const SetLicenseDocument = props => {
  const { isVisible, setNextStep, setLicenseIsValid } = props;

  return (
    <ButtonPink
      onClick={() => {
        setLicenseIsValid(true);
        setNextStep(0);
      }}
      variant={'contained'}
      color={'primary'}
      fullWidth={true}
      disableElevation
      style={{ display: `${isVisible ? `block` : `none`}` }}
    >
      <span>Upload</span>
      <ArrowUpwardIcon className={`upArrow`} />
    </ButtonPink>
  );
};

export default SetLicenseDocument;
