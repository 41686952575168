import lscache from "lscache";
import React, { useEffect } from "react";

const Logout = props => {
  const logoutUser = async () => {
    lscache.flush();
    lscache.remove("accessToken");
    window.location = "/login";
  };

  useEffect(() => {
    logoutUser();
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default Logout;
