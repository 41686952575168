import InvestTabs from "@Components/InvestTabs";
import ProjectsTable from "@Components/ProjectsTable/ProjectsTable";
import Grid from "@material-ui/core/Grid";
import { RESTjavaInApp } from "@Utils/";
import React, { useContext, useEffect, useState } from "react";
import { UserProfileContext } from "src/context/userProfileContext";

export const ArtistProjects = (props) => {
    const { userData } = useContext(UserProfileContext)

    const [artistProjects, setArtistProjects] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(true);

    const [selectedTab, setSelectedTab] = useState("CATEGORIES");
    const [selectedCategory, setSelectedCategory] = useState("all");


    const getArtistProjects = async (id) => {
        await RESTjavaInApp.get(`/api/v1/projects/getAll/~/user/${id}`)
            .then((answer) => {
                console.log("artist Projects >>>> ", answer.data.content)
                setArtistProjects(answer.data.content)
                setIsPageLoading(false)
            })
            .catch((err) => {
                if (err && err.response && err.response.data) {
                    const { message } = err.response.data;
                    console.log(`Error message: `, message)
                }
            });
    };



    useEffect(() => {
        if (userData && userData.data && userData.data.id) {
            getArtistProjects(userData.data.id);
        }
    }, [userData]);


    if (isPageLoading) {
        return (
            <Grid container className={"investPage"}>
                <Grid item xs={12}>
                    <h1>My Projects</h1>
                </Grid>
                <InvestTabs
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                />
            </Grid>
        );
    } else {
        return (
            <Grid container className={"investPage"}>
                <Grid item xs={12}>
                    <h1>My Projects</h1>
                </Grid>
                <Grid item xs={12}>
                    {artistProjects && <ProjectsTable data={artistProjects} />}
                </Grid>
            </Grid>
        );
    }
};

export default ArtistProjects;
