const registerFormSchemaStep7 = {
    idtype: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
  issuingauthority: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },

  issuedate: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 10,
    },
    format: {
      pattern: /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
      message: 'require this format YYYY-MM-DD',
    },
  },
  expirationdate: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum:  10,
    },
    format: {
        pattern: /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
        message: 'require this format YYYY-MM-DD',
      },
  },
};

export default registerFormSchemaStep7;
