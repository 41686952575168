import React from 'react';

const ServicesDetails = (props) => {

  const { services } = props

  console.log('penis', services)
  return (
    <div className={'details-root'}>
      <div className={'info-container'}>
        <div className={'info-title'}>INFO</div>
        <div className={'info-content'}>
          {/* <label className={'content'}> */}
          {services.description}
          {/* </label> */}
        </div>
      </div>

      <div className={'list-container'}>
        <div className={'list-title'}>LIST OF SERVICES PROIVEDED</div>

        <ul className={'services-list'}>
          <li className={'item'}>A&R support and funding</li>
          <li className={'item'}>Marketing and proimotion</li>
          <li className={'item'}>Distribution</li>
          <li className={'item'}>Licensing deals</li>
        </ul>
      </div>
    </div>
  );
};

export default ServicesDetails;
