import Grid from '@material-ui/core/Grid';
import React from 'react';

const InvestPortofolioRoyaltiesEntry = props => {
  const {
    id,
    artist,
    projectType,
    revenuShare,
    revenueValue,
    revenueCurrency,
    yearlyYield,
    imgSrc,
    selectedEntry,
    setSelectedEntry,
    showChart,
  } = props;

  const handleSelect = () => {
    setSelectedEntry(id);
    showChart(id, revenueValue);
  };

  if (selectedEntry === id) {
    return (
      <Grid
        container
        className={'selectedInvestPortofolioRoyaltiesEntry'}
        onClick={() => handleSelect()}
      >
        <Grid item xs={3} style={{ textAlign: 'start' }}>
          <img src={imgSrc} style={{ width: '47px', height: '47px' }} alt="" />
          {artist}
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          {projectType}
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }} className={'rights'}>
          {revenuShare} %
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }} className={'value'}>
          {revenueValue} {revenueCurrency}
        </Grid>
        <Grid item xs={3} className={'yearlyYield'}>
          {yearlyYield} %
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        className={'investPortofolioRoyaltiesEntry'}
        onClick={() => handleSelect()}
      >
        <Grid item xs={3} style={{ textAlign: 'start' }}>
          <img src={imgSrc} style={{ width: '47px', height: '47px' }} alt="" />
          {artist}
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          {projectType}
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }} className={'rights'}>
          {revenuShare} %
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'center' }} className={'value'}>
          {revenueValue} {revenueCurrency}
        </Grid>
        <Grid item xs={3} className={'yearlyYield'}>
          {yearlyYield} %
        </Grid>
      </Grid>
    );
  }
};

export default InvestPortofolioRoyaltiesEntry;
