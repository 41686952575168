import { loginPlayDisabled, LogoBtn } from '@Helpers/ImageRoutes/Logo';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { GlobalMUIStyles } from '@Styled/AppStyle';
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import registerFormSchemaStep6 from '../../FormSchema/registerFormSchemaStep6';

const Step6Signup = props => {
  const { setNextStep, isVisible, secondFormState, setSecondFormState } = props;
  const GlobalStyle = GlobalMUIStyles({ color: 'cadetblue' });

  const [placeOfBirth, setPlaceOfBirth] = useState(null);
  const [personalIdNumber, setPersonalIdNumber] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [sex, setSex] = useState(null);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, registerFormSchemaStep6);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleNext = () => {
    setSecondFormState({
      ...secondFormState,
      step6: {
        placeOfBirth: placeOfBirth,
        personalIdNumber: personalIdNumber,
        dateOfBirth: dateOfBirth,
        sex: sex,
      },
    });
    setNextStep(7);

    console.log('[Step6]', secondFormState);
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems={`center`}
      justify={`center`}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <h1>Investor Sign-up</h1>
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          id={'placeofbirth'}
          name="placeofbirth"
          label={'Place of birth'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-placeofbirth',
            form: {
              autocomplete: 'off',
            },
          }}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setPlaceOfBirth(value);
          }}
          error={hasError('placeofbirth')}
          helperText={
            hasError('placeofbirth') ? formState.errors.placeofbirth[0] : null
          }
          value={formState.values.placeofbirth || ''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <TextField
          id={'idnumber'}
          name='idnumber'
          type='number'
          label={'Personal identification number'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-idnumber',
            form: {
              autocomplete: 'off',
            },
          }}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setPersonalIdNumber(value);
          }}
          error={hasError('idnumber')}
          helperText={
            hasError('idnumber') ? formState.errors.idnumber[0] : null
          }
          value={formState.values.idnumber || ''}
        />
      </Grid>
      <Grid item xl={8} lg={8} md={8} xs={8}>
        <TextField
          id={'dateofbirth'}
          name='dateofbirth'
          label={'Date of birth'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-dob',
            form: {
              autocomplete: 'off',
            },
          }}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setDateOfBirth(value);
          }}
          error={hasError('dateofbirth')}
          helperText={
            hasError('dateofbirth') ? formState.errors.dateofbirth[0] : null
          }
          value={formState.values.dateofbirth || ''}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={3} xs={3}>
        <TextField
          id={'sex'}
          name='sex'
          label={'Sex'}
          variant={'outlined'}
          className={GlobalStyle.textField}
          inputProps={{
            autocomplete: 'new-sex',
            form: {
              autocomplete: 'off',
            },
          }}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setSex(value);
          }}
          error={hasError('sex')}
          helperText={hasError('sex') ? formState.errors.sex[0] : null}
          value={formState.values.sex || ''}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        {formState.isValid ? (
          <label htmlFor="icon-button-file" className="nextButtonContainer">
            <IconButton
              color="primary"
              component="span"
              onClick={handleNext}
              disabled={!formState.isValid}
              type="submit"
            >
              <img src={LogoBtn} alt="" />
            </IconButton>
          </label>
        ) : (
          <img src={loginPlayDisabled} alt="" />
        )}
      </Grid>
    </Grid>
  );
};

export default Step6Signup;
