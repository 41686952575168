import Grid from '@material-ui/core/Grid';
import { LoginMUIStyles } from '@Styled/Login.style';
import TextField from '@material-ui/core/TextField';
import { GlobalMUIStyles } from '@Styled/AppStyle';
import React, { useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import { RESTjavaInApp } from '@Utils';
import { debounce } from 'lodash';
import Dropzone from 'react-dropzone';
import checked from '../../../../../Assets/Images/checked.png';

const CreateManager = (props) => {
    const { isVisible, mainFormState, setMainFormState } = props;
    const LoginStyle = LoginMUIStyles();
    const GlobalStyle = GlobalMUIStyles({ color: 'cadetblue' });

    const [artistList, setArtistList] = useState([]);
    const [clientName, setClientName] = useState(null);
    const [getAllArtists, setGetAllArtists] = useState([]);
    // eslint-disable-next-line
    const [error, setError] = useState({ message: null });
    const [uploadedList, setUploadedList] = useState(null);
    const [docUploaded, setDocUploaded] = useState(false);

    const handleAdd = (name) => {
        if (clientName) {
            if (!artistList.includes(name)) {
                setArtistList([...artistList, name]);
            }
        }
        setClientName(null);
    };

    const searchThruArtists = async (term) => {
        if (term.length >= 3) {
            await RESTjavaInApp.get(`api/v1/managers/getAll/~/${term}`)
                .then((answer) => {
                    setError({ message: null });
                    var data = answer.data;
                    setGetAllArtists(data);
                })
                .catch((err) => {
                    if (err && err.response && err.response.data) {
                        const { message } = err.response.data;

                        setError({
                            message
                        });
                    }
                });
        }
    };

    // eslint-disable-next-line
    const debounceLoadData = useCallback(debounce(searchThruArtists, 500), []);

    const handleNewChange = (event) => {
        event.persist();

        if (event.target.name === 'clientName') {
            debounceLoadData(event.target.value);
        }
        setClientName(event.target.value);
    };

    const removeClient = (index) => {
        if (index > -1) {
            const myClientList = [...artistList];
            myClientList.splice(index, 1);
            setArtistList(myClientList)
        }
    }

    const onUpload = async (files) => {
        const managerId = mainFormState.entityDetails.managerId;

        const formData = new FormData();

        files.map((item) => {
            formData.append('files', item);
            setUploadedList(formData);
        });

        if (managerId && managerId) {
            await RESTjavaInApp.post(`/api/v1/managers/uploadManagerFiles/${managerId}`, uploadedList)
                .then((answer) => {
                    setDocUploaded(true)
                })
                .catch((err) => {
                    if (err && err.response && err.response.data) {
                        const { message } = err.response.data;
                        console.log("CREATE USER ERROR: ", message);
                    }
                });
        }
    };

    console.log("plm", mainFormState.entityDetails.managerId)

    return (
        <Grid
            container
            spacing={3}
            alignItems="center"
            justify="center"
            direction="column"
            className={LoginStyle.root}
            style={{ display: isVisible ? 'block' : 'none' }}
        >
            <Grid item xs={12} className="mt-3-rem">
                <h1>Add your clients</h1>
                {/* <h2>Do you want to be an investor?</h2> */}
            </Grid>

            <Grid item xl={12} lg={12} md={12} xs={12} className={GlobalStyle.addClientContainer}>
                <Autocomplete
                    options={getAllArtists}
                    getOptionLabel={(option) =>
                        option !== undefined ? (option.fullName ? option.fullName : option) : null}
                    // getOptionSelected={(option) => setArtistId(option.id)}
                    defaultValue={''}
                    placeholder="Search"
                    onSelect={handleNewChange}
                    className={GlobalStyle.textField}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="clientName"
                            name="clientName"
                            color="secondary"
                            variant={'outlined'}
                            label="Client Name"
                            placeholder="e.g. John Doe"
                            onChange={(event) => handleNewChange(event)}
                            value={clientName || ""}
                        />
                    )}
                />
                <Tooltip title="Add">
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => handleAdd(clientName)}
                        className={GlobalStyle.addButton}
                    >
                        <AddCircleIcon style={{ width: 40, height: 40 }} />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12} className={GlobalStyle.clientList}>
                {artistList && artistList.map((item, index) => {
                    return (
                        <div className={GlobalStyle.clientName} key={item} onClick={() => removeClient(index)}>
                            <p >{item}</p>
                        </div>
                    )
                })}
            </Grid>

            <Grid item xs={12} className={GlobalStyle.uploaderTitle}>
                <h1>Upload your documents</h1>
            </Grid>

            <Grid xs={12} className="selectFieldContainer">
                <div className="selectField">
                    <Dropzone
                        onDrop={onUpload}
                        // accept={'.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf'}
                        accept={'.png,.jpeg,.jpg,.JPEG,.JPG,.pdf'}
                    // disabled={isLoading}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p className="selectFieldText">Click or Drag 'n' drop your files</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    {docUploaded ? (
                        <img
                            src={checked}
                            onClick={() => setDocUploaded(false)}
                            style={{
                                cursor: 'pointer',
                                height: '30px',
                                width: '30px',
                                marginLeft: '20px',
                                marginTop: '7px'
                            }}
                            alt=""
                        />
                    ) : null}
                </div>
            </Grid>
        </Grid>
    );
};

export default CreateManager;
