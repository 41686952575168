import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';

import MarketFilters from './Components/Header/MarketFilters';
import Cart from './Components/Cart/Cart';
import RecommnededItems from './Components/RecommendedItems/RecommendedItems';
import { marketItemList } from '@Utils/mocks/marketItemsList';


export const MarketCart = () => {
    // eslint-disable-next-line
    const [marketItems, setMarketItems] = useState(marketItemList);

    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <MarketFilters />
            </Grid>

            <Grid item xs={6} md={6} lg={6} xl={6}>
                <Cart />
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6}>
                <RecommnededItems marketItems={marketItems} />
            </Grid>
        </Grid>
    );
};
export default MarketCart;
