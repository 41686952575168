import React from 'react';
import Grid from '@material-ui/core/Grid';

import { checked, unChecked } from '@Helpers/ImageRoutes/Assets';

const WatchlistEntry = props => {
  const {
    id,
    artist,
    buy,
    sell,
    limitPrice,
    limitPriceCurrency,
    marketPrice,
    marketPriceCurrency,
    imgSrc,
    isChecked,
    handleCheck,
  } = props;

  return (
    <Grid container spacing={0} className={'watchlistEntry'}>
      <Grid
        item
        xs={3}
        style={{ textAlign: 'start' }}
        className={'artistDetailsSelectedEntry'}
      >
        <div>
          <img src={imgSrc} style={{ width: '47px', height: '47px' }} alt="" />
        </div>
        &nbsp;&nbsp;
        <div>{artist}</div>
      </Grid>

      <Grid item xs={2} style={{ textAlign: 'center' }}>
        {buy}
      </Grid>

      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <div className={'watchlistPinkColor'}>{sell}</div>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <div className={'watchlistPinkColor'}>
          {limitPrice} {limitPriceCurrency}/Right
        </div>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'end' }}>
        <div className={'watchlistPinkColor'}>
          {marketPrice} {marketPriceCurrency}/Right
        </div>
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'center' }}>
        {typeof isChecked == 'undefined' ? null : (
          <img
            src={isChecked ? checked : unChecked}
            style={{ width: '18px', height: '18px' }}
            onClick={() => handleCheck(id)}
            alt=""
          />
        )}
      </Grid>
    </Grid>
  );
};

export default WatchlistEntry;
