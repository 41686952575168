import { min2time } from "@Helpers";
import { unkownArtist } from "@Helpers/ImageRoutes/MusicPlayer";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";


const SeeAllTracksList = props => {
  const { list, handlePlay } = props;
  let item = {
    imageUrl: list["Songs.imageUrl"]
  };

  const artistImgDisplay = item =>
    item && item.imageUrl && item.imageUrl.includes("http")
      ? item.imageUrl
      : unkownArtist;

  const [durationToShow, setDurationToShow] = useState(0);

  useEffect(() => {
    min2time(list.duration, setDurationToShow);
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={0} className={"seeAllEntry"}>
      <Grid item xs={1} className={"artistImgContainer"}>
        <img src={artistImgDisplay(item)} className="artistImg" alt="" />
      </Grid>
      <Grid item xs={3} className={"artistSongName"}>
        {list.title}
      </Grid>

      <Grid item xs={3} className={"artistAlbum"}>
        {list.name}
      </Grid>

      {list["Songs.duration"] === "0" ? (
        <Grid item xs={3} className={"playlistDuration"}>
          Duration: 00:00
        </Grid>
      ) : (
        <Grid item xs={3} className={"playlistDuration"}>
          Duration: {durationToShow}
        </Grid>
      )}
      <Grid item xs={1}>
        <div className="playBtnBg" onClick={() => handlePlay(list.id)}>
          <p className="playBtnText">PLAY</p>
        </div>
      </Grid>
    </Grid>
  );
};

export default SeeAllTracksList;
