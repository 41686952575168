import { loginPlayDisabled, LogoBtn } from '@Helpers/ImageRoutes/Logo';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { GlobalMUIStyles } from '@Styled/AppStyle';
import { LoginMUIStyles } from '@Styled/Login.style';
import { RESTjavaTest } from "@Utils";
import React, { useEffect, useState } from 'react';
import validate from 'validate.js';
import registerFormSchemaStep2 from '../../FormSchema/registerFormSchemaStep2';


const Step2Signup = props => {
  const { setNextStep, isVisible, mainFormState, setMainFormState } = props;
  const GlobalStyle = GlobalMUIStyles({ color: 'cadetblue' });
  const LoginStyle = LoginMUIStyles();

  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, registerFormSchemaStep2);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));


    setMainFormState({
      ...mainFormState,
      step2: {
        password: password,
        confirmPassword: confirmPassword,
      },
    });
  };


  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  const createUser = async () => {
    var body = {
      email: mainFormState.step1.email,
      firstName: mainFormState.step1.firstName,
      lastName: mainFormState.step1.lastName,
      username: mainFormState.step1.username,
      password: mainFormState.step2.password,
    };
    await RESTjavaTest.post(`/auth/register`, body)
      .then((answer) => {
        setMainFormState({
          ...mainFormState,
          entityDetails: {
            userId: answer.data.id,
          },
        });
        setNextStep(3);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("CREATE USER ERROR: ", message);
        }
      });
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justify="center"
      direction="column"
      className={LoginStyle.root}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <Grid item xs={12}>
        <TextField
          type={'password'}
          id={'password'}
          label={'Password'}
          name="password"
          variant={'outlined'}
          className={GlobalStyle.textField}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setPassword(value);
          }}
          error={hasError('password')}
          helperText={
            hasError('password') ? formState.errors.password[0] : null
          }
          value={formState.values.password || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type={'password'}
          id={'passwordConfirm'}
          label={'Verify Password'}
          name="passwordConfirm"
          variant={'outlined'}
          className={GlobalStyle.textField}
          onChange={event => {
            handleChange(event);
            const { value } = event.target;
            setConfirmPassword(value);
          }}
          error={hasError('passwordConfirm')}
          helperText={
            hasError('passwordConfirm')
              ? formState.errors.passwordConfirm[0]
              : null
          }
          value={formState.values.passwordConfirm || ''}
        />
      </Grid>
      <Grid item xs={12}>
        {formState.isValid ? (
          <label htmlFor="icon-button-file" className="nextButtonContainer">
            <IconButton
              color="primary"
              component="span"
              onClick={createUser}
              disabled={!formState.isValid}
              type="submit"
            >
              <img src={LogoBtn} alt="" />
            </IconButton>
          </label>
        ) : (
          <img src={loginPlayDisabled} alt="" />
        )}
      </Grid>
    </Grid>
  );
};

export default Step2Signup;
