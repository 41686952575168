import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { checked, unChecked } from '@Helpers/ImageRoutes/Assets';

const WatchListTable = (props) => {

    const {
        id,
        artist,
        buy,
        sell,
        limitPrice,
        limitPriceCurrency,
        marketPrice,
        marketPriceCurrency,
        imgSrc,
        isChecked,
        handleCheck,
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const handleDetails = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <Grid container className={`invest_table_container`}>

            <Grid item xs={12} className={`invest_item`} onClick={handleDetails}>
                <Grid item xs={6} style={{ display: "flex", flexDirection: "row", paddingLeft: "1rem" }}>
                    <img src={imgSrc} style={{ width: "47px", height: "47px" }} alt="" />
                </Grid>

                <Grid item xs={6}>
                    {artist}
                </Grid>
            </Grid>
            {isExpanded ?
                <Grid container>
                    <Grid item xs={12} className={`invest_item`} style={{ justifyContent: "space-between" }}>
                        <Grid item x={6}>
                            <p>BUY</p>
                        </Grid>
                        <Grid item xs={6}>
                            {buy}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>SELL</p>
                        </Grid>
                        <Grid item xs={6}>
                            {sell}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>LIMIT PRICE</p>
                        </Grid>
                        <Grid item xs={6}>
                            {limitPrice} {limitPriceCurrency}/Right
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>MARKET PRICE</p>
                        </Grid>
                        <Grid item xs={6}>
                            {marketPrice} {marketPriceCurrency}/Right
                        </Grid>
                    </Grid>

                    <Grid item xs={1} style={{ textAlign: 'center' }}>
                        {typeof isChecked == 'undefined' ? null : (
                            <img
                                src={isChecked ? checked : unChecked}
                                style={{ width: '18px', height: '18px' }}
                                onClick={() => handleCheck(id)}
                                alt=""
                            />
                        )}
                    </Grid>
                </Grid>
                :
                null
            }
        </Grid>
    )
}

export default WatchListTable;