import React from 'react';


const CurrencyAmount = props => {
  const { textData, currency, amount } = props;

  return (
    <div className={'CurrencyContainer'}>
      <div className="BankAccountTitle">
        <p>{textData}</p>
      </div>
      <div className="BankAccountRow">
        <p className={'SecondaryInfo'}>{amount}</p>
        &nbsp; &nbsp;
        <p className={'SecondaryInfo'}>{currency}</p>
      </div>
    </div>
  );
};

export default CurrencyAmount;
