import React from "react";


const TabsButtons = props => {
  const { selectedTab } = props;

  return (
    <div className={"playlistButtonsContainer"}>
      {selectedTab === "PROFILE" ? (
        <div
          className={"selectedTabStyle"}
          onClick={() => (window.location.href = "/account/profile")}
        >
          PROFILE
        </div>
      ) : (
        <div
          className={"unselectedTabStyle"}
          onClick={() => (window.location.href = "/account/profile")}
        >
          PROFILE
        </div>
      )}
      &nbsp;&nbsp;&nbsp;
      {selectedTab === "WALLET" ? (
        <div
          className={"selectedTabStyle"}
          onClick={() => (window.location.href = "/account/wallet")}
        >
          WALLET
        </div>
      ) : (
        <div
          className={"selectedTabStyle"}
          onClick={() => (window.location.href = "/account/wallet")}
        >
          WALLET
        </div>
      )}
    </div>
  );
};

export default TabsButtons;
