import React from 'react';


const Services = (props) => {

  const { services } = props


  return (
    <div className={'services-root'}>
      <div className={'item-container'}>
        <img src={services.servicePhotoPath} className={'label-img'} alt="" />
        <div className={'label-contact'}>
          <div className={'label-title'}>
            <h2>{services.title}</h2>
          </div>
          <div className={'label-contact-info'}>
            <div className={'email'}>Email: office@labelname.com</div>
            <div className={'phone'}>Phone: +40 065 626 620</div>
          </div>
        </div>
      </div>

      <div className={'contact-info'}>
        <div className={'contact-title'}>CONTACT INFO</div>

        <div className={'contact-details-container'}>
          <div>Email: office@labelname.com</div>
          <div>Phone: +40 065 626 620</div>
        </div>
      </div>
    </div>
  );
};

export default Services;
