import { Footer, LoadingUntilRedirect } from "@Components";
import { Container } from "@Styled/Login.style";
import React from "react";
import SignInForm from "./Components/SignInForm";
import SignInSocial from "./Components/SignInSocial";

const Login = props => {
  const { isAuthenticated } = props;

  let html;
  if (isAuthenticated) {
    html = <LoadingUntilRedirect />;
  } else {
    html = (
      <Container>
        <SignInForm />
        <SignInSocial />
        <Footer />
      </Container>
    );
  }

  return html;
};

export default Login;
