import { Minus, Plus } from '@Helpers/ImageRoutes/Market';
import Grid from '@material-ui/core/Grid';
import React from 'react';


const Cart = () => {
    return (
        <Grid container className={'cart-root'}>
            <Grid item xs={12} md={12} lg={12} xl={12} className={'cart-header'}>
                <h4>YOUR CART</h4>
            </Grid>

            <Grid item xs={12} md={12} lg={12} xl={12} className={'cart-body'}>
                <Grid item xs={4} md={4} lg={4} xl={4} className={'cart-item'}>
                    <img src={"https://cdn.flightclub.com/TEMPLATE/804093/1.jpg"} className={'item-img'} alt="" />
                </Grid>
                <Grid item xs={8} md={8} lg={8} xl={8} className={'info-container'}>
                    <div className={'top-half'}>
                        <div className={'item-info'}>
                            <h4 className={'product-name'}>Air Jordan</h4>
                            <label>120 USD</label>
                        </div>

                        <div className={'produc-quantity'}>
                            <h4 className={'product-name'}>Pieces</h4>
                            <label>1</label>
                        </div>

                        <div className={'incrementer'}>
                            {/* <h1 className={'incermenter-btn'}>+</h1>
                            <h1 className={'incermenter-btn'}>-</h1> */}
                            <img src={Plus} alt='plus' className={'incermenter-btn'} />
                            <img src={Minus} alt='minus' className={'incermenter-btn'} />
                        </div>
                    </div>

                    <div className={'bottom-half'}>
                        <h6 className={'currency-symbol'}>TOTAL</h6>
                        <div className={'amountInput'}>
                            <div className={'amount'}>
                                <p>120</p>
                            </div>
                            <div className={'currency-label'}>
                                <h6 className={'currency-symbol'}>$</h6>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12} xl={12} className={'cart-footer'}>
                <div className={'checkout-btn'}>
                    <h6 className={'text-btn'}>CHECK OUT</h6>
                </div>
            </Grid>
        </Grid>
    )
}
export default Cart;
