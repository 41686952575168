import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Tab from "@mui/material/Tab";
import Tabs from '@mui/material/Tabs';
import React, { useState } from 'react';
import LimitOrder from './Components/LimitOrder';
import OrderToMarket from './Components/OrderToMarket';
import StopLimit from './Components/StopLimit';
import StopOrder from './Components/StopOrder';

const TradeOrderType = props => {
  const { buyPrice, sellPrice } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  return (
    <Grid container className={'TradeOrderType'}>
      <Grid item xs={12} className={'orderTypeHeader'}>
        <h3>ORDER TYPE</h3>
        <h2>What's this? </h2>
      </Grid>
      <Grid item xs={12} className='selectOrederType'>
        <Box sx={{ width: '100%', display: "flex", justifyContent: "space-around" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="red"
            indicatorColor="primary"
            aria-label="primary tabs example"
          >
            <Tab value={0} label="MARKET EXECUTION" />
            <Tab value={1} label="LIMIT ORDER" />
            <Tab value={2} label="STOP ORDER" />
            <Tab value={3} label="STOP-LIMIT ORDER" />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12} className="orderComponent">
        {value === 0 && <OrderToMarket sellPrice={sellPrice} buyPrice={buyPrice} />}
        {value === 1 && <LimitOrder sellPrice={sellPrice} buyPrice={buyPrice} />}
        {value === 2 && <StopOrder sellPrice={sellPrice} buyPrice={buyPrice} />}
        {value === 3 && <StopLimit sellPrice={sellPrice} buyPrice={buyPrice} />}
      </Grid>
    </Grid>
  );
};

export default TradeOrderType;