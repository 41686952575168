import {
  FormControlLabel, Grid, Radio,
  RadioGroup, TextField
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import validate from "validate.js";
import InfoBox from "../components/infoBox";
import secondStepSchema from "../schemas/secondStepSchema";
import { RESTjavaInApp } from "@Utils";
import moment from "moment";

export const SecondStep = (props) => {
  const {
    setCurrentStep,
    handleChange,
    formState,
    setFormState,
    newProjectId,
    setNewStockId
  } = props;

  // eslint-disable-next-line
  const [checkBoxList, setCheckBoxList] = React.useState({
    monthly: true,
    quarterly: false,
    semiannually: false,
    yearly: false,
    "one off (bullet)": false,
  });
  // eslint-disable-next-line
  const [yieldType, setYieldType] = React.useState({
    guaranteed: false,
    "not guaranteed": false,
  });
  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });

  useEffect(() => {
    const errors = validate(formState.values, secondStepSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line
  }, [formState.values]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleNextStep = async () => {
    setCurrentStep("thirdStep");
  };

  const createStock = async () => {
    let body = {
      dueDate: moment(formState.values.dueDate).format("yyyy-MM-DD"),
      estimatedValue: formState.values.estimatedValue,
      name: formState.values.projectTitle,
      numberOfUnits: formState.values.unitSold,
      percentage: formState.values.percentage,
      price: formState.values.pricePerUnit,
      projectId: newProjectId,
      releaseDate: moment(formState.values.releaseDate).format("yyyy-MM-DD"),
      // stockTypeId: formState.values.estimatedValue,
      stockTypeId: 1,
      estimatedYield: formState.values.estimatedYield,
      estimatedYieldDescription: formState.values.estimatedYieldDescription,
      yieldType: formState.values.yieldType,
      tenor: formState.values.tenor,
      tenorDescription: formState.values.tenorDescription,
      installments: formState.values.installments,
    }

    await RESTjavaInApp.post(`/api/v1/stocks/create`, body)
      .then(answer => {
        setError({ message: null });
        if (formState.isValid) {
          console.log(`project id >>>`, answer.data.id)
          setNewStockId(answer.data.id)
          handleNextStep();
        }
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          setError({
            message
          });
        }
      });
  }



  return (
    <Grid container className="secondPageContainer">
      <Grid item xs={12} className="titleContainer">
        <p className="titleText">Create Project</p>
      </Grid>
      <Grid item xs={12} className="stepTrackerContainer">
        <div className="firstStepTracker">1</div>
        <div className="firstStepTrackerLine" />
        <div className="secondStepTracker">2</div>
        <div className="secondStepTrackerLine" />
        <div className="thirdStepTracker">3</div>
      </Grid>

      <Grid item xs={12}>
        <p className="contentTitle">Complete the following fields: </p>
      </Grid>
      <div className="allContentContainer">
        <Grid item container xs={12} className="contentContainer">
          <Grid item xs={12} md={6} className="firstInputContainer">
            <InfoBox
              title="Estimated Value"
              infoText="Estimated project value, to include both floating rights and rights retained by the issuer"
            />

            <NumberFormat
              thousandSeparator={true}
              className="nrField"
              id="estimatedValue"
              name="estimatedValue"
              placeholder="e.g. $ 100,000"
              prefix={"$ "}
              value={formState.values["estimatedValue"]}
              error={hasError("estimatedValue")}
              helperText={
                hasError("estimatedValue")
                  ? formState.errors.estimatedValue[0]
                  : null
              }
              onValueChange={(values) => {
                const { floatValue } = values;

                setFormState((formState) => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    estimatedValue: floatValue,
                  },
                  touched: {
                    ...formState.touched,
                    estimatedValue: true,
                  },
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className="secondInputContainer">
            <InfoBox
              title="IRO Value offer"
              infoText="Estimated Yield Description"
              hideInfoButton={true}
            />

            <NumberFormat
              thousandSeparator={true}
              className="nrField"
              id="valueOffer"
              name="valueOffer"
              placeholder="e.g. $ 100,000"
              prefix={"$ "}
              value={formState.values["valueOffer"]}
              error={hasError("valueOffer")}
              helperText={
                hasError("valueOffer") ? formState.errors.valueOffer[0] : null
              }
              onValueChange={(values) => {
                const { floatValue } = values;

                setFormState((formState) => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    valueOffer: floatValue,
                  },
                  touched: {
                    ...formState.touched,
                    valueOffer: true,
                  },
                }));
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className="contentContainer">
          <Grid item xs={12} md={6} className="firstInputContainer">
            <InfoBox
              title="Percentage"
              infoText="Percentage of estimated value to be floated/subject of IRO"
            />

            <NumberFormat
              thousandSeparator={true}
              className="nrField"
              id="percentage"
              name="percentage"
              placeholder="e.g. 30%"
              // prefix={"$ "}
              suffix={" %"}
              value={formState.values["percentage"]}
              error={hasError("percentage")}
              helperText={
                hasError("percentage") ? formState.errors.percentage[0] : null
              }
              onValueChange={(values) => {
                const { floatValue } = values;

                setFormState((formState) => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    percentage: floatValue,
                  },
                  touched: {
                    ...formState.touched,
                    percentage: true,
                  },
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className="secondInputContainer">
            <InfoBox
              title="NUMBER OF UNITS FOR SALE"
              infoText=""
              hideInfoButton={true}
            />
            <NumberFormat
              thousandSeparator={true}
              className="nrField"
              id="unitSold"
              name="unitSold"
              placeholder="e.g. 20,000"
              // prefix={"$ "}
              // suffix={" %"}
              value={formState.values["unitSold"]}
              error={hasError("unitSold")}
              helperText={
                hasError("unitSold") ? formState.errors.unitSold[0] : null
              }
              onValueChange={(values) => {
                const { floatValue } = values;

                setFormState((formState) => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    unitSold: floatValue,
                  },
                  touched: {
                    ...formState.touched,
                    unitSold: true,
                  },
                }));
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className="contentContainer">
          <Grid item xs={12} md={6} className="firstInputContainer">
            <InfoBox
              title="Tenor (Maturity)"
              infoText="Lifetime of the project in years or months"
            />

            <TextField
              className="textField"
              id="tenor"
              name="tenor"
              type="number"
              color="secondary"
              variant="filled"
              placeholder="e.g. 5 years"
              onChange={handleChange}
              value={formState.values["tenor"]}
              error={hasError("tenor")}
              helperText={hasError("tenor") ? formState.errors.tenor[0] : null}
            />
          </Grid>
          <Grid item xs={12} md={6} className="secondInputContainer">
            <InfoBox
              title="Estimated Yield"
              infoText="Estimated annual income per right IRO value (percentage)"
            />
            <NumberFormat
              thousandSeparator={true}
              className="nrField"
              id="estimatedYield"
              name="estimatedYield"
              placeholder="e.g. 30%"
              // prefix={"$ "}
              suffix={" %"}
              value={formState.values["estimatedYield"]}
              error={hasError("estimatedYield")}
              helperText={
                hasError("estimatedYield")
                  ? formState.errors.estimatedYield[0]
                  : null
              }
              onValueChange={(values) => {
                const { floatValue } = values;

                setFormState((formState) => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    estimatedYield: floatValue,
                  },
                  touched: {
                    ...formState.touched,
                    estimatedYield: true,
                  },
                }));
              }}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} className="contentContainer">
          <Grid item xs={12} md={6} className="firstInputContainer">
            <InfoBox title="Tenor Description" infoText="Years or months" />

            <TextField
              className="textField"
              id="tenorDescription"
              name="tenorDescription"
              color="secondary"
              multiline
              rows={2}
              variant="filled"
              placeholder="e.g. Tenor Description"
              onChange={handleChange}
              value={formState.values["tenorDescription"]}
              error={hasError("tenorDescription")}
              helperText={
                hasError("tenorDescription")
                  ? formState.errors.tenorDescription[0]
                  : null
              }
            />
          </Grid>
          <Grid
            item
            xs={12} md={6}
            className="secondInputContainer"
          >
            <Grid item xs={12} md={7}>
              <InfoBox
                title="Estimated Yield Description"
                infoText="Linked to the source of income (royalties, ticket sales for live events) and the type of project (equity/OSP, FTIS senior debt, FTIS junior debt)"
              />

              <TextField
                className="textField"
                id="estimatedYieldDescription"
                name="estimatedYieldDescription"
                color="secondary"
                multiline
                rows={2}
                variant="filled"
                placeholder="e.g. Estimated Yield Description"
                onChange={handleChange}
                value={formState.values["estimatedYieldDescription"]}
                error={hasError("estimatedYieldDescription")}
                helperText={
                  hasError("estimatedYieldDescription")
                    ? formState.errors.estimatedYieldDescription[0]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <p className="inputTitle">Select yield type</p>
              <RadioGroup
                id="yieldType"
                name="yieldType"
                value={(formState && formState.values["yieldType"]) || ""}
                onChange={handleChange}
              >
                <div className="radioGroup">
                  {Object.entries(yieldType).map(([key, value], index) => {
                    return (
                      <FormControlLabel
                        value={key}
                        control={<Radio />}
                        label={key}
                      />
                    );
                  })}
                </div>
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="contentContainer">
          <Grid item xs={12} md={6} className="firstInputContainer">
            <InfoBox
              title="Price per unit"
              infoText="Right unit sell price in IRO"
            />
            <NumberFormat
              thousandSeparator={true}
              className="nrField"
              id="pricePerUnit"
              name="pricePerUnit"
              placeholder="e.g. $ 100"
              prefix={"$ "}
              // suffix={" %"}
              value={formState.values["pricePerUnit"]}
              error={hasError("pricePerUnit")}
              helperText={
                hasError("pricePerUnit")
                  ? formState.errors.pricePerUnit[0]
                  : null
              }
              onValueChange={(values) => {
                const { floatValue } = values;

                setFormState((formState) => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    pricePerUnit: floatValue,
                  },
                  touched: {
                    ...formState.touched,
                    pricePerUnit: true,
                  },
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className="secondInputContainer">
            <InfoBox
              title="Installments"
              infoText="Periodicity of income distribution"
            />
            <RadioGroup
              id="installments"
              name="installments"
              value={(formState && formState.values["installments"]) || ""}
              onChange={handleChange}
            >
              <div className="radioGroup">
                {Object.entries(checkBoxList).map(([key, value], index) => {
                  return (
                    <FormControlLabel
                      value={key}
                      control={<Radio />}
                      label={key}
                    />
                  );
                })}
              </div>
            </RadioGroup>
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12} className="buttonContainer">
        <div
          onClick={() => setCurrentStep("firstStep")}
          className="buttonTextContainer"
        >
          <p className="buttonText">Back</p>
        </div>
        <div
          // onClick={formState.isValid ? handleNextStep : null}
          onClick={createStock}
          className={
            formState.isValid
              ? "buttonTextContainer"
              : "buttonTextContainerDisabled"
          }
        >
          <p className="buttonText">Next step</p>
        </div>
      </Grid>
    </Grid>
  );
};

export default SecondStep;
