import Grid from "@material-ui/core/Grid";
import commaNumber from "comma-number";
import React from "react";
const TradeEntryForListing = (props) => {
  const {
    selectedEntry,
    setSelectedEntry,
    showChart,
    onSelectForFraction,
    data,
    setBuyPrice,
    setSellPrice
  } = props;

  const handleSelect = () => {
    setSellPrice(commaNumber(data.pricePerUnit))
    setBuyPrice(commaNumber(data.pricePerUnit))
    setSelectedEntry(data.project.project.id);
    showChart(
      data.project.project.id,
      data.project.project.valueOffer,
      data.project.project.numberOfUnitsSold
    );
    onSelectForFraction(data.project.project.valueOffer);
  };



  const handleEntryClick = () => {
    window.location.href = `/portfolio-projects/${data.project.project.id}`;
  };

  if (selectedEntry === data.project.project.id) {
    return (
      <Grid container className={"selectedTradeEntry"}>
        <Grid
          item
          xs={2}
          style={{ textAlign: "start" }}
          onClick={() => handleSelect()}
        >
          <img
            src={data.project.project.artist.imageUrl}
            style={{ width: "47px", height: "47px", marginRight: "10px" }}
            alt=""
          />
          {data.project.project.artist.name}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
        >
          {data.project.project.title}
        </Grid>
        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
        >
          {data.project.project.category}
        </Grid>
        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
        >
          {data.project.project.projectName}
        </Grid>

        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          className={"value"}
          onClick={() => handleSelect()}
        >
          {data.numberOfUnitsSold}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => {
            handleSelect();
            // handleBuy();
            // setSellPrice(commaNumber(data.pricePerUnit))
          }
          }
        >
          {/*{Moment(releaseDate).format('DD-MM-YYYY')}*/}${" "}
          {commaNumber(data.pricePerUnit)} / Right
        </Grid>
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => {
            handleSelect();
            // handleSell();
            // setSellPrice(commaNumber(data.pricePerUnit))
          }
          }
        >
          $ {commaNumber(data.pricePerUnit)} / Right
        </Grid>

        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => handleEntryClick()}
        >
          View Project
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={"tradeEntry"}>
        <Grid
          item
          xs={2}
          style={{ textAlign: "start" }}
          onClick={() => handleSelect()}
        >
          <img
            src={data.project.project.artist.imageUrl}
            style={{ width: "47px", height: "47px", marginRight: "10px" }}
            alt=""
          />
          {data.project.project.artist.name}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
        >
          {data.project.project.title}
        </Grid>
        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
        >
          {data.project.project.category}
        </Grid>
        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          onClick={() => handleSelect()}
        >
          {data.project.project.projectName}
        </Grid>

        <Grid
          item
          xs={1}
          style={{ textAlign: "center" }}
          className={"value"}
          onClick={() => handleSelect()}
        >
          {data.numberOfUnitsSold}
        </Grid>
        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => {
            handleSelect();
            // handleBuy();
          }
          }
        >
          {commaNumber(data.pricePerUnit)} / Right
        </Grid>

        <Grid
          item
          xs={2}
          style={{ textAlign: "center" }}
          className={"rights"}
          onClick={() => {
            handleSelect();
            // handleSell();
            // setSellPrice(commaNumber(data.pricePerUnit))
          }
          }
        >
          $ {commaNumber(data.pricePerUnit)} / Right
        </Grid>

        <Grid
          item
          xs={1}
          style={{ textAlign: "end" }}
          className={"rights"}
          onClick={() => handleEntryClick()}
        >
          View Project
        </Grid>
      </Grid>
    );
  }
};

export default TradeEntryForListing;
