import { ButtonPink } from '@Components/ButtonPink';
import React from 'react';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

const DecisionButtonsStep = props => {
  const {
    isVisible,
    setNextStep,
    licenseIsValid,
    publicAppearanceIsValid,
  } = props;

  return (
    <div style={{ display: `${isVisible ? `block` : `none`}` }}>
      <ButtonPink
        onClick={() => setNextStep(1)}
        variant={'contained'}
        color={'primary'}
        fullWidth={true}
        disableElevation
      >
        License
        {licenseIsValid && <DoneOutlineIcon className={`upArrow`} />}
      </ButtonPink>

      <ButtonPink
        onClick={() => setNextStep(2)}
        variant={'contained'}
        color={'primary'}
        fullWidth={true}
        disableElevation
      >
        Public Appearance
        {publicAppearanceIsValid && <DoneOutlineIcon className={`upArrow`} />}
      </ButtonPink>
    </div>
  );
};

export default DecisionButtonsStep;
