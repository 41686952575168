import {
  Button, ListItemText,
  TextField
} from "@material-ui/core";
import { REST } from "@Utils";
import lscache from "lscache";
import React, { useEffect, useState } from "react";
import validate from "validate.js";
import formSchemaAccount from "../FormSchema/formSchemaAccount";


const PasswordField = (props) => {
  const { textData, data } = props;

  const [isSelected, setIsSelected] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, formSchemaAccount);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };
  const handleOnClick = () => {
    setIsSelected(!isSelected);

    if (isSelected) {
      updateData();
      setNewPassword("");
      setPassword("");
    }
    if (!isSelected) {
      setWrongPassword(false);
    }
  };

  const handleCancel = () => {
    setIsSelected(false);
    setNewPassword("");
    setPassword("");
  };

  const updateData = async (event) => {
    var PS = {
      current_password: "" + password,
      new_password: "" + newPassword,
      confirm_new_password: "" + newPassword,
    };

    await REST.put(`/user/${lscache.get("user")}/update/password`, PS)
      .then((answer) => {
        setWrongPassword(false);
        props.refreshData();
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log("GRESIT");
          setWrongPassword(true);
          if (err === "Not Found") {
            setWrongPassword(true);
            console.log("WRONGPASS: ", wrongPassword);
            setIsSelected(true);
          }
          const { message } = err.response.data;
          console.log("ERROR PASSWORD: ", message);
        }
      });
  };

  return (
    <div>
      <ListItemText className="Title">{textData}</ListItemText>

      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          width: "69%",
          justifyContent: "space-between",
        }}
      >
        {((formState.errors.password === undefined &&
          formState.errors.newPassword === undefined &&
          !formState.isValid.password) ||
          !isSelected ||
          wrongPassword) && (
            <Button onClick={handleOnClick} className="EditPasswordButton">
              {isSelected ? "SAVE" : "EDIT"}
            </Button>
          )}
        {isSelected ? (
          <div>
            <Button onClick={handleCancel} className="CancelButton">
              CANCEL
            </Button>
          </div>
        ) : null}
        {wrongPassword ? (
          <div>
            <p className={"WrongText"}>Wrong Password</p>
          </div>
        ) : (
          <div></div>
        )}
        {isSelected ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextField
              className="Data"
              id="password"
              name="password"
              color="secondary"
              variant="filled"
              placeholder={"Current password"}
              fullWidth={true}
              autoFocus={true}
              autoComplete="current-password"
              type="password"
              onChange={(event) => {
                handleChange(event);
                const { value } = event.target;

                setPassword(value);
                if (value === data) {
                  setPasswordsMatch(true);
                  console.log("IF MATCHED: ", passwordsMatch);
                }
              }}
              error={hasError("password")}
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              value={formState.values.password || ""}
            />

            <TextField
              className="Data"
              id="newPassword"
              name="newPassword"
              type="password"
              color="secondary"
              variant="filled"
              placeholder={"New password"}
              fullWidth={true}
              required={true}
              autoComplete="new-password"
              onChange={(event) => {
                handleChange(event);
                const { value } = event.target;

                setNewPassword(value);
                if (value === data) {
                  setPasswordsMatch(true);
                  console.log("IF MATCHED: ", passwordsMatch);
                }
              }}
              error={hasError("newPassword")}
              helperText={
                hasError("newPassword") ? formState.errors.newPassword[0] : null
              }
              value={formState.values.newPassword || ""}
            />
          </div>
        ) : (
          <ListItemText className="Data">****************</ListItemText>
        )}
      </div>
    </div>
  );
};

export default PasswordField;
