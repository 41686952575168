import { useCloseIntervalSelector } from '@Hooks';
import dropdownArrow from '@Images/drop-down-arrow.png';
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";

const InvestPortofolioRightsListHeader = (props) => {
  const { forActive, handleTableInterval, tableInterval } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandedFilter = () => {
    setIsExpanded(!isExpanded);
  }

  const ExpandedInterval = () => {
    return (
      <div className={'expandedInterval intervalSelector'} style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", marginBottom: "1.75rem" }} onClick={() => handleExpandedFilter()}>
        <div className={`expanded-interval intervalSelector`}>
          <ul className={`category-list intervalSelector`}>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1h"); setIsExpanded(false) }}>1h</li>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1d"); setIsExpanded(false) }}>1d</li>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1w"); setIsExpanded(false) }}>1w</li>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1m"); setIsExpanded(false) }}>1m</li>
            <li className={`category-interval`} onClick={() => { handleTableInterval("1y"); setIsExpanded(false) }}>1y</li>
          </ul>
        </div>
      </div>
    )
  }

  const IntervalBtn = () => {
    return (
      <div className={"interval intervalSelector"} onClick={() => handleExpandedFilter()}>
        <div className={`portfolio_table_header`}>
          {tableInterval}
        </div>
        <img src={dropdownArrow} alt="" className={"arrow intervalSelector"} style={{ height: 15, width: 15, marginLeft: "0.5rem" }} />
      </div>
    )
  }

  useCloseIntervalSelector('intervalSelector', setIsExpanded, false);

  return (
    <div className={"investPortofolioRightsListHeader intervalSelector"}>
      <div className={`portfolio_table_header`}>
        ARTIST
      </div>
      <div className={`portfolio_table_header`}>
        PROJECT NAME
      </div>
      <div className={`portfolio_table_header`}>
        PROJECT TYPE
      </div>
      <div className={`portfolio_table_header`}>
        RIGHTS (UNITS)
      </div>
      <div className={`portfolio_table_header`}>
        SETTLED RIGHTS (UNITS)
      </div>
      <div className={`portfolio_table_header`}>
        UNAVAILABLE RIGHTS (UNITS)
      </div>
      <div className={`portfolio_table_header`}>
        AVAILABLE RIGHTS (UNITS)
      </div>
      <div className={`portfolio_table_header`}>
        AVERAGE PRICE
      </div>
      <div className={`portfolio_table_header`}>
        INVESTED AMMOUNT
      </div>
      <div className={`portfolio_table_header`}>
        INVESTED AMMOUNT
      </div>
      <div className={`portfolio_table_header`}>
        INVESTED PERCENT
      </div>
      <div className={`portfolio_table_header`}>
        DATE
      </div>
      <div className={`portfolio_table_header`}>
        MARKET PIRCE
      </div>
      <div className={`portfolio_table_header`}>
        PERCENTAGE CHANGE IN PRICE
      </div>
      <div className={`portfolio_table_header`}>
        MARKET VALUE
      </div>

      {forActive ? (
        <div className={`portfolio_table_header`}>
          UNITS SOLD
        </div>
      ) : null}
      {forActive ? (
        <div className={`portfolio_table_header`}>
          PRICE PER UNIT
        </div>
      ) : null}
      {/* {showDueDate ? (
        <Grid item xs={2} style={{ textAlign: "center" }}>
          IRO DUE DATE
        </Grid>
      ) : null} */}
      {/* {showDueDate ? ( */}

      {window.location.pathname === "/portofolio-rights" ?
        <Grid item xs={2} style={{ textAlign: "center" }}>
          <div>
            {isExpanded ? <ExpandedInterval /> : <IntervalBtn />}
          </div>
          <div className={`portfolio_table_header`}>
            PROFIT / LOSS
          </div>
        </Grid> : null
      }

      <div className={`portfolio_table_header`}>
        DAILY PRECENTAGE VARIATION
      </div>
    </div>
  );
};

export default InvestPortofolioRightsListHeader;
