export const AccountImage = require("@Images/Account/accountImg.png").default;
export const AccountImage404 = require("@Images/Account/accountImg404.svg").default;
export const IsSelectedImage = require("@Images/Account/isSelectedItemImg.png").default;
export const GoPlatinumImage = require("@Images/Account/goPlatinumImg.png").default;
export const TrendsImage = require("@Images/Account/trendsImage.png").default;
export const PodcastImage = require("@Images/Account/podcastImage.png").default;
export const NewsImage = require("@Images/Account/newsImage.png").default;
export const ReviewsImage = require("@Images/Account/reviewsImage.png").default;
export const ToReadImage = require("@Images/Account/toReadImage.png").default;
export const EventsImage = require("@Images/Account/eventsImage.png").default;
export const ServicesImage = require("@Images/Account/servicesImage.png").default;
export const TradeImage = require("@Images/Account/tradeImage.png").default;
export const PortofolioImage = require("@Images/Account/portofolioImage.png").default;
export const WatchImage = require("@Images/Account/watchImage.png").default;
export const deleteIcon = require("@Images/Playlist/deleteIcon.png").default;
export const editIcon = require("@Images/Playlist/editIcon.png").default;
export const forwardIcon = require("@Images/Playlist/forwardIcon.png").default;
export const deleteSelectedIcon = require("@Images/Playlist/deleteSelectedIcon.png").default;
export const likeSelectedIcon = require("@Images/Playlist/likeSelectedIcon.png").default;
export const forwardIconSelected = require("@Images/Playlist/forwardIconSelected.png").default;
export const closeButton = require("@Images/closeButton.png").default;
export const katasticLogoForModal = require("@Images/katasticLogoForModal.png").default;
export const BankAccountEditImage = require("@Images/Wallet/bankaccounteditimage.png").default;
export const WalletImage = require("@Images/Wallet/walletImage.png").default;
export const CreditCardImage = require("@Images/Wallet/CreditCardImage.png").default;
export const ValidationImage = require("@Images/Wallet/ValidationImage.png").default;
export const expandCategories = require("@Images/expandCategoriesButton.png").default;
export const investButtonPlay = require("@Images/InvestButtonPLAY.png").default;
export const investButtonBuy = require("@Images/InvestButtonBuy.png").default;
export const positiveGrowth = require("@Images/positiveGrowth.png").default;
export const negativeGrowth = require("@Images/negativeGrowth.png").default;
export const addToPlaylist = require("@Images/addToPlaylist.png").default;
export const playButton = require("@Images/playButton.png").default;
export const editButtonPink = require("@Images/Playlist/editButtonPink.png").default;
export const textFieldRightSide = require("@Images/textFieldRightSide.png").default;
export const buyButton = require("@Images/buyButton.png").default;
export const stopLimit = require("@Images/stopLimit.png").default;
export const blackLogo = require("@Images/logo_k_black.png").default;
export const blackLogo2x = require("@Images/logo_k_black_2x.png").default;
export const pinkLogo = require("@Images/logo_k_pink.png").default;
export const pinkLogo2x = require("@Images/logo_k_pink_2x.png").default;
export const unChecked = require("@Images/unChecked.png").default;
export const checked = require("@Images/checked.png").default;
export const goInvest = require("@Images/goInvest.svg").default;
export const cancelOrder = require("@Images/cancelOrder.png").default;
export const cancelOrderWhite = require("@Images/cancelOrderWhite.png").default;
export const hpgPlay = require("@Images/Homepage/play2.png").default;
