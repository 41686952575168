import { ButtonPink } from "@Components";
import { useRouter } from "@Hooks";
import Grid from "@material-ui/core/Grid";
import lscache from "lscache";
import React from "react";

const Step12Signup = (props) => {
  const Router = useRouter();
  const { isVisible } = props;

  const redirectToProfile = async () => {
    lscache.set("mediaID", null);
    Router.push("/account/profile");
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems={`center`}
      justify={`center`}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <h4>We're checking your info</h4>
        <h4 className={`bloodPink mt-2-rem`}>
          You'll get an email <br /> with more instructions soon
        </h4>

        <h3 className={`mt-2-rem`}>For now, enjoy your user account!</h3>
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <ButtonPink
          onClick={redirectToProfile}
          variant={"contained"}
          color={"primary"}
          disableElevation
        >
          Go to Profile
        </ButtonPink>
      </Grid>
    </Grid>
  );
};

export default Step12Signup;
