import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { useRouter } from "@Hooks";
import { Grid } from "@material-ui/core";
import React from "react";
import PopularCard from "./EventCards/PopularCard";

const PopularEvents = props => {
  const Router = useRouter();
  const { popularEvents } = props;

  const goToCart = artistID => {
    console.log("GOTO MEDIA DETAILS FOR INVEST ID: ", artistID);
    Router.push({
      pathname: `/eventsCart/${artistID}`
    });
  };

  return (
    <Grid container className={"popularEvents-container"}>
      <Grid item md={12} lg={12}>
        <h2 className={"title"}>POPULAR EVENTS</h2>
        <Carousel
          plugins={["arrows"]}
          slidesPerPage={3}
          breakpoints={{
            640: {
              slidesPerPage: 1,
              arrows: false
            },
            900: {
              slidesPerPage: 3,
              arrows: false
            }
          }}
        >
          {popularEvents.map(item => (
            <PopularCard item={item} key={item.id} navigateOn={goToCart} />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default PopularEvents;
