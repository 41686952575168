import { Grid } from '@material-ui/core';
import React from 'react';

import { CustomUtilityBtn } from '@Components';
import {
  artistLikeButton,
  artistShareButton,
  artistProfileButton,
  addToPlaylist,
} from '@Helpers/ImageRoutes/ArtistDetails';

const VideoDetails = props => {
  const { artist } = props;

  return (
    <Grid container>
      <Grid item xs={6}>
        <div className={'imgContainer'}>
          <img
            src={artist.artistProfileImg}
            alt="artist"
            className={'artistLogo'}
          />
        </div>
        <div className={'infoContainer'}>
          <h2 className="trackName">{artist.trackName}</h2>
          <h4 className="artist-Name">{artist.artistName}</h4>
          <p className="followersCount">followers: {artist.followers}</p>
        </div>
      </Grid>

      <Grid item xs={6}>
        <div className={'postContainer'}>
          <p className={'postDate'}>Posted on {artist.postDate}</p>
          <div className={'utilityBtns'}>
            <div className={'btnItem'}>
              <CustomUtilityBtn icon={artistProfileButton} rotate={0} />
            </div>
            <div className={'btnItem'}>
              <CustomUtilityBtn icon={artistShareButton} rotateIcon={-90} />
            </div>
            <div className={'btnItem'}>
              <CustomUtilityBtn icon={artistLikeButton} rotateIcon={-90} />
            </div>
            <div className={'last-btnItem'}>
              <CustomUtilityBtn
                icon={addToPlaylist}
                rotate={0}
                marginRight={8}
              />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default VideoDetails;
