import React, { useState, useEffect } from "react";
import InvestTabs from "@Components/InvestTabs";
import InvestHeader from "@Components/InvestHeader";
import InvestList from "@Components/InvestList";
import { RESTjava } from "@Utils";

export const InvestProjects = (props) => {
  const [investList, setInvestList] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [selectedTab, setSelectedTab] = useState("CATEGORIES");
  const [selectedCategory, setSelectedCategory] = useState("project");

  const getData = async (event) => {
    await RESTjava.get(
      `/api/invest/getInvestsByCategory?category=project&page=0&size=20`
    )
      .then((answer) => {
        var data = answer.data.content;
        var list = [];
        // eslint-disable-next-line
        data.map((item) => {
          let entry = {
            id: item.id,
            artistId: item.artist.id,
            artist: item.artist.name,
            rights: item.rights,
            share: item.share,
            offeringPrice: item.offeringPrice,
            imgSrc: item.photoPath,
            videoId: item.videoId,
            projectType: item.projectType,
            rightsOffered: item.rightsOffered,
            rightsSold: item.rightsSold,
            dueDate: item.dueDate,
            title: item.title,
            releaseDate: item.releaseDate,
          };
          list.push(entry);
        });
        setInvestList(list);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  if (isPageLoading) {
    return (
      <div className={"investPage"}>
        <InvestHeader />
        <InvestTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </div>
    );
  } else {
    return (
      <div className={"investPage"}>
        <InvestHeader />
        <InvestTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <InvestList
          list={investList}
          tab={selectedTab}
          selectedCategory={selectedCategory}
        />
      </div>
    );
  }
};

export default InvestProjects;
