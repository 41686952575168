import React from "react";
import Grid from "@material-ui/core/Grid";

const InvestListHeader = (props) => {
  return (
    <Grid container spacing={0} className={"listHeader"}>
      <Grid item xs={1}>
        <p>ARTIST</p>
      </Grid>
      <Grid item xs={1}>
        <p>PROJECT NAME</p>
      </Grid>
      <Grid item xs={1}>
        <p>PROJECT TYPE</p>
      </Grid>

      <Grid item xs={1}>
        <p>RIGHTS OFFERED</p>
      </Grid>
      <Grid item xs={1}>
        <p>RIGHTS SOLD</p>
      </Grid>
      <Grid item xs={1}>
        <p>SHARE</p>
      </Grid>
      <Grid item xs={1}>
        <p>OFFERING PRICE</p>
      </Grid>
      <Grid item xs={1}>
        <p>IRO START DATE</p>
      </Grid>
      <Grid item xs={1}>
        <p>IRO END DATE</p>
      </Grid>
    </Grid>
  );
};

export default InvestListHeader;
