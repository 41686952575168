import { RESTjavaInApp } from '@Utils';
import React, { useEffect, useState } from "react";
import AccountTypeField from "./AccountTypeField";
import DataField from "./DataField";
import PasswordField from "./PasswordField";
const UserDetails = () => {
  const [isPageLoading, setIsPageLoading] = useState(null);

  const [name, setName] = useState("default name");
  const [mail, setMail] = useState("default mail");
  const [physicalAddress, setPhysicalAddress] = useState(
    "default physicalAddress"
  );
  const [password, setPassword] = useState("****************");
  const [type, setType] = useState("default type");
  const [userProfile, setUserProfile] = useState(null);

  // const getData = async (event) => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${lscache.get("accessToken")}`);
  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   await fetch(
  //     "https://katastic-node.wtzconsult.com/api/v1/user/profile",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((answer) => {
  //       console.log("ANSWER:", answer);
  //       setName(answer.body.username);
  //       setMail(answer.body.email);
  //       setPassword(answer.body.password);
  //       setPhysicalAddress(answer.body.address);
  //       if (answer.body.isPlatinum) {
  //         setType("Premium");
  //       } else {
  //         setType("Basic");
  //       }
  //       setIsPageLoading(false);
  //     })
  //     .catch((err) => {
  //       if (err && err.response && err.response.data) {
  //         console.log(`Error`, err.response.data)
  //       }
  //     });

  //   // await REST.get(`/user/profile`)
  //   //   .then((answer) => {
  //   //     setName(answer.data.body.username);
  //   //     setMail(answer.data.body.email);
  //   //     setPassword(answer.data.body.password);
  //   //     setPhysicalAddress(answer.data.body.address);
  //   //     if (answer.data.body.isPlatinum) {
  //   //       setType("Premium");
  //   //     } else {
  //   //       setType("Basic");
  //   //     }
  //   //     setIsPageLoading(false);
  //   //   })
  //   //   .catch((err) => {
  //   //     if (err && err.response && err.response.data) {
  //   //       const { message } = err.response.data;
  //   //     }
  //   //   });
  // };



  const getData = async () => {
    await RESTjavaInApp.get(`/api/v1/users/getUserProfile`)
      .then((answer) => {
        console.log("ANSWER user profile :", answer);

        setUserProfile(answer.data)


        setName(answer.body.username);
        setMail(answer.body.email);
        setPassword(answer.body.password);
        setPhysicalAddress(answer.body.address);
        if (answer.body.isPlatinum) {
          setType("Premium");
        } else {
          setType("Basic");
        }
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          console.log(`Error user profile: `, err.response.data)
        }
      });
  };

  console.log("user profile state >>> ", userProfile)

  useEffect(() => {
    getData();
  }, []);

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <div className={"UserDetails"}>
        {userProfile &&
          <DataField
            id={"1"}
            textData={"User Name"}
            data={userProfile.username}
            setData={setName}
            defaultName={setName}
            refreshData={getData}
          />}
        {userProfile && <DataField
          id={"2"}
          textData={"Email"}
          data={userProfile.email}
          setData={setMail}
          refreshData={getData}
        />}
        {/* <DataField
          id={"3"}
          textData={"Physical address"}
          data={physicalAddress}
          setData={setPhysicalAddress}
          refreshData={getData}
        /> */}
        {userProfile && <PasswordField
          id={"1"}
          textData={"Password"}
          data={password}
          setData={setPassword}
          refreshData={getData}
        />}
        {userProfile && <AccountTypeField
          id={"1"}
          textData={"Account Type"}
          data={userProfile.userType}
          setData={setType}
        />}

      </div>
    );
  }
};

export default UserDetails;
