import React from 'react';
import Grid from '@material-ui/core/Grid';

const WatchlistListHeader = props => {
  return (
    <Grid container spacing={0} className={'watchlistListHeader'}>
      <Grid item xs={3} style={{ textAlign: 'start' }}>
        <p>ARTIST</p>
      </Grid>

      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <p>BUY</p>
      </Grid>

      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <p>SELL</p>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <p>LIMIT PRICE</p>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'end' }}>
        <p>MARKET PRICE</p>
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'end' }}></Grid>
    </Grid>
  );
};

export default WatchlistListHeader;
