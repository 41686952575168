import Grid from '@material-ui/core/Grid';
import Moment from 'moment';
import React from 'react';
const SeeAllEventsList = props => {
  const { list } = props;

  return (
    <Grid container className={'seeAllEntryEvents'}>
      <Grid item xs={1} className={'dateTextRed'}>

        {Moment(list.eventDate).format("DD.MM.YYYY")}


      </Grid>

      <Grid item xs={1} className={'dateText'}>
        {list.city}
      </Grid>

      <Grid item xs={1} className={'dateText'}>
        {list.country}
      </Grid>
      <Grid item xs={6} className={'descriptionText'}>
        {list.description}
      </Grid>
      {list.valid ? (
        <Grid item xs={1}>
          <p className="buyContainer">BUY {list.ticketPrice}$ </p>
        </Grid>
      ) : (
        <Grid item xs={1}>
          <p className="soldContainer">SOLD</p>
        </Grid>
      )}
    </Grid>
  );
};

export default SeeAllEventsList;
