import { categoryIcon } from '@Helpers/ImageRoutes/ArtistDetails';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';


const MarketFilters = () => {

    const [categoryOpen, setCategoryOpen] = useState(false)

    const handleCategory = () => {
        setCategoryOpen(!categoryOpen)
    }

    const ExpandCategory = () => {
        return (
            <div style={{ display: "flex", flexDirection: "row", marginRight: '118px' }}>
                <div className={`expanded-category`} onClick={() => handleCategory()} >
                    <img src={categoryIcon} className={'category-icon'} alt="" />
                    <div className={'category-text'}>
                        <h6 className={'btn-label'}>CATEGORY</h6>
                        <p className={'category-list'}>APPAREL</p>
                        <p className={'category-list'}>ACCESSORIES</p>
                        <p className={'category-list'}>GIFTS</p>
                        <p className={'category-list'}>FASHION</p>
                        <p className={'category-list'}>AUTO</p>
                    </div>
                </div>
            </div>
        )
    }

    const CategoryBtn = () => {
        return (
            <div className={'category-btn'} onClick={() => handleCategory()}>
                <img src={categoryIcon} className={'category-icon'} alt="" />
                <div className={'category-text'}>
                    <h6 className={'btn-label'}>CATEGORY</h6>
                </div>
            </div>
        )
    }


    return (
        <Grid container spacing={0}>
            <Grid item xs={6} className={'filters-container'}>

                {categoryOpen ?
                    (
                        <ExpandCategory />
                    )
                    :
                    (
                        <CategoryBtn />
                    )
                }

                {/* <div className={'category-btn'} onFocus={handleCategory}>
                    <img src={categoryIcon} className={'category-icon'} />
                    <div className={'category-text'}>
                        <h6 className={'btn-label'}>CATEGORY</h6>
                    </div>
                </div> */}

                {/* {filterOpen ?
                    (
                        <div style={{ display: "flex", flexDirection: "row", marginRight: '96.6px' }}>
                            <div className={'expanded-filters'} onClick={() => handleFilter()}>
                                <img src={filtersIcon} className={'category-icon'} />
                                <div className={'category-text'}>
                                    <h6 className={'btn-label'}>FILTERS</h6>
                                    <h6 className={'category-list'}>RATING</h6>
                                    <h6 className={'category-list'}>PRICE</h6>
                                    <h6 className={'category-list'}>NEW</h6>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className={'filters-btn'} onClick={() => handleFilter()} >
                            <img src={filtersIcon} className={'category-icon'} />
                            <div className={'category-text'}>
                                <h6 className={'btn-label'}>FILTERS</h6>
                            </div>
                        </div>
                    )
                } */}



                {/* <div className={'basket-btn'}>
                    <img src={basketIcon} className={'basket-icon'} />
                </div> */}
            </Grid>

        </Grid>
    )
}

export default MarketFilters;
