import CustomModal from "@Components/CustomModal";
import {
  BankAccountEditImage,
  CreditCardImage, WalletImage
} from "@Helpers/ImageRoutes/Assets";
import {
  IconButton
} from "@material-ui/core";
import { RESTjava } from "@Utils";
import AddMoneyToWallet from "@Views/Website/accountWallet/Components/AddMoneyToWallet";
import lscache from "lscache";
import React, { useEffect, useState } from "react";
import BankAccountForm from "./BankAccountForm";

const BankAccount = props => {
  const {
    cardNumber,
    fullName,
    expireDate,
    cvc,
    setCardNumber,
    setFullName,
    setExpireDate,
    setCvc
  } = props;

  const [cardNumberToShow, setCardNumberToShow] = useState(cardNumber);

  const [modalIsVisible, setIsModalVisible] = useState(false);
  const [moneyModalVisible, setMoneyModalVisible] = useState(false);
  const [cardDetails, setCardDetails] = useState({});

  useEffect(() => {
    setCardNumberToShow(cardNumberToShow.replace(/(.{4})/g, "$1 ").trim());
    // eslint-disable-next-line
  }, [cardNumber]);

  const handleModal = () => {
    setIsModalVisible(true);
  };

  const save = () => {
    setIsModalVisible(false);
    updateData();
  };

  const updateData = async event => {
    var details = {
      cardNumber: cardNumber,
      cvc: cvc,
      expireDate: expireDate,
      fullName: fullName
    };

    await RESTjava.post(
      `/api/wallet/card/addCard?userEmail=${lscache.get("userEmail")}`,
      details
    )
      .then(answer => {
        console.log("DATA UPDATED SUCCESFULLY", answer);
        props.refreshData();
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("ERROR SAVE NEW CARD: ", message);
        }
      });
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="BankAccount">
      {modalIsVisible ? (
        <CustomModal
          open={modalIsVisible}
          setOpen={setIsModalVisible}
          buttonPressed={save}
          buttonText={"SAVE"}
        >
          <IconButton className={"CreditCardImage"}>
            <img src={CreditCardImage} alt="" />
          </IconButton>

          <BankAccountForm
            fullName={fullName}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            expireDate={expireDate}
            cvc={cvc}
            setFullName={setFullName}
            setExpireDate={setExpireDate}
            setCvc={setCvc}
            setCardDetails={setCardDetails}
            cardDetails={cardDetails}
          />
        </CustomModal>
      ) : null}

      {moneyModalVisible && (
        <AddMoneyToWallet
          moneyModalVisible={moneyModalVisible}
          setMoneyModalVisible={setMoneyModalVisible}
          refreshPage={refreshPage}
        />
      )}

      <div className="BankAccountRow">
        {/* //Title */}
        <div className="BankAccountTitle">
          <p>Bank Account</p>
        </div>

        {/* //EditImage */}
        <IconButton className="BankAccountButton" onClick={handleModal}>
          <img src={BankAccountEditImage} alt="" />
        </IconButton>

        <IconButton
          className="BankAccountButton"
          onClick={() => setMoneyModalVisible(true)}
        >
          <img src={WalletImage} alt="" />
        </IconButton>
      </div>

      {/* //CardNumber */}
      <div className={"SecondaryInfo"}>
        <p>{cardNumber.replace(/(.{4})/g, "$1 ").trim()}</p>
      </div>
    </div>
  );
};

export default BankAccount;
