import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { RouterPush } from '@Utils';
import MediaTopStoriesList from './Components/MediaTopStoriesList.js';
import { RESTjava } from '@Utils';

import { mediaTopStoriesCarousel } from '@Utils/mocks/mediaTopStoriesCarousel';

const MediaTopStories = () => {
  const [mediaTopStoriesList, setMediaTopStoriesList] = useState(
    mediaTopStoriesCarousel
  );

  const getTopStories = async event => {
    await RESTjava.get(
      `/api/media/getMediaByCategory?category=TopStories`
    ).then(answer => {
      var data = answer.data;
      setMediaTopStoriesList(data);
    });
  };

  useEffect(() => {
    getTopStories();
  }, []);

  return (
    <Grid container className="MediaTopStories">
      <Grid item xs={12} className="titleContainer">
        <p className="titleMedia">MEDIA</p>
      </Grid>
      <Grid item xs={12} className="subTitleContainer">
        <p className="subTitleMedia">To Read</p>
      </Grid>

      <Grid container className="categoriesContainer">
        <div className="forYouCategory" onClick={RouterPush('/mediaForYou')}>
          <p className="forYouText">FOR YOU</p>
        </div>
        <div
          className="editorsPickCategory"
          onClick={RouterPush('/mediaEditorsPick')}
        >
          <p className="editorsPickText">EDITOR'S PICK</p>
        </div>
        <div
          className="topStoriesCategory"
          onClick={RouterPush('/mediaTopStories')}
        >
          <p className="topStoriesText">TOP STORIES</p>
        </div>
      </Grid>
      <MediaTopStoriesList forYouList={mediaTopStoriesList} />
    </Grid>
  );
};
export default MediaTopStories;
