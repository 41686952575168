import React from "react";
import Grid from "@material-ui/core/Grid";

const TradeListHeader = (props) => {
  const {
    forActiveTrades,
  } = props;

  return forActiveTrades ? (
    <Grid container className={"tradeListHeader"}>
      <Grid item xs={2} style={{ textAlign: "start" }}>
        ARTIST
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        PROJECT NAME
      </Grid>
      <Grid item xs={1} style={{ textAlign: "center" }}>
        PROJECT TYPE
      </Grid>
      <Grid item xs={1} style={{ textAlign: "center" }}>
        CODE
      </Grid>
      <Grid item xs={1} style={{ textAlign: "center" }}>
        NR OF UNITS
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        BUY
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        SELL
      </Grid>

      <Grid item xs={1} style={{ textAlign: "end" }}></Grid>
    </Grid>
  ) : (
    <Grid container className={"tradeListHeader"}>
      <Grid item xs={2} style={{ textAlign: "start" }}>
        ARTIST
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        PROJECT NAME
      </Grid>
      <Grid item xs={1} style={{ textAlign: "center" }}>
        PROJECT TYPE
      </Grid>
      <Grid item xs={1} style={{ textAlign: "center" }}>
        CODE
      </Grid>
      {/*<Grid item xs={2} style={{ textAlign: 'center' }}>*/}
      {/*  PAR/BUY/SELL*/}
      {/*</Grid>*/}
      <Grid item xs={1} style={{ textAlign: "center" }}>
        IRO PRICE
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        BUY
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        SELL
      </Grid>
      <Grid item xs={1} style={{ textAlign: "end" }}></Grid>
    </Grid>
  );
};

export default TradeListHeader;
