import { closeButton } from "@Helpers/ImageRoutes/Assets";
import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";

const RightsBuyedModal = (props) => {
  const {
    open,
    setOpen,
    artistName,
    projectType,
    rights,
    share,
    offeringPrice,
    selled,
    rightsOffered,
  } = props;
  // eslint-disable-next-line
  const [closeModalVisible, setCloseModalVisible] = useState(false);

  const handleCloseModal = () => {
    setCloseModalVisible(false);
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <div
        className={"rightsBuyedModal"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(9px)",
          width: "100%",
          height: "100%",
        }}
      >
        <div className={"modal"}>
          <div className={"insideModal"}>
            <div className={"modal--1"}>
              <div className={"congratulations"}>CONGRATULATIONS</div>
              <div className={"rightsBuyedModal-rightsDetails"}>
                <div className={"rightsBuyedModal-rightsDetails-firstColumn"}>
                  <div>{artistName}</div>
                  <div>{projectType}</div>
                  <div>
                    Rights: {rights}/{rightsOffered}
                  </div>
                </div>
                <div className={"rightsBuyedModal-rightsDetails-secondColumn"}>
                  <div>SHARE</div>
                  <div></div>
                  <div style={{ paddingTop: "25px", paddingLeft: "10px" }}>
                    {share}%
                  </div>
                </div>
                <div className={"rightsBuyedModal-rightsDetails-thirdColumn"}>
                  <div>&nbsp;&nbsp;&nbsp;OFFERING</div>
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PRICE
                  </div>
                  <div>{offeringPrice} USD/Right</div>
                </div>
              </div>
              {console.log("SELLED ", selled)}
              {selled ? (
                <div className={"addedToPortofolio"}>
                  SELLED FROM YOUR PORTFOLIO
                </div>
              ) : (
                <div className={"addedToPortofolio"}>
                  ADDED TO YOUR PORTFOLIO
                </div>
              )}

              <div
                className="addCommentBtnContainer"
                onClick={handleCloseModal}
              >
                <div className="addCommentBtnBg">
                  <p className="addCommentBtnText">DONE</p>
                </div>
              </div>
            </div>
            <div className={"modal--2"}>
              <div className={"closeButton"} onClick={handleCloseModal} alt="">
                <img
                  src={closeButton}
                  style={{
                    width: "41px",
                    height: "30px",
                    marginTop: "15px",
                    marginLeft: "145px",
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RightsBuyedModal;
