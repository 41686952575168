import React from 'react';

const PopularCard = props => {
  const { item, navigateOn } = props;
  return (
    <div className={'popularCard-container'}
      onClick={() => navigateOn(item.id)}
    >
      <div className={'photo-container'}>
        <img src={item.eventPhotoPath} className={'artist-photo'} alt="" />
      </div>
      <div className={'info-container'}>
        <h2>{item.artistName}</h2>
        <p className={'info-location'}>
          {item.city}, &nbsp;
          {item.country}
        </p>
      </div>
    </div>
  );
};

export default PopularCard;
