const registerFormSchemaStep2 = {
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
      message: 'must be at least 8 characters',
    },
    format: {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ ,
        message: "only alphanumeric with least one uppercase and one number"
      }
  },

  passwordConfirm: {
    equality: 'password',
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 8,
      message: 'must be at least 8 characters',
    },
  },
};

export default registerFormSchemaStep2;
