import Grid from '@material-ui/core/Grid';
import React from 'react';

const ItemInfo = props => {
    const { eventData } = props;
    const { guests } = eventData;

    return (
        <Grid container className={'eventsCars-root'}>
            <Grid item xs={6} md={6} lg={6} xl={6} className={'ticketInfo-container'}>
                <div className={'artist-name'}>
                    <h3 className={'title'}>{eventData.artistName}</h3>
                    <h6 className={'location'}>{eventData.city}, &nbsp; {eventData.country}</h6>
                </div>

                <div className={'guests-container'}>
                    <h4 className={'guest-title'}>Guests:</h4>
                    {guests === null ?
                        (<ul>

                            <li className={'guest-item'}>Guest 1</li>
                            <li className={'guest-item'}>Guest 2</li>
                            <li className={'guest-item'}>Guest 3</li>
                        </ul>)
                        :
                        (
                            <ul>
                                {guests && guests.map(item => <li key={item.id} className={'guest-item'}>{item}</li>)}
                            </ul>
                        )}

                </div>
            </Grid>

            <Grid item xs={6} md={6} lg={6} xl={6} className={'info-container'}>
                <h2 className={'title'}>Info</h2>
                <h5 className={'content'}>It is a long established fact that a reader will be distracted by....</h5>
            </Grid>

            <Grid item xs={12} md={12} lg={12} xl={12} >

            </Grid>
        </Grid>
    )

}
export default ItemInfo;