import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Girdle, SocialImg } from '@Styled/Login.style';
import {
  FbLogo,
  GoogleLogo,
  InstaLogo,
  TwitterLogo,
} from '@Helpers/ImageRoutes/Social';

const SignInSocial = () => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={3} md={5} xl={5} className={`p-0`}>
        <Girdle />
      </Grid>
      <Grid item xs={6} md={2} xl={2}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={3}>
            <SocialImg src={FbLogo} alt="Facebook" />
          </Grid>
          <Grid item xs={3}>
            <SocialImg src={GoogleLogo} alt="Google" />
          </Grid>
          <Grid item xs={3}>
            <SocialImg src={InstaLogo} alt="Instagram" />
          </Grid>
          <Grid item xs={3}>
            <SocialImg src={TwitterLogo} alt="Twitter" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} md={5} xl={5} className={`p-0`}>
        <Girdle />
      </Grid>
    </Grid>
  );
};

export default SignInSocial;
