const secondStepSchema = {
  trackLink: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

export default secondStepSchema;
