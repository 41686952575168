export const musicDownloadBtn = require("@Images/MusicPlayer/musicDownloadBtn.svg").default;
export const headphonesBtn = require("@Images/MusicPlayer/headphonesBtn.svg").default;
export const repeatBtn = require("@Images/MusicPlayer/repeatBtn.svg").default;
export const shuffleBtn = require("@Images/MusicPlayer/shuffleBtn.svg").default;
export const playBtn = require("@Images/MusicPlayer/playBtn.svg").default;
export const forwardBtn = require("@Images/MusicPlayer/forwardBtn.svg").default;
export const backwardBtn = require("@Images/MusicPlayer/backwardBtn.svg").default;
export const progressBarDot = require("@Images/MusicPlayer/progressBarDot.svg").default;
export const pauseBtn = require("@Images/MusicPlayer/pauseBtn.svg").default;
export const unkownArtist = require("@Images/MusicPlayer/unknownArtist.jpg").default;


