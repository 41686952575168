import { Plus } from '@Helpers/ImageRoutes/Market';
import React from 'react';



const MarketItem = props => {
    const { item } = props;

    return (
        <div className={'market-item'}>
            <div
                className={'item'}
                onClick={() => console.log('YOU CLICKED ON ', item.name)}
            >
                <div>
                    <img src={item.imgSrc} className={'item-img'} alt="" />
                </div>
                <div className={'info-container'}>
                    <div className={'info-text'}>
                        <h4>
                            {item.itemName}
                        </h4>
                        <h6>
                            {item.price} {item.currency}
                        </h6>
                    </div>
                    <div className={'buy-btn'}>
                        <img src={Plus} alt='plus' className={'btn'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketItem;
