import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { closeButton, katasticLogoForModal } from "@Helpers/ImageRoutes/Assets";

const CustomModal = props => {
  const {
    open,
    setOpen,
    children,
    buttonPressed,
    buttonText,
    closeCloseButton,
    showSecondButton,
    secondButtonPressed,
    secondButtonText
  } = props;
  const [closeModalVisible, setCloseModalVisible] = useState(false);

  const handleCloseModal = () => {
    setCloseModalVisible(false);
    setOpen(false);
  };

  const showModalContent = () => {
    setCloseModalVisible(false);
  };

  const showCloseModalContent = () => {
    setCloseModalVisible(true);
  };

  const screenWidth = window.innerWidth;

  if (!closeModalVisible) {
    return (
      <Modal open={open} onClose={handleCloseModal}>
        <div
          className={"intoModalWrapper"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(9px)",
            width: "100%",
            height: "100%"
          }}
        >
          <div className={"modal"}>
            <div className={"insideModal"}>
              <div className={"modal--1"} style={{ padding: screenWidth > 500 ? "40px" : 0 }}>
                {children}
              </div>
              <div className={"modal--2"}>
                <div className={"closeButton"} onClick={showCloseModalContent}>
                  {!closeCloseButton ? (
                    <img
                      src={closeButton}
                      style={{
                        width: "41px",
                        height: "30px",
                        marginTop: "15px",
                        marginLeft: "145px"
                      }}
                      alt=""
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
                <div>
                  <img
                    src={katasticLogoForModal}
                    style={{
                      width: screenWidth > 500 ? "150px" : "100px",
                      height: screenWidth > 500 ? "100px" : "70px",
                      marginTop: screenWidth > 500 ? "60px" : 0,
                      marginLeft: screenWidth > 500 ? 0 : 20,
                    }}
                    alt=""
                  />
                </div>
              </div>
            </div>

            {showSecondButton ? (
              <div className={"buttonsContainer"}>
                <div className={"firstButton"} onClick={secondButtonPressed}>
                  {secondButtonText}
                </div>
                <div className={"secondButton"} onClick={buttonPressed}>
                  {buttonText}
                </div>
              </div>
            ) : (
              <div className={"buttonContainer"} onClick={buttonPressed}>
                {buttonText}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal open={open} onClose={handleCloseModal}>
        <div
          className={"intoModalWrapper"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(9px)",
            width: "100%",
            height: "100%"
          }}
        >
          <div className={"closeModal"}>
            <div onClick={showCloseModalContent}>
              <img
                src={closeButton}
                style={{
                  width: "41px",
                  height: "30px",
                  marginTop: "15px",
                  marginLeft: "645px"
                }}
                alt=""
              />
            </div>
            <div className={"closingText"}>
              <h4>Are you sure you want to close the window without saving?</h4>
            </div>
            <div className={"buttonsCloseModalContainer"}>
              <div className={"buttonCloseModal"} onClick={handleCloseModal}>
                YES
              </div>
              <div className={"buttonCloseModal"} onClick={showModalContent}>
                NO
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};

export default CustomModal;
