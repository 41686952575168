import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import InvestPortofolioRoyaltiesEntry from "@Components/InvestPortofolioRoyaltiesEntry";
import InvestPortofolioRoyaltiesListHeader from "@Components/InvestPortofolioRoyaltiesListHeader";
import PortofolioTabs from "@Components/PortofolioTabs";
import { RESTjava } from "@Utils";
import lscache from "lscache";
import Chart from "./Components/Chart";

const InvestPortofolioRoyalties = (props) => {
  const [selectedTab, setSelectedTab] = useState("ROYALTIES");
  const [dataList, setDataList] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedEntryPrice, setSelectedEntryPrice] = useState(false);

  const getData = async (event) => {
    await RESTjava.get(
      `/api/invest/getUserRights?userEmail=${lscache.get("userEmail")}`
    )
      .then((answer) => {
        var data = answer.data;
        var list = [];
        // eslint-disable-next-line
        data.map((item) => {
          console.log("ITEM: ", item);
          let entry = {
            id: item.id,
            artistId: item.invest.artist.id,
            artist: item.invest.artist.name,
            rights: item.rights,
            share: item.share,
            offeringPrice: item.invest.offeringPrice,
            imgSrc: item.invest.photoPath,
            videoId: item.invest.videoId,
            projectType: item.invest.projectType,
            value: item.value,
          };
          list.push(entry);
        });
        console.log("LIST:", list);
        setDataList(list);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const getChartData = async (id, offeringPrice) => {
    console.log("SHOW CHART FOR INVEST: ", id, " with price: ", offeringPrice);
    setSelectedEntryPrice(offeringPrice);
  };

  if (isPageLoading) {
    return (
      <Grid container className={"portofolio"}>
        <Grid container style={{ height: "500px" }}>
          <Grid item xs={6}>
            <h1>PORTFOLIO</h1>
            <h5>ACCOUNT VALUE</h5>
            <PortofolioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container>
          <InvestPortofolioRoyaltiesListHeader />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={"portofolio"}>
        <Grid container style={{ height: "400px" }}>
          <Grid item xs={12} md={6}>
            <h1>PORTFOLIO</h1>
            <h5>ACCOUNT VALUE</h5>
            <PortofolioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={"lineChart"}>
              {console.log("CHART DATA WITH: ", selectedEntryPrice)}
              <Chart dataset={selectedEntryPrice} />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <InvestPortofolioRoyaltiesListHeader />
          <div className={"royaltiesList"}>
            {dataList.map((item) => (
              <InvestPortofolioRoyaltiesEntry
                key={item.id}
                id={item.id}
                artist={item.artist}
                projectType={item.projectType}
                revenuShare={item.share}
                revenueValue={0}
                revenueCurrency={"USD"}
                yearlyYield={"0"}
                imgSrc={item.imgSrc}
                setSelectedEntry={setSelectedEntry}
                selectedEntry={selectedEntry}
                showChart={getChartData}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default InvestPortofolioRoyalties;
