import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { RouterPush } from '@Utils';
import MediaEditorList from './Components/MediaEditorList.js';
import MediaTopEditorsList from './Components/MediaTopEditorsList.js';

import { mediaEditorCarousel } from '@Utils/mocks/mediaEditorCarousel';
import { mediaTopEditorCarousel } from '@Utils/mocks/mediaTopEditorCarousel';
import { RESTjava } from '@Utils';

const MediaEditorsPick = () => {
  const [mediaEditorList, setMediaEditorList] = useState(mediaEditorCarousel);
  const [mediaTopEditorList, setMediaTopEditorList] = useState(
    mediaTopEditorCarousel
  );

  const getDataEditorList = async event => {
    await RESTjava.get(
      `/api/media/getMediaByCategory?category=EditorsPick`
    ).then(answer => {
      var data = answer.data;
      setMediaEditorList(data);
    });
  };

  const getDataTopEditorList = async event => {
    await RESTjava.get(
      `/api/media/getMediaByCategory?category=TopEditors`
    ).then(answer => {
      var data = answer.data;
      setMediaTopEditorList(data);
    });
  };

  useEffect(() => {
    getDataEditorList();
    getDataTopEditorList();
  }, []);

  return (
    <Grid container className="MediaEditorsPick">
      <Grid item xs={12} className="titleContainer">
        <p className="titleMedia">MEDIA</p>
      </Grid>
      <Grid item xs={12} className="subTitleContainer">
        <p className="subTitleMedia">Reviews</p>
      </Grid>

      <Grid container className="categoriesContainer">
        <div className="forYouCategory" onClick={RouterPush('/mediaForYou')}>
          <p className="forYouText">FOR YOU</p>
        </div>
        <div
          className="editorsPickCategory"
          onClick={RouterPush('/mediaEditorsPick')}
        >
          <p className="editorsPickText">EDITOR'S PICK</p>
        </div>
        <div
          className="topStoriesCategory"
          onClick={RouterPush('/mediaTopStories')}
        >
          <p className="topStoriesText">TOP STORIES</p>
        </div>
      </Grid>

      <MediaEditorList editorList={mediaEditorList} />
      <MediaTopEditorsList topEditorList={mediaTopEditorList} />
    </Grid>
  );
};

export default MediaEditorsPick;
