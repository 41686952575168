import React from 'react';

import MarketItem from '../MarketItem';


const RecommnededItems = (props) => {

    const { marketItems } = props;

    return (
        <div className={'recommneded-root'}>
            <div className={'recommneded-header'}>
                <h3>RECOMMENDED ITEMS</h3>
            </div>

            <div className={'market-item-root'}>
                {marketItems.map(item => (
                    <MarketItem item={item} key={item.id} />
                ))}
            </div>
        </div>
    )
}

export default RecommnededItems;
