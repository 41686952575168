export const marketItemList = [
    { id: 1, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://cdn.flightclub.com/TEMPLATE/804093/1.jpg" },
    { id: 2, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://mediarealnews.files.wordpress.com/2014/02/bac.jpg" },
    { id: 3, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Dior-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1603481985" },
    { id: 4, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Gatorade-Blue-Lagoon.png?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&trim=color&q=90&dpr=2&updated_at=1538080256" },
    { id: 1, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://cdn.flightclub.com/TEMPLATE/804093/1.jpg" },
    { id: 2, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://mediarealnews.files.wordpress.com/2014/02/bac.jpg" },
    { id: 3, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Dior-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1603481985" },
    { id: 4, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Gatorade-Blue-Lagoon.png?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&trim=color&q=90&dpr=2&updated_at=1538080256" },
    { id: 1, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://cdn.flightclub.com/TEMPLATE/804093/1.jpg" },
    { id: 2, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://mediarealnews.files.wordpress.com/2014/02/bac.jpg" },
    { id: 3, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Dior-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1603481985" },
    { id: 4, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Gatorade-Blue-Lagoon.png?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&trim=color&q=90&dpr=2&updated_at=1538080256" },
    { id: 1, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://cdn.flightclub.com/TEMPLATE/804093/1.jpg" },
    { id: 2, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://mediarealnews.files.wordpress.com/2014/02/bac.jpg" },
    { id: 3, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Dior-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1603481985" },
    { id: 4, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Gatorade-Blue-Lagoon.png?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&trim=color&q=90&dpr=2&updated_at=1538080256" },
    { id: 1, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://cdn.flightclub.com/TEMPLATE/804093/1.jpg" },
    { id: 2, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://mediarealnews.files.wordpress.com/2014/02/bac.jpg" },
    { id: 3, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Dior-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1603481985" },
    { id: 4, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Gatorade-Blue-Lagoon.png?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&trim=color&q=90&dpr=2&updated_at=1538080256" },
    { id: 1, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://cdn.flightclub.com/TEMPLATE/804093/1.jpg" },
    { id: 2, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://mediarealnews.files.wordpress.com/2014/02/bac.jpg" },
    { id: 3, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Dior-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1603481985" },
    { id: 4, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Gatorade-Blue-Lagoon.png?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&trim=color&q=90&dpr=2&updated_at=1538080256" },
    { id: 1, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://cdn.flightclub.com/TEMPLATE/804093/1.jpg" },
    { id: 2, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://mediarealnews.files.wordpress.com/2014/02/bac.jpg" },
    { id: 3, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Dior-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1603481985" },
    { id: 4, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Gatorade-Blue-Lagoon.png?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&trim=color&q=90&dpr=2&updated_at=1538080256" },
    { id: 1, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://cdn.flightclub.com/TEMPLATE/804093/1.jpg" },
    { id: 2, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://mediarealnews.files.wordpress.com/2014/02/bac.jpg" },
    { id: 3, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Dior-Product.jpg?fit=fill&bg=FFFFFF&w=700&h=500&auto=format,compress&q=90&dpr=2&trim=color&updated_at=1603481985" },
    { id: 4, itemName: "Item Name", price: 120, currency: "USD", imgSrc: "https://stockx.imgix.net/Air-Jordan-1-Retro-High-Gatorade-Blue-Lagoon.png?fit=fill&bg=FFFFFF&w=300&h=214&auto=format,compress&trim=color&q=90&dpr=2&updated_at=1538080256" },
];