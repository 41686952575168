import Grid from "@material-ui/core/Grid";
import { RESTjava } from "@Utils";
import React, { useEffect, useState } from "react";
import PopularEvents from "./Components/PopularEvents";
import UpcomingEvents from "./Components/UpcomingEvents";

export const MarketEvents = () => {
  const [popularEvents, setPopularEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  const getUpcoming = async event => {
    await RESTjava.get(`/api/event/getUpcomingEvents`).then(answer => {
      var data = answer.data;
      setUpcomingEvents(data);
    });
  };

  const getPopularEvents = async event => {
    await RESTjava.get(`/api/event/getUpcomingEvents`).then(answer => {
      var data = answer.data;
      setPopularEvents(data);
    });
  };

  useEffect(() => {
    getUpcoming();
    getPopularEvents();
  }, []);

  return (
    <Grid container style={{ marginLeft: "5%" }}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <PopularEvents popularEvents={popularEvents} />
      </Grid>

      {/* <Grid item xs={12} md={12} lg={12} xl={12}>
        <UpcomingEvents upcomingEvents={upcomingEvents} />
      </Grid> */}
    </Grid>
  );
};

export default MarketEvents;
