import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal as ModalMUI } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: `100%`,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const Modal = props => {
  const { children, isOpen, onClose } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);

  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(isOpen);

  return (
    <>
      <ModalMUI
        open={open}
        onBackdropClick={onClose}
        onEscapeKeyDown={onClose}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>{children}</div>
      </ModalMUI>
    </>
  );
};

export default Modal;
