// #region Global Imports
import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import VideoPlayer from './Components/Player/Player';
import VideoDetails from './Components/VideoDetails/VideoDetails';
import { artistInfoDetails } from '@Utils/mocks/artistInfoDetails.js';

export const PremiumContent = () => {
  // eslint-disable-next-line
  const [artistInfo, setartistInfo] = useState(artistInfoDetails);

  return (
    <Grid container className={'root'}>
      <Grid item xs={12}>
        <VideoPlayer />
      </Grid>
      <Grid item xs={12}>
        <VideoDetails artist={artistInfo} />
      </Grid>
    </Grid>
  );
};

export default PremiumContent;
