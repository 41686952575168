import React from 'react';

import { albumPicture } from '@Helpers/ImageRoutes/ArtistDetails';

const Banner = () => {
  return (
    <div className={'banner-container'}>
      <img src={albumPicture} alt="banner" className={'banner-img'} />
    </div>
  );
};

export default Banner;
