import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { expandCategories } from "@Helpers/ImageRoutes/Assets";

const InvestTabs = props => {
  const { selectedTab, selectedCategory } = props;
  const [expandCategoriesVisible, setExpandCategoriesVisible] = useState(false);

  const handleExpandCategories = () => {
    setExpandCategoriesVisible(!expandCategoriesVisible);
  };

  var categoryName = selectedCategory.toUpperCase();

  return (
    <Grid container className={"investTabsContainer"}>
      {selectedTab === "CATEGORIES" ? (
        <Grid item xs={12} md={2} className={"selectedTabStyle"}>
          {/* <div onClick={() => Router.push(`/invest/${selectedCategory}`)}>
            {categoryName}
          </div> */}
          <a
            href={`/invest/${selectedCategory}`}
            style={{ textDecoration: "none", color: "white" }}
          >
            {categoryName}
          </a>
          &nbsp;&nbsp;&nbsp;
          <img
            src={expandCategories}
            style={{ width: "13px", height: "17px" }}
            onClick={handleExpandCategories}
            alt=""
          />
        </Grid>
      ) : (
        <Grid className={"unselectedTabStyle"}>
          {/* <div onClick={() => Router.push(`/invest/${selectedCategory}`)}>
              {categoryName}
            </div> */}
          <a
            href={`/invest/${selectedCategory}`}
            style={{ textDecoration: "none", color: "white" }}
          >
            {categoryName}
          </a>
          &nbsp;&nbsp;&nbsp;
          <img
            src={expandCategories}
            style={{ width: "13px", height: "17px" }}
            onClick={handleExpandCategories}
            alt=""
          />
        </Grid>
      )}
      {selectedTab === "NEW" ? (
        <Grid item xs={12} md={3}
          className={"selectedTabStyle"}
        // onClick={() => Router.push('/invest/new')}
        >
          <a
            href="/invest/new"
            style={{ textDecoration: "none", color: "white" }}
          >
            NEW
          </a>
        </Grid>
      ) : (
        <Grid item xs={12} md={1}
          className={"unselectedTabStyle"}
        // onClick={() => Router.push('/invest/new')}
        >
          <a
            href="/invest/new"
            style={{ textDecoration: "none", color: "white" }}
          >
            NEW
          </a>
        </Grid>
      )}
      {selectedTab === "RECOMMENDED INVESTS" ? (
        <Grid item xs={12} md={3}
          className={"selectedTabStyle"}
        // onClick={() => Router.push('/invest/bestValue')}
        >
          <a
            href="/invest/recommendedInvests"
            style={{ textDecoration: "none", color: "white" }}
          >
            RECOMMENDED INVESTS
          </a>
        </Grid>
      ) : (
        <Grid item xs={12} md={3}
          className={"unselectedTabStyle"}
        // onClick={() => Router.push('/invest/bestValue')}
        >
          <a
            href="/invest/recommendedInvests"
            style={{ textDecoration: "none", color: "white" }}
          >
            RECOMMENDED INVESTS
          </a>
        </Grid>
      )}
      {selectedTab === "BEST PERFORMERS" ? (
        <Grid item xs={12} md={3}
          className={"selectedTabStyle"}
        // onClick={() => Router.push('/invest/bestValue')}
        >
          <a
            href="/invest/bestPerformers"
            style={{ textDecoration: "none", color: "white" }}
          >
            BEST PERFORMERS
          </a>
        </Grid>
      ) : (
        <Grid item xs={12} md={3}
          className={"unselectedTabStyle"}
        // onClick={() => Router.push('/invest/bestValue')}
        >
          <a
            href="/invest/bestPerformers"
            style={{ textDecoration: "none", color: "white" }}
          >
            BEST PERFORMERS
          </a>
        </Grid>
      )}
      {expandCategoriesVisible ? (
        <div className={"categoriesToShow"}>
          <div
          // onClick={() => {
          //   setExpandCategoriesVisible(false);
          //   Router.push('/invest/all');
          // }}
          >
            <a
              href="/invest/all"
              style={{ textDecoration: "none", color: "white" }}
            >
              ALL
            </a>
          </div>
          <div
          // onClick={() => Router.push('/invest/album')}
          >
            <a
              href="/invest/album"
              style={{ textDecoration: "none", color: "white" }}
            >
              ALBUM
            </a>
          </div>
          <div
          // onClick={() => Router.push('/invest/career')}
          >
            <a
              href="/invest/career"
              style={{ textDecoration: "none", color: "white" }}
            >
              CAREER
            </a>
          </div>
          <div
          // onClick={() => Router.push('/invest/song')}
          >
            <a
              href="/invest/song"
              style={{ textDecoration: "none", color: "white" }}
            >
              SONG
            </a>
          </div>
          <div
          // onClick={() => Router.push('/invest/tour')}
          >
            <a
              href="/invest/tour"
              style={{ textDecoration: "none", color: "white" }}
            >
              TOUR
            </a>
          </div>
          <div
          // onClick={() => Router.push('/invest/project')}
          >
            <a
              href="/invest/project"
              style={{ textDecoration: "none", color: "white" }}
            >
              PROJECT
            </a>
          </div>
        </div>
      ) : null}
    </Grid>
  );
};

export default InvestTabs;
