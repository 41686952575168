import React, { useState } from "react";

export const InfoBox = (props) => {
  const { title, infoText, hideInfoButton } = props;
  const [showInfoText, setShowInfoText] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // position: "relative",
      }}
    >
      <p className="inputTitle"
        style={{ marginLeft: 0 }}
      >
        {title}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // position: "relative",
        }}
      >
        {!hideInfoButton ? (
          <div>
            <div
              className="inputInfoContainer"
              onClick={() => setShowInfoText(!showInfoText)}
            >
              <p className="inputInfo">i</p>
            </div>
            {showInfoText ? (
              <div className="infoDescContainer">
                <p className="infoDescTextr">{infoText}</p>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InfoBox;
