import logo from "@Images/logo_k_pink.png";
import {
  AppBar, Hidden,
  IconButton, Toolbar
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1000,
    background: `#000`,
    // borderRadius: `0 0 25px 0`,
    height: `5em`,
    boxShadow: "none",
    fontFamily: `Arial`,
    textTransform: `uppercase`,
    "& a, a:hover, a:active, a:visited": {
      outline: `none`,
      color: "#fff",
      // padding: `0 0 .3rem 0`,
    },
  },
  logo: {
    maxWidth: `120px`,
    margin: `1rem 0 0`,
  },
  flexGrow: {
    flexGrow: 1,
    display: "flex",
  },
  flexGrow2: {
    flexGrow: 0.3,
    display: "flex",
    textAlign: `center`,
    "& a": {
      textAlign: `center`,
      display: `block`,
      width: `100%`,
    },
  },
  item: {
    "& a, a:hover, a:active, a:visited": {
      outline: `none`,
      color: "#fff",
      padding: `0 0 .5rem 0`,
      borderBottom: `1px solid #30c3b2`,
    },
    "& a:hover": {
      borderBottom: `1px solid #2d2d59`,
    },
  },
  menuIcon: {
    color: `#2d2d59`,
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, pages, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="white"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <h1>
            <img className={classes.logo} src={logo} alt="logo" height={'50'} />
          </h1>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon className={classes.menuIcon} color={`secondary`} />
          </IconButton>
        </Hidden>

        {/* <Hidden smDown>
          <List className={classes.flexGrow2}>
            {pages.map((page) => (
              <ListItem
                className={classes.item}
                disableGutters
                key={page.title}
              >
                <a className={classes.button} href={page.href}>
                  {page.title}
                </a>
              </ListItem>
            ))}
          </List>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
