// import InvestPortofolioRightsEntry from "@Components/InvestPortofolioRightsEntry";
import InvestPortofolioRightsListHeader from "@Components/InvestPortofolioRightsListHeader";
import PortofolioTabs from "@Components/PortofolioTabs";
import Grid from "@material-ui/core/Grid";
import { RESTjava } from "@Utils";
import lscache from "lscache";
import React, { useEffect, useState } from "react";
import Chart from "./Components/Chart";
import Indicators from './Components/Indicators';
import RightsEntryTable from './Components/RightsEntryTable';

const InvestMyProjectEntry = (props) => {
  const [selectedTab, setSelectedTab] = useState("RIGHTS");
  const [dataList, setDataList] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedEntryPrice, setSelectedEntryPrice] = useState(0);
  const [indicators, setIndicators] = useState(null);
  const [interval, setInterval] = useState("1h");
  const [tableInterval, setTableInterval] = useState("1h");
  const [projectId, setProjectId] = useState(null);
  const [totalVal, setTotalVal] = useState(null);

  const handleProjectId = (e) => {
    setProjectId(e)
  }
  const handleInterval = (e) => {
    setInterval(e)
  }

  const handleTableInterval = (e) => {
    setTableInterval(e)
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getIndicators();
    // eslint-disable-next-line
  }, [interval, projectId])

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [tableInterval])

  const getData = async () => {
    await RESTjava.get(
      `/api/project/getMyRights?page=0&size=50&userEmail=${lscache.get(
        "userEmail"
      )}&interval=${tableInterval}`
    )
      .then((answer) => {
        setDataList(answer.data.content);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
  };

  const handleTotal = async () => {
    await RESTjava.get(
      `/api/project/getMyPortofolioAveragePrice?userEmail=${lscache.get("userEmail")}`)

      .then((answer) => {
        setTotalVal(answer.data.totalAveragePrice)
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
  }
  useEffect(() => {
    handleTotal();
  }, [])

  const getIndicators = async () => {
    if (projectId) {

      await RESTjava.get(
        `/api/project/getMyProjectIndicators?interval=${interval}&projectId=${projectId}&userEmail=${lscache.get(
          "userEmail"
        )}`
      )
        .then((answer) => {
          setIndicators(answer.data)
        }).catch((err) => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            console.log(`Error message: `, message)
          }
        })
    }
  }

  const getChartData = async (id, offeringPrice) => {
    setSelectedEntryPrice(offeringPrice);
  };

  if (isPageLoading) {
    return (
      <Grid container className={"portofolio"}>
        <Grid container style={{ height: "500px" }}>
          <Grid item xs={6}>
            <h1>PORTFOLIO</h1>
            <h5>ACCOUNT VALUE</h5>
            <PortofolioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container>
          <InvestPortofolioRightsListHeader />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={"portofolio"}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <h1>PORTFOLIO</h1>
            <h5>ACCOUNT VALUE</h5>
            {projectId ? <Indicators indicators={indicators} handleInterval={handleInterval} interval={interval} /> : null}
            <PortofolioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={"lineChart"}>
              <Chart dataset={selectedEntryPrice} />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {/* <InvestPortofolioRightsListHeader
              showDueDate={true}
              tableInterval={tableInterval}
              handleTableInterval={handleTableInterval}
            /> */}
            <div className={"rightsList"}>

              {dataList && <RightsEntryTable
                data={dataList}
                valueCurrency={"USD"}
                growth={"0"}
                setSelectedEntry={setSelectedEntry}
                selectedEntry={selectedEntry}
                showChart={getChartData}
                handleProjectId={handleProjectId}
                showDueDate={true}
                tableInterval={tableInterval}
                handleTableInterval={handleTableInterval}
              />}
              {/* {dataList && dataList.map((item) => {
                return (
                   <InvestPortofolioRightsEntry
                    item={item}
                    id={item.id}
                    key={item.id}
                    valueCurrency={"USD"}
                    growth={"0"}
                    showDueDate={true}
                    setSelectedEntry={setSelectedEntry}
                    selectedEntry={selectedEntry}
                    showChart={getChartData}
                    handleProjectId={handleProjectId}
                  /> 
                )
              })} */}
            </div>
          </Grid>
          <div className={`total_container`}>
            <div className={`total_header`}>
              TOTAL AVERAGE PRICE
            </div>
            <div className={`total_body`}>
              ${Math.round(totalVal * 100) / 100}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default InvestMyProjectEntry;
