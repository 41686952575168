import { LogoBtn } from '@Helpers/ImageRoutes/Logo';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { LoginMUIStyles } from '@Styled/Login.style';
import { RESTjavaInApp } from '@Utils';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import checked from '../../../../../Assets/Images/checked.png';

const CreateArtist = (props) => {
	const { isVisible, mainFormState } = props;
	const LoginStyle = LoginMUIStyles();
	const [uploadedList, setUploadedList] = useState(null);
	const [docUploaded, setDocUploaded] = useState(false);

	const onUpload = async (files) => {
		const artistId = mainFormState.entityDetails.artistId;

		const formData = new FormData();


		files.map((item) => {
			formData.append('files', item);
			setUploadedList(formData);
		});

		if (artistId && uploadedList) {
			await RESTjavaInApp.post(`/api/v1/artists/uploadArtistFiles/${artistId}`, uploadedList)
				.then((answer) => {
					setDocUploaded(true)
				})
				.catch((err) => {
					if (err && err.response && err.response.data) {
						const { message } = err.response.data;
						console.log("CREATE USER ERROR: ", message);
					}
				});
		}
	};

	const createUser = async () => {
		window.location.href = "/login"
	}

	return (
		<Grid
			container
			spacing={3}
			alignItems="center"
			justify="center"
			direction="column"
			className={LoginStyle.root}
			style={{ display: isVisible ? 'block' : 'none' }}
		>
			<Grid item xs={12} className="mt-3-rem">
				<h1>Upload your documents</h1>
				{/* <h2>Do you want to be an investor?</h2> */}
			</Grid>

			<Grid xs={12} className="selectFieldContainer">
				<div className="selectField">
					<Dropzone
						onDrop={onUpload}
						// accept={'.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf'}
						accept={'.png,.jpeg,.jpg,.JPEG,.JPG,.pdf'}
					// disabled={isLoading}
					>
						{({ getRootProps, getInputProps }) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<p className="selectFieldText">Click or Drag 'n' drop</p>
								</div>
							</section>
						)}
					</Dropzone>
					{docUploaded ? (
						<img
							src={checked}
							onClick={() => setDocUploaded(false)}
							style={{
								cursor: 'pointer',
								height: '30px',
								width: '30px',
								marginLeft: '20px',
								marginTop: '7px'
							}}
							alt=""
						/>
					) : null}
				</div>
			</Grid>

			<Grid item xs={12}>
				{docUploaded ?
					<label htmlFor="icon-button-file" className="nextButtonContainer">
						<IconButton
							color="primary"
							component="span"
							onClick={createUser}
							type="submit"
						>
							<img src={LogoBtn} alt="" />
						</IconButton>
					</label>
					:
					null
				}

			</Grid>
		</Grid>
	);
};

export default CreateArtist;
