export const artistProfileImg = require("@Images/ArtistDetails/artistProfileImg.svg").default;
export const artistLikeButton = require("@Images/ArtistDetails/artistLikeButton.svg").default;
export const artistLikeButtonActive = require("@Images/ArtistDetails/artistLikeButtonActive.svg").default;
export const artistShareButton = require("@Images/ArtistDetails/artistShareButton.svg").default;
export const artistProfileButton = require("@Images/ArtistDetails/artistProfileButton.svg").default;
export const artistProfileButtonActive = require("@Images/ArtistDetails/artistProfileButtonActive.png").default;
export const albumPicture = require("@Images/ArtistDetails/albumPicture.svg").default;
export const addToPlaylist = require("@Images/ArtistDetails/addToPlaylist.svg").default;
export const categoryIcon = require("@Images/Market/categoryIcon.svg").default;
export const filtersIcon = require("@Images/Market/filters.svg").default;
export const basketIcon = require("@Images/Market/basket.svg").default;
export const buyBtnIcon = require("@Images/Market/buyBtn.svg").default;
export const curvedLine = require("@Images/Market/curvedLine.svg").default;
