import {
  Grid
} from '@material-ui/core';
import React from 'react';
import AccountHeader from './Components/AccountHeader';
import LogOutButton from './Components/LogOutButton';
import ProfilePhoto from './Components/ProfilePhoto';
import SettingsPanel from './Components/SettingsPanel';
import TabsButtons from './Components/TabsButtons';
import UserDetails from './Components/UserDetails';

const AccountProfile = () => {
  return (
    <section id="account">
      <Grid container>
        <Grid item md={4} lg={4}>
          <div className={'Column--1'}>
            <AccountHeader />
            <TabsButtons />
            <UserDetails />
            <LogOutButton />
          </div>
        </Grid>
        <Grid item md={2} lg={2}>
          <div className={'ProfilePhotoContainer'}>
            <ProfilePhoto />
          </div>
        </Grid>
        {/*/////////////RIGHT SIDE*/}
        <Grid item md={6} lg={6}>
          <div className={'Column--2'}>
            <SettingsPanel />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default AccountProfile;
