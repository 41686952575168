import React from 'react';
import { pinkLogo } from '@Helpers/ImageRoutes/Assets';
import { LoadingOnPage } from '@Components';

export const LoadingUntilRedirect = () => {
  return (
    <div className={`loaderAnimationAndImage loadingUntilRedirect`}>
      <img src={pinkLogo} alt="" />
      <LoadingOnPage isVisible={true}></LoadingOnPage>
    </div>
  );
};
