import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { RouteWithLayout } from "@Components";
import DesktopRoutes from "@Config/Routes";
import NoSideMenu from "@Layouts/NoSideMenu";
import WithSideMenu from "@Layouts/WithSideMenu";

const Routes = props => {
  // const { children, pathURI, asPath, parser } = props;
  const { parser } = props;

  const deviceType = parser.getDevice().type;
  // const isMobile = deviceType === "mobile";
  // const deviceRoutes = isMobile ? MobileRoutes : DesktopRoutes;
  // const layoutType = isMobile ? Mobile : Desktop;
  // const OS = parser.getOS().name;
  // const OSversion = parser.getOS().version;

  const noSideMenuPages = [
    "/register",
    "/go-platinum",
    "/go-platinum/individual",
    "/go-platinum/entity",
    "/login",
    "/terms",
    "/privacy",
    "home",
    "/",
    ""
  ];

  const renderScreens = () => {
    return DesktopRoutes.map((route, index) => {
      const layoutType = noSideMenuPages.includes(route.path)
        ? NoSideMenu
        : WithSideMenu;

      return (

        <RouteWithLayout
          component={route.component}
          layout={layoutType}
          path={route.path}
          needsAuth={route.needsAuth}
          title={route.title}
          key={`${deviceType}-route-item-${index}`}
        />
      );
    });
  };

  return (
    <Switch>
      {renderScreens()}
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
