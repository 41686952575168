import React from 'react';
import { Button, Grid } from '@material-ui/core';

const StatementLine = props => {
  const { isDaily, setIsDaily, totalAmountD, totalAmountM, currency } = props;

  const [dailySelected, setDailySelected] = React.useState(true);
  // eslint-disable-next-line
  const [monthlySelected, setMonthlySelected] = React.useState(false);

  const handleDailyStatement = () => {
    //Show StatementList
    setDailySelected(true);
    setMonthlySelected(false);
    setIsDaily(true);
  };

  const handleMonthlyStatement = () => {
    //Show StatementList
    setMonthlySelected(true);
    setDailySelected(false);
    setIsDaily(false);
  };

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = dd + '-' + mm + '-' + yyyy;

  var last = function (y, m) {
    return new Date(y, m + 1, 0).getDate();
  };

  var firstDay = '01-' + mm + '-' + yyyy + ' -> ';
  var lastDay = last(yyyy, mm) + '-' + mm + '-' + yyyy;

  return (
    <Grid className={'StatementLine'} item md={12} lg={12}>
      <Grid item md={4} lg={4}>
        <div className="Statement">
          <div className="BankAccountRow">
            {/* //Title */}
            <div className="BankAccountTitle">
              <p>STATEMENT</p>
            </div>
          </div>

          {/* //CardNumber */}
          <div className={'SecondaryInfo'}>
            {!isDaily ? (
              <p>
                {firstDay}
                {lastDay}
              </p>
            ) : (
              <p>{today}</p>
            )}
          </div>
        </div>
      </Grid>
      {dailySelected ? (
        <Grid className={'StatementRow'} item md={4} lg={4}>
          <div className={'PeriodButtonLeftSelected'}>
            <Button className={'StatementTitle'} onClick={handleDailyStatement}>
              {' '}
              DAILY{' '}
            </Button>
          </div>
          <div className={'PeriodButtonRight'}>
            <Button
              className={'StatementTitle'}
              onClick={handleMonthlyStatement}
            >
              {' '}
              MONTHLY{' '}
            </Button>
          </div>
        </Grid>
      ) : (
        <Grid className={'StatementRow'} item md={4} lg={4}>
          <div className={'PeriodButtonLeft'}>
            <Button className={'StatementTitle'} onClick={handleDailyStatement}>
              {' '}
              DAILY{' '}
            </Button>
          </div>
          <div className={'PeriodButtonRightSelected'}>
            <Button
              className={'StatementTitle'}
              onClick={handleMonthlyStatement}
            >
              {' '}
              MONTHLY{' '}
            </Button>
          </div>
        </Grid>
      )}

      <Grid item md={4} lg={4}>
        <div>
          <div className={'TotalAmountContainer'}>
            <div className="TotalAmountTitle">
              <p>TOTAL AMOUNT</p>
            </div>
            <div className="AmountRow">
              {isDaily ? (
                <p className={'SecondaryInfo'}>{totalAmountD}</p>
              ) : (
                <p className={'SecondaryInfo'}>{totalAmountM}</p>
              )}
              &nbsp; &nbsp;
              <p className={'SecondaryInfo'}>{currency}</p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default StatementLine;
