import React from 'react';
// import './items.scss';

import MarketItem from '../MarketItem';

const Items = props => {
  const { marketItems } = props;

  return (
    <div className={'market-item-root'}>
      {marketItems.map(item => (
        <MarketItem item={item} key={item.id} />
      ))}
    </div>
  );
};

export default Items;
