import React from "react";
import { Grid } from "@material-ui/core";
import { hpgPlay } from "@Helpers/ImageRoutes/Assets";
import { ButtonPink } from "@Components";

import useStyles from "./hero.style";
import { useRouter } from "@Hooks";

const Home = () => {
  const Router = useRouter();
  const classes = useStyles();

  const redirectToLogin = () => {
    Router.push("/login");
  };

  const redirectToRegister = () => {
    Router.push("/register");
  };

  return (
    <div className={classes.hero}>
      <Grid container spacing={4}>
        <Grid item lg={6} md={6} xl={6} xs={12}>
          <img className={`heroLogo`} src={hpgPlay} alt="" />
        </Grid>
        <Grid item lg={6} md={6} xl={6} xs={12}>
          <h1>A GLOBAL MUSIC INDUSTRY FULL SCALE ECONOMY</h1>
          <h2>Organize a decentralized industry.</h2>

          <div className={"hpgBtns"}>
            <ButtonPink
              onClick={redirectToLogin}
              variant={"contained"}
              color={"primary"}
              disableElevation
            >
              Login
            </ButtonPink>
            <ButtonPink
              onClick={redirectToRegister}
              variant={"contained"}
              color={"primary"}
              disableElevation
            >
              Register
            </ButtonPink>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
