export const mediaTopEditorCarousel = [
    { id: 1, name: "The revolution will not be televised", imgSrc: "https://lsinsight.org/wp-content/uploads/2018/01/lloyd-banks.jpg" },
    { id: 2, name: "50CENT", imgSrc: "https://i.pinimg.com/236x/5b/80/da/5b80dac656c4e8856b6f15d392cb25b2.jpg" },
    { id: 3, name: "LIL JOHN", imgSrc: "https://media-exp1.licdn.com/dms/image/C4D03AQFWBHESiq3doQ/profile-displayphoto-shrink_200_200/0?e=1608163200&v=beta&t=LsSt7dB-9kT4rqf0Byvhx_eyWFol6sxQa7NlWS9dYRE" },
    { id: 4, name: "PACHA MAN", imgSrc: "https://www.muzica9.ro/_pics/muzica/thumbs3/qubz5hvdee.jpg" },
    { id: 1, name: "LLOYD BANKS", imgSrc: "https://lsinsight.org/wp-content/uploads/2018/01/lloyd-banks.jpg" },
    { id: 2, name: "50CENT", imgSrc: "https://i.pinimg.com/236x/5b/80/da/5b80dac656c4e8856b6f15d392cb25b2.jpg" },
    { id: 3, name: "LIL JOHN", imgSrc: "https://media-exp1.licdn.com/dms/image/C4D03AQFWBHESiq3doQ/profile-displayphoto-shrink_200_200/0?e=1608163200&v=beta&t=LsSt7dB-9kT4rqf0Byvhx_eyWFol6sxQa7NlWS9dYRE" },
    { id: 4, name: "PACHA MAN", imgSrc: "https://www.muzica9.ro/_pics/muzica/thumbs3/qubz5hvdee.jpg" },
    { id: 1, name: "LLOYD BANKS", imgSrc: "https://lsinsight.org/wp-content/uploads/2018/01/lloyd-banks.jpg" },
    { id: 2, name: "50CENT", imgSrc: "https://i.pinimg.com/236x/5b/80/da/5b80dac656c4e8856b6f15d392cb25b2.jpg" },
    { id: 3, name: "LIL JOHN", imgSrc: "https://media-exp1.licdn.com/dms/image/C4D03AQFWBHESiq3doQ/profile-displayphoto-shrink_200_200/0?e=1608163200&v=beta&t=LsSt7dB-9kT4rqf0Byvhx_eyWFol6sxQa7NlWS9dYRE" },
    { id: 4, name: "PACHA MAN", imgSrc: "https://www.muzica9.ro/_pics/muzica/thumbs3/qubz5hvdee.jpg" },
];