import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { RouterPush } from '@Utils';

import MediaForYourList from './Components/MediaForYourList.js';
import MediaTrendingList from './Components/MediaTrendingList.js';

import { mediaForYouCarousel } from '@Utils/mocks/mediaForYouCarousel';
import { mediaTrendingCarousel } from '@Utils/mocks/mediaTrendingCarousel';

import { RESTjava } from '@Utils';

const MediaForYou = () => {
  const [mediaForYourList, setMediaForYourList] = useState(mediaForYouCarousel);
  const [mediaTrendingList, setMediaTrendingList] = useState(
    mediaTrendingCarousel
  );

  const getDataForYou = async event => {
    await RESTjava.get(`/api/media/getMediaByCategory?category=ForYou`).then(
      answer => {
        var data = answer.data;
        setMediaForYourList(data);
      }
    );
  };

  const getDataTrendingStories = async event => {
    await RESTjava.get(
      `/api/media/getMediaByCategory?category=TrendingStories`
    ).then(answer => {
      var data = answer.data;
      setMediaTrendingList(data);
    });
  };

  useEffect(() => {
    getDataForYou();
    getDataTrendingStories();
  }, []);

  return (
    <Grid container className="MediaForYou">
      <Grid item xs={12} className="titleContainer">
        <p className="titleMedia">MEDIA</p>
      </Grid>
      <Grid item xs={12} className="subTitleContainer">
        <p className="subTitleMedia">News</p>
      </Grid>

      <Grid container className="categoriesContainer">
        <div className="forYouCategory" onClick={RouterPush('/mediaForYou')}>
          <p className="forYouText">FOR YOU</p>
        </div>
        <div
          className="editorsPickCategory"
          onClick={RouterPush('/mediaEditorsPick')}
        >
          <p className="editorsPickText">EDITOR'S PICK</p>
        </div>
        <div
          className="topStoriesCategory"
          onClick={RouterPush('/mediaTopStories')}
        >
          <p className="topStoriesText">TOP STORIES</p>
        </div>
      </Grid>
      <MediaForYourList forYouList={mediaForYourList} />
      <MediaTrendingList trendingList={mediaTrendingList} />
    </Grid>
  );
};

export default MediaForYou;
