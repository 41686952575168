import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";


export const ShowSecondaryLink = props => {
  const { item, index, asPath, currentPage } = props;

  const redirect = () => {
    if (item.hardRefresh) {
      window.location.href = item.link;
      return;
    }

    window.location.href = item.link;
  };

  return (
    <div>
      <ListItem
        button
        onClick={redirect}
        key={`menu-link-${index}`}
        className={
          item.link === currentPage || item.link === asPath
            ? "activeSideMenuLink"
            : "sss"
        }
      >
        <img className="subcategoryImage" src={item.icon} alt="" />
        {item.link === currentPage || item.link === asPath ? (
          <ListItemText
            className={item.listSelectedItemTextClass}
            primary={item.text}
            key={`menu-link-text-${index}`}
          />
        ) : (
          <ListItemText
            className={item.listItemTextClass}
            primary={item.text}
            key={`menu-link-text-${index}`}
          />
        )}
      </ListItem>

      {item.hasDivider && (
        <Divider style={{ marginTop: 20 }} variant="middle"></Divider>
      )}
    </div>
  );
};
