import InvestHeader from "@Components/InvestHeader";
import InvestList from "@Components/InvestList";
import InvestTabs from "@Components/InvestTabs";
import Grid from "@material-ui/core/Grid";
import { RESTjava } from "@Utils";
import React, { useEffect, useRef, useState } from "react";

export const InvestAll = (props) => {
  const [investList, setInvestList] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [selectedTab, setSelectedTab] = useState("CATEGORIES");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const isSubscribed = useRef(true);

  const getData = async (event) => {
    await RESTjava.get(`api/project/getAllIproProjects?page=0&size=100`)
      .then((answer) => {
        if (!isSubscribed.current) return null;
        var data = answer.data.content;
        var list = [];
        // eslint-disable-next-line
        data.map((item) => {
          let entry = {
            rightsOffered: item.numberOfUnitsSold,
            rightsSold: item.numberOfRightsSold,
            dueDate: item.dueDate,
            releaseDate: item.releaseDate,
            tourFromDate: item.start,
            tourToDate: item.end,
            id: item.id,
            artistId: item.artist.id,
            title: item.title,
            artist: item.artist.name,
            rights: item.numberOfUnitsSold,
            share: item.percentage,
            offeringPrice: item.pricePerUnit,
            imgSrc: item.artist.imageUrl,
            videoId: "",
            projectType: item.category,
            value: item.estimatedValue,
          };
          list.push(entry);
        });
        setInvestList(list);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
  };

  useEffect(() => {
    getData();
    return () => {
      isSubscribed.current = false;
    };
    // eslint-disable-next-line

  }, []);

  if (isPageLoading) {
    return (
      <div className={"investPage"}>
        <InvestHeader />
        <InvestTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </div>
    );
  } else {
    return (
      <Grid container className={"investPage"}>
        <Grid item xs={12}>
          <InvestHeader />
        </Grid>
        <Grid item xs={12}>
          <InvestTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </Grid>
        <Grid item xs={12}>
          <InvestList
            list={investList}
            tab={selectedTab}
            selectedCategory={selectedCategory}
          />
        </Grid>
      </Grid>
    );
  }
};

export default InvestAll;
