import React from 'react';

const InvestHeader = props => {
  return (
    <div className={'investHeader'}>
      <h1>INVEST</h1>
      <div className={'investSubtitle'}>
        <h5>Select to invest</h5>
      </div>
    </div>
  );
};

export default InvestHeader;
