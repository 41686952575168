import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { theme as ThemeForMUI } from '@Styled/Theme';

export const GlobalMUIStyles = makeStyles(theme => ({
  textField: {
    [theme.breakpoints.down("md")]: {
    },
    width: `100%`,
    '& .MuiInputLabel-outlined': {
      color: ThemeForMUI.colors.white,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '20px',
        borderColor: ThemeForMUI.colors.primary,
        borderWidth: '2px',
        '& legend': {
          color: ThemeForMUI.colors.white,
        },
      },
      '&.Mui-focused fieldset': {
        borderColor: ThemeForMUI.colors.primary,
      },
      '&:hover fieldset': {
        borderColor: ThemeForMUI.colors.primary,
      },
      '& input': {
        color: ThemeForMUI.colors.white,
      },

      '& .MuiFormLabel-root.Mui-focused': {
        color: '#fff',
      },
    },
  },

  modalTextInput: {
    '& label.Mui-focused': {},
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FF3366',
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderBottomColor: '#FF3366',
      },
      '&:hover fieldset': {
        borderBottomColor: '#FF3366',
      },
      '&.Mui-focused fieldset': {
        borderBottomColor: '#FF3366',
        borderCollor: '#FF3366',
      },
    },
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .&MuiOutlinedInput-input': {
      color: 'white',
    },
  },

  amountInput: {
    '& .MuiInputBase-input': {
      color: 'white',
      fontSize: '20px',
      fontFamily: "rooneysansbold",
      justifyContent: "flex-end",
      height: '30px'
    },
    '& .MuiInputBase-input:before': {
      color: 'white',
    },
    '& .&MuiOutlinedInput-input': {
      color: 'white',
    },
  },

  pinkButton: {
    background: ThemeForMUI.colors.primary,
    borderRadius: 50,
    color: ThemeForMUI.colors.black,
    outline: `none`,
    borderColor: ThemeForMUI.colors.black,
    boxShadow: `0 0 10px ${ThemeForMUI.colors.primary}`,
    minWidth: 100,
    textAlign: `center`,
    display: `block`,
    margin: props => (props.notCentered ? `` : `0 auto`),
  },

  addClientContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },

  addButton: {
    width: 70,
    height: 70
  },

  clientList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",

  },

  clientName: {
    display: "flex",
    // minWidth: 100,
    // height: 20,
    backgroundColor: "rgba(192,192,192,0.3)",
    cursor: "pointer",
    color: "#ff3366",
    margin: 5,
    borderRadius: 10,

    '& p': {
      margin: 0,
      padding: 5,
      fontSize: 14,
      textTransform: "capitalize"
    }
  },

  uploaderTitle: {

  }
}));

export const CenterWrap = styled.div`
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
`;

export const CarouselWrap = styled.div`
  // max-width: 1400px;
  width: 100%;
  //margin-left: 2%;
`;

export const LinkCtaStyled = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  display: block;
  // font-family: ${({ theme }) => theme.fonts.rooney};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: ${({ theme }) => theme.letterSpacings.scattered};
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
`;

export const LinkRegularStyled = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.rooney};
  font-size: ${({ theme }) => theme.fontSizes.body};
  cursor: pointer;
`;

export const NinputWrapper = styled.div``;
