import BuyModalContent from "@Components/BuyModalContent";
import CustomModal from "@Components/CustomModal";
import InvestEndorsedBy from "@Components/InvestEndorsedBy";
import InvestHeader from "@Components/InvestHeader";
import InvestInfo from "@Components/InvestInfo";
import InvestListHeader from "@Components/InvestListHeader";
import InvestTabs from "@Components/InvestTabs";
import RightsBuyedModal from "@Components/RightsBuyedModal";
import SelectedInvestPreview from "@Components/SelectedInvestPreview";
import { getHistoryPathArray } from "@Helpers";
import { useRouter } from "@Hooks";
import { Grid } from "@material-ui/core";
import { RESTjava } from "@Utils";
import download from "downloadjs";
import lscache from "lscache";
import moment from "moment";
import React, { useEffect, useState } from "react";



const PortfolioProjectsEntry = (props) => {
  const { history } = props;
  const entrySLug = getHistoryPathArray(history);
  const entryId = entrySLug.slice(-1).toString();

  const id = entrySLug.slice(-1).toString();
  const [documentsList, setDocumentsList] = useState([]);

  const router = useRouter();
  const [project, setProject] = useState(null);
  // eslint-disable-next-line
  const [projectTitle, setProjectTitle] = useState({
    category: "Category",
    estimtedYield: "Estimated Yield",
    estimatedValue: "Estimated Value",
    genre: "Genre",
    info: "Info",
    installments: "Installments",
    location: "Location",
    percentage: "Percentage",
    projectName: "Project Name",
    tenor: "Tenor",
    valueOffer: "Value Offered",
    pricePerUnit: "Price Per Unit",
  });

  const [projectData, setProjectData] = useState([]);
  const [selectedInvest, setSelectedInvest] = useState(null);
  // eslint-disable-next-line
  const [userRating, setUserRating] = useState(0);
  // eslint-disable-next-line
  const [proRating, setProRating] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rightsAmount, setRightsAmout] = useState(0);
  const [isCongratsModalVisible, setIsCongratsModalVisible] = useState(false);
  // eslint-disable-next-line
  const [maxRightsAmountMessage, setMaxRightsAmountMessage] = useState(false);
  // eslint-disable-next-line
  const [userRightsAmount, setUserRightsAmount] = useState(0);
  // eslint-disable-next-line
  const [userShareProcent, setUserShareProcent] = useState(0);

  const selectedCategory = router.query.selectedCategory;
  const selectedTab = router.query.tab;

  const getData = async (event) => {
    await RESTjava.get(`/api/project/getProject?projectId=${id}`)
      .then((answer) => {
        var item = answer.data;
        var entry = {
          rightsOffered: item.numberOfUnitsSold,
          rightsSold: item.numberOfRightsSold,
          dueDate: item.dueDate,
          releaseDate: item.releaseDate,

          id: item.id,
          artistId: item.artist.id,
          artist: item.artist.name,
          rights: item.numberOfUnitsSold,
          share: item.percentage,
          offeringPrice: item.pricePerUnit,
          imgSrc: item.artist.imageUrl,
          videoId: "",
          info: item.info,
          projectType: item.category,
        };
        setProjectData(answer);
        setSelectedInvest(entry);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
    // eslint-disable-next-line
  }, []);


  const handleBuyRights = async (event) => {
    setIsModalVisible(false);

    await RESTjava.post(
      `api/project/buyProjectRights?numberOfUnits=${rightsAmount}&projectId=${id}&userEmail=${lscache.get(
        "userEmail"
      )}`
    )
      .then((answer) => {
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
    getData();
    // }
  };

  const getEntryById = async () => {
    await RESTjava.get(`/api/project/getProject?projectId=${entryId}`)
      .then((answer) => {
        var data = answer.data;
        setProject(data);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log('Error message: ', message);
        }
      });
  };

  useEffect(() => {
    getEntryById();
    // eslint-disable-next-line
  }, []);

  const getAllDocuments = async () => {
    await RESTjava.get(`/api/project/getAllProjectDocuments?productId=${id}`)
      .then((answer) => {
        setDocumentsList(answer);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log('Error message: ', message);
        }
      });
  };
  useEffect(() => {
    getAllDocuments();
    // eslint-disable-next-line
  }, []);

  const handleDownloadClick = () => {
    return documentsList.data.map((item, index) => {
      const urle = `https://katastic-java.wtzconsult.com/jointoo/api/project/getProjectDocument?documentFilename=${item.filename}&productId=${id}`;

      return fetch(urle)
        .then((response) => response.blob())
        .then((blob) => {
          download(blob, `${item.filename}`, `${item.extension}`);
        });
    });
  };
  if (isPageLoading) {
    return (
      <div className={"investPage"}>
        <InvestHeader />
        {selectedCategory ? (
          <InvestTabs
            selectedTab={selectedTab}
            selectedCategory={selectedCategory}
          />
        ) : null}
        <InvestListHeader />
      </div>
    );
  } else {
    return (
      <div className={"investPage"} style={{ marginTop: "50px" }}>
        <InvestListHeader />
        <SelectedInvestPreview
          id={selectedInvest.id}
          artistName={selectedInvest.artist}
          projectType={selectedInvest.projectType}
          rights={selectedInvest.rights}
          share={selectedInvest.share}
          offeringPrice={selectedInvest.offeringPrice}
          imgSrc={selectedInvest.imgSrc}
          rightsSold={selectedInvest.rightsSold}
          rightsOffered={selectedInvest.rightsOffered}
          dueDate={selectedInvest.dueDate}
          releaseDate={selectedInvest.releaseDate}
        />
        <Grid container style={{ paddingTop: "20px" }}>
          <InvestInfo
            // proRatingData={artistProfile}
            // userRatingData={artistProfile}
            investInfo={selectedInvest.info}
            proRating={proRating}
            userRating={userRating}
          />
          <Grid item xs={1} />
          <Grid item xs={6}>
            <InvestEndorsedBy data={projectData} />
            {/* <SalesHistory data={salesData} /> */}
          </Grid>

          <div
            onClick={() => handleDownloadClick()}
            style={{
              backgroundColor: "#ff3366",
              width: "200px",
              height: "40px",
              borderRadius: "30px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                marginLeft: "0",
                lineHeight: "40px",
                color: "black",
              }}
            >
              Download docs
            </p>
          </div>
        </Grid>

        <CustomModal
          open={isModalVisible}
          setOpen={setIsModalVisible}
          buttonPressed={handleBuyRights}
          buttonText={"BUY AMOUNT"}
        >
          <BuyModalContent
            offeringPrice={selectedInvest.offeringPrice}
            rightsAmount={rightsAmount}
            setRightsAmout={setRightsAmout}
            maxRightsAmount={
              selectedInvest.rightsOffered - selectedInvest.rightsSold
            }
            maxRightsAmountMessage={maxRightsAmountMessage}
          />
        </CustomModal>
        <RightsBuyedModal
          open={isCongratsModalVisible}
          setOpen={setIsCongratsModalVisible}
          id={selectedInvest.id}
          artistName={selectedInvest.artist}
          projectType={selectedInvest.projectType}
          rights={userRightsAmount}
          share={userShareProcent}
          offeringPrice={selectedInvest.offeringPrice}
          rightsOffered={selectedInvest.rightsOffered}
        />
        <Grid container className="currentProjectContainer">
          <Grid item xs={12} className="contentContainer">
            <Grid item xs={12} className="elementsGrid">
              <Grid item xs={3} className="firstElement">
                <Grid item xs={12} className="elemntTitle">
                  {projectTitle["category"]}
                </Grid>
                <Grid item xs={12} className="elementValue">
                  {project && project.category}
                </Grid>
              </Grid>
              <Grid item xs={3} className="secondElement">
                <Grid item xs={12} className="elemntTitle">
                  {projectTitle["estimtedYield"]}
                </Grid>
                <Grid item xs={12} className="elementValue">
                  {project && project.estimtedYield}{" "}
                  {project && project.estimatedYieldDescription}
                </Grid>
              </Grid>
              <Grid item xs={3} className="thirdElement">
                <Grid item xs={12} className="elemntTitle">
                  {projectTitle["estimatedValue"]}
                </Grid>
                <Grid item xs={12} className="elementValue">
                  {project && project.estimatedValue}
                </Grid>
              </Grid>
              <Grid item xs={3} className="forthElement">
                <Grid item xs={12} className="elemntTitle">
                  {projectTitle["genre"]}
                </Grid>
                <Grid item xs={12} className="elementValue">
                  {project && project.genre}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="elementsGrid">
              <Grid item xs={3} className="firstElement">
                <Grid item xs={12} className="elemntTitle">
                  {projectTitle["tenor"]}
                </Grid>
                <Grid item xs={12} className="elementValue">
                  {project && project.tenor} {project && project.tenorSufix}
                </Grid>
              </Grid>
              <Grid item xs={3} className="secondElement">
                <Grid item xs={12} className="elemntTitle">
                  {projectTitle["installments"]}
                </Grid>
                <Grid item xs={12} className="elementValue">
                  {project && project.installments}
                </Grid>
              </Grid>
              <Grid item xs={3} className="thirdElement">
                <Grid item xs={12} className="elemntTitle">
                  {projectTitle["valueOffer"]}
                </Grid>
                <Grid item xs={12} className="elementValue">
                  {project && project.valueOffer}
                </Grid>
              </Grid>
              <Grid item xs={3} className="forthElement">
                <Grid item xs={12} className="elemntTitle">
                  {projectTitle["percentage"]}
                </Grid>
                <Grid item xs={12} className="elementValue">
                  {project && project.percentage}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="elementsGrid">
              <Grid item xs={3} className="forthElement">
                <Grid item xs={12} className="elemntTitle">
                  {project && project.location
                    ? projectTitle["location"]
                    : null}
                </Grid>
                {project && project.location ? (
                  <Grid item xs={12} className="elementValue">
                    {project && project.location} -{" "}
                    {project && project.start
                      ? moment(project && project.start).format("DD/MM/YYYY")
                      : ""}{" "}
                    -{" "}
                    {project && project.end
                      ? moment(project && project.end).format("DD/MM/YYYY")
                      : ""}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
};
export default PortfolioProjectsEntry;
