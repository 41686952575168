import React from 'react';
import SignUpForm from './Components/SignUpForm';
import { Footer } from '@Components';
import { LoadingUntilRedirect } from '@Components';

export const Register = props => {
  const { isAuthenticated } = props;

  let html;
  if (isAuthenticated) {
    html = <LoadingUntilRedirect />;
  } else {
    html = (
      <div>
        <SignUpForm />
        <Footer />
      </div>
    );
  }

  return html;
};

export default Register;
