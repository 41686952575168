import { ThemeProvider } from "@material-ui/styles";
// import { useRouter } from "@Hooks";
import { theme } from "@Styled/Theme";
import { createBrowserHistory } from "history";
import React, { useState } from "react";
import { Router } from "react-router-dom";
import UAParser from "ua-parser-js";
import validate from "validate.js";
import "./Assets/scss/index.scss";
import validators from "./Common/validators";
import Routes from "./Routes";
import UserProfileProvider from "./context/userProfileContext";

const browserHistory = createBrowserHistory();
const parser = new UAParser();

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  // eslint-disable-next-line
  const [isAuthed, setIsAuthed] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      <Router
        history={browserHistory}
      // triggerWalletUpdate={triggerWalletUpdate}
      >
        <UserProfileProvider>
          <Routes parser={parser} isAuthenticated={isAuthed} />
        </UserProfileProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
