import React from "react";
import { investButtonBuy, investButtonPlay } from "@Helpers/ImageRoutes/Assets";

const InvestButton = (props) => {
  const { handlePlayButton, handleBuyButton } = props;

  return (
    <div className={"investButton"}>
      <div className={"buttonContainer"}>
        <div className={"playButton"} onClick={handlePlayButton}>
          PLAY TRACK&nbsp;
          <img
            src={investButtonPlay}
            style={{ width: "38px", height: "38px" }}
            alt=""
          />
        </div>
        <div className={"delimiter"}>|</div>
        <div className={"buyButton"} onClick={handleBuyButton}>
          &nbsp; &nbsp;BUY&nbsp; &nbsp;
          <img
            src={investButtonBuy}
            style={{ width: "28px", height: "28px" }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default InvestButton;
