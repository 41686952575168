const secondStepSchema = {
  // estimatedValue: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 128,
  //   },
  // },
  // valueOffer: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 128,
  //   },
  // },
  // percentage: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 128,
  //   },
  // },
  // unitSold: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 128,
  //   },
  // },
  tenor: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  tenorDescription: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  // estimatedYield: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     maximum: 128,
  //   },
  // },
  estimatedYieldDescription: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  // pricePerUnit: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 128,
  //   },
  // },
};

export default secondStepSchema;
