const registerFormSchemaStep6 = {
  placeofbirth: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
  idnumber: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },

  dateofbirth: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 10,
    },
    format:{
        pattern: /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
        message: "require this format YYYY-MM-DD"

    }
  },
  sex: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 7,
    },
  },
};

export default registerFormSchemaStep6;
