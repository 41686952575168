import React from "react";
import Grid from "@material-ui/core/Grid";

const InvestPortofolioRoyaltiesListHeader = (props) => {
  return (
    <Grid container className={"investPortofolioRightsListHeader"}>
      <Grid item xs={3} style={{ textAlign: "start" }}>
        ARTIST
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        PROJECT NAME
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        PROJECT TYPE
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        REVENUE SHARE
      </Grid>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        REVENUE
      </Grid>
      <Grid item xs={3} style={{ textAlign: "end" }}>
        YEARLY YIELD
      </Grid>
    </Grid>
  );
};

export default InvestPortofolioRoyaltiesListHeader;
