import { goInvest } from "@Helpers/ImageRoutes/Assets";
import { LogoBtn, LogoRed } from "@Helpers/ImageRoutes/Logo";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import SignUpFooter from "@Views/Website/register/Components/SignUpFooter";
import React, { useState } from "react";
import DecisionButtonsStep from "./Components/DecisionButtonsStep";
import SetDocument from "./Components/SetDocument";
import SetDocument2 from "./Components/SetDocument2";
import SetLicenseDocument from "./Components/SetLicenseDocument";
import SetPublicAppearanceDocument from "./Components/SetPublicAppearanceDocument";


const RegisterProEntity = props => {
  const [step, setNextStep] = useState(0);
  const [licenseIsValid, setLicenseIsValid] = useState(null);
  const [publicAppearanceIsValid, setPublicAppearanceIsValid] = useState(null);
  const [docIsValid, setDocIsValid] = useState(null);
  const [doc2IsValid, setDoc2IsValid] = useState(null);

  const goBackOneStep = () => {
    setNextStep(0);
  };

  const goInvestRoute = () => {
    window.location.href = "/invest/all";
  };

  return (
    <div>
      <Grid container spacing={3} className={`signUpLogo pt-7-rem`}>
        <Grid item xl={6} lg={6} md={6} xs={12} className={`signUpForm`}>
          {" "}
          {/* isVisible={step < 3} */}
          <img src={LogoRed} alt="Katastic" />
          <h1>Sign Up</h1>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          xs={12}
          className={`signUpForm signupPlatinumIndividual`}
        >
          {step !== 999 && (
            <div>
              {step > 0 && (
                <div className={`backToPrevBtn`} onClick={goBackOneStep}>
                  {" "}
                  &lt; Prev Step
                </div>
              )}
              <h1>Go Platinum</h1>
              <h2>Legal Entity</h2>

              <DecisionButtonsStep
                setNextStep={setNextStep}
                isVisible={step === 0}
                licenseIsValid={licenseIsValid}
                publicAppearanceIsValid={publicAppearanceIsValid}
                docIsValid={docIsValid}
                doc2IsValid={doc2IsValid}
              />
              <SetLicenseDocument
                setLicenseIsValid={setLicenseIsValid}
                setNextStep={setNextStep}
                isVisible={step === 1}
              />
              <SetPublicAppearanceDocument
                setPublicAppearanceIsValid={setPublicAppearanceIsValid}
                setNextStep={setNextStep}
                isVisible={step === 2}
              />
              <SetDocument
                setDocIsValid={setDocIsValid}
                setNextStep={setNextStep}
                isVisible={step === 3}
              />
              <SetDocument2
                setDoc2IsValid={setDoc2IsValid}
                setNextStep={setNextStep}
                isVisible={step === 4}
              />

              {licenseIsValid &&
                publicAppearanceIsValid &&
                docIsValid &&
                doc2IsValid &&
                step === 0 && (
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() => setNextStep(999)}
                    >
                      <img src={LogoBtn} alt="" />
                    </IconButton>
                  </label>
                )}
            </div>
          )}

          {step === 999 && (
            <div>
              <h1>You're platinum now!</h1>
              <h4>Add a new invest project?</h4>
              <img
                src={goInvest}
                className={`goInvest`}
                onClick={goInvestRoute}
                width={80}
                alt=""
              />
            </div>
          )}
        </Grid>
      </Grid>

      <SignUpFooter />
    </div>
  );
};

export default RegisterProEntity;
