import { Button, ListItemText } from '@material-ui/core';
import React from 'react';


const LinkField = (props) => {

  const { textData } = props;

  const handleOnClick = () => {
    window.open('http://catalin.wtzconsult.com:3100/public/terms-and-conditions-template.pdf');
  }

  return (
    <div style={{ marginTop: '0%' }}>
      <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '69%', justifyContent: 'space-between' }}>
        <Button onClick={handleOnClick} className="EditData">READ</Button>
        <ListItemText className="LinkTitle">{textData}</ListItemText>
      </div>
    </div>
  )
}

export default LinkField;
