import { getHistoryPathArray } from "@Helpers";
import Grid from "@material-ui/core/Grid";
import { RESTjava } from "@Utils";
import React, { useEffect, useState } from "react";
import Services from "./Components/Services/Services";
import ServicesDetails from "./Components/Services/ServicesDetails";



export const MarketServices1 = props => {
  const { history } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState({});

  const entrySLug = getHistoryPathArray(history);
  const id = entrySLug.slice(-1).toString();

  const getServices = async () => {
    await RESTjava.get(`/api/market/service/getService?serviceID=${id}`)
      .then(answer => {
        var data = answer.data;
        setServices(data);
        setIsLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message: `, message)
        }
      });
  };

  useEffect(() => {
    if (id) {
      getServices();
    }
    // eslint-disable-next-line
  }, [entrySLug]);

  if (isLoading) {
    return <div></div>;
  } else {
    return (
      <Grid container className={"market-services1-root"}>
        {/* <Grid item xs={12} md={12} lg={12} xl={12}>
          <MarketFilters />
        </Grid> */}

        <Grid item xs={12} md={12} lg={12} xl={12}>
          {services && <Services services={services} />}
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          {services && <ServicesDetails services={services} />}
        </Grid>
      </Grid>
    );
  }
};

export default MarketServices1;
