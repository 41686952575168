import React from "react";
import { Grid } from "@material-ui/core";

const WithSideMenu = props => {
  const { children } = props;

  return (
    <Grid container className={"pageGrid"}>
      <Grid item className={"pageGrid__content"} md={12}>
        <div className={"content"}>{children}</div>
      </Grid>
    </Grid>
  );
};

export default WithSideMenu;
