import { uploadIcon } from "@Helpers/ImageRoutes/CreateProject";
import {
  Checkbox, FormControlLabel, Grid, TextField
} from "@material-ui/core";
import { RESTjava } from "@Utils";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import validate from "validate.js";
import checked from "../../../../Assets/Images/checked.png";
import thirdStepSchema from "../schemas/thirdStepSchema";


export const ThirdStep = (props) => {
  const {
    setCurrentStep,
    handleChange,
    formState,
    katasticType,
    setProjectData,
    setFormState,
  } = props;

  const [docUploaded, setDocUploaded] = useState(false);
  const [audioUploaded, setAudioUploaded] = useState(false);
  const [uploadedList, setUploadedList] = useState(null);
  // eslint-disable-next-line
  const [uploadedAudioList, setUploadedAudioList] = useState(null);
  // eslint-disable-next-line
  const [error, setError] = useState({ message: null });

  useEffect(() => {
    const errors = validate(formState.values, thirdStepSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line
  }, [formState.values]);

  const postInputsData = async () => {
    var body = {
      category: formState.values["categorySelect"],

      estimtedYield: formState.values["estimatedYield"],
      estimatedYieldDescription: formState.values["estimatedYieldDescription"],
      genre: formState.values["genreSelect"],

      info: formState.values["info"],
      installments: formState.values["installments"]
        ? formState.values["installments"]
        : "",

      location: formState.values["locationSelect"]
        ? formState.values["locationSelect"]
        : "",
      start: formState.values["fromDate"]
        ? moment(formState.values["fromDate"]).format("YYYY-MM-DD")
        : "",
      end: formState.values["toDate"]
        ? moment(formState.values["toDate"]).format("YYYY-MM-DD")
        : "",

      numberOfUnitsSold: formState.values["unitSold"],
      percentage: formState.values["percentage"],
      pricePerUnit: formState.values["pricePerUnit"],

      tenor: formState.values["tenor"],
      tenorSufix: formState.values["tenorDescription"],
      estimatedValue: formState.values["estimatedValue"],
      valueOffer: formState.values["valueOffer"],
      currency: "USD",
      draft: true,
      link: formState.values["trackLink"],
      valid: true,
      dueDate: formState.values["dueDate"]
        ? moment(formState.values["dueDate"]).format("YYYY-MM-DD")
        : "",
      projectType: katasticType,
      title: formState.values["projectTitle"],
      releaseDate: formState.values["releaseDate"]
        ? moment(formState.values["releaseDate"]).format("YYYY-MM-DD")
        : "",
    };

    await RESTjava.post(
      `/api/project/addProject?artistId=${formState.values["artistId"]}&endorsedById=${formState.values["endorsedById"]}`,
      body
    )
      .then((answer) => {
        setError({ message: null });

        RESTjava.post(
          `/api/project/uploadProjectDocument?id=${answer.data.id}`,
          uploadedList
        )
          .then((answer) => {
            setError({ message: null });

            setProjectData(answer);
            window.location.href = "/invest/all";
          })
          .catch((err) => {
            if (err && err.response && err.response.data) {
              const { message } = err.response.data;

              setError({
                message,
              });
            }
          });
      })

      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          setError({
            message,
          });
        }
      });
  };

  const handleSubmission = async () => {
    postInputsData();
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const onUpload = async (files) => {
    const formData = new FormData();

    // eslint-disable-next-line
    files.map((item) => {
      formData.append("files", item);
      setUploadedList(formData);
    });
    setDocUploaded(true);
  };

  const onUploadAudio = async (files) => {
    const formData = new FormData();

    // eslint-disable-next-line
    files.map((item) => {
      formData.append("files", item);
      setUploadedAudioList(formData);
    });
    setAudioUploaded(true);
  };
  return (
    <Grid container className="thirdPageContainer">
      <Grid item xs={12} className="titleContainer">
        <p className="titleText">Create Project</p>
      </Grid>
      <Grid item xs={12} className="stepTrackerContainer">
        <div className="firstStepTracker">1</div>
        <div className="firstStepTrackerLine" />
        <div className="secondStepTracker">2</div>
        <div className="secondStepTrackerLine" />
        <div className="thirdStepTracker">3</div>
      </Grid>

      <Grid item xs={12}>
        <p className="contentTitle">
          Complete the fields and upload regulatory filings:
        </p>
      </Grid>
      {katasticType === "ONE" ? (
        <div className="allContentContainer">
          <Grid item xs={12} className="contentContainer">
            <Grid item xs={12} md={6} className="uploadContainer">
              <p className="inputTitle">Engagement letter</p>
              <input
                type="file"
                onChange={handleChange}
                id="engagementFiles"
                name="engagementFiles"
                style={{ display: "none" }}
              />
              <div className="selectFieldContainer">
                <div className="selectField">
                  <label for="engagementFiles" className="selectFieldText">
                    <img src={uploadIcon} id="uploadIcon" alt="" />
                    Upload File
                  </label>
                </div>
                <label className="fileNameText">
                  {formState &&
                    formState.values["engagementFiles"] &&
                    formState.values["engagementFiles"].name}
                </label>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className="uploadContainer">
              <p className="inputTitle">Underwriting Agreement</p>
              <input
                type="file"
                onChange={handleChange}
                id="underwritingAgreement"
                name="underwritingAgreement"
                style={{ display: "none" }}
              />
              <div className="selectFieldContainer">
                <div className="selectField">
                  <label
                    for="underwritingAgreement"
                    className="selectFieldText"
                  >
                    <img src={uploadIcon} id="uploadIcon" alt="" />
                    Upload File
                  </label>
                </div>
                <label className="fileNameText">
                  {formState &&
                    formState.values["underwritingAgreement"] &&
                    formState.values["underwritingAgreement"].name}
                </label>
              </div>
            </Grid>
          </Grid>
          <Grid item container xs={12} className="contentContainer">
            <Grid item xs={12} md={6} className="uploadContainer">
              <p className="inputTitle">Registration statement</p>
              <input
                type="file"
                onChange={handleChange}
                id="registrationStatement"
                name="registrationStatement"
                style={{ display: "none" }}
              />
              <div className="selectFieldContainer">
                <div className="selectField">
                  <label
                    for="registrationStatement"
                    className="selectFieldText"
                  >
                    <img src={uploadIcon} id="uploadIcon" alt="" />
                    Upload due diligence docs
                  </label>
                </div>
                <label className="fileNameText">
                  {formState &&
                    formState.values["registrationStatement"] &&
                    formState.values["registrationStatement"].name}
                </label>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className="uploadContainer">
              <p className="inputTitle">Prospectus</p>
              <input
                type="file"
                onChange={handleChange}
                id="prospectus"
                name="prospectus"
                style={{ display: "none" }}
              />
              <div className="selectFieldContainer">
                <div className="selectField">
                  <label for="prospectus" className="selectFieldText">
                    <img src={uploadIcon} id="uploadIcon" alt="" />
                    Upload File
                  </label>
                </div>
                <label className="fileNameText">
                  {formState &&
                    formState.values["prospectus"] &&
                    formState.values["prospectus"].name}
                </label>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className="contentContainer">
            <FormControlLabel
              control={
                <Checkbox
                  name="otherPublicDocs"
                  id="otherPublicDocs"
                  onChange={handleChange}
                  checked={formState.values["otherPublicDocs"]}
                />
              }
              label="Other Public Company Legal Documents"
            />
          </Grid>
        </div>
      ) : katasticType === "OTC" ? (
        <div className="allContentContainer">
          <Grid item container xs={12} className="contentContainer">
            <Grid item xs={12} md={6} className="uploadContainer">
              <p className="inputTitle"> Upload due diligence docs</p>
              <div className="selectFieldContainer">
                <div className="selectField">
                  <Dropzone
                    onDrop={onUpload}
                    // accept={'.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf'}
                    accept={".png,.jpeg,.jpg,.JPEG,.JPG,.pdf"}
                  // disabled={isLoading}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p className="selectFieldText">
                            Click or Drag 'n' drop
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {docUploaded ? (
                    <img
                      src={checked}
                      onClick={() => setDocUploaded(false)}
                      style={{
                        cursor: "pointer",
                        height: "30px",
                        width: "30px",
                        marginLeft: "20px",
                        marginTop: "7px",
                      }}
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className="uploadContainer">
              <p className="inputTitle"> Upload audio track</p>
              <div className="selectFieldContainer">
                <div className="selectField">
                  <Dropzone
                    onDrop={onUploadAudio}
                    // accept={'.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf'}
                    accept={".mp3,.mp4"}
                  // disabled={isLoading}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p className="selectFieldText">
                            Click or Drag 'n' drop
                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {audioUploaded ? (
                    <img
                      src={checked}
                      onClick={() => setAudioUploaded(false)}
                      style={{
                        cursor: "pointer",
                        height: "30px",
                        width: "30px",
                        marginLeft: "20px",
                        marginTop: "7px",
                      }}
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} className="contentContainer">
            <Grid item xs={12} md={6} className="uploadContainer">
              <p className="inputTitle"> Send us a link with your tracks</p>
              <TextField
                className="infoInput"
                id="trackLink"
                name="trackLink"
                color="secondary"
                placeholder="http://www.youtube.com/exemple"
                onChange={handleChange}
                value={formState.values["trackLink"]}
                error={hasError("trackLink")}
                helperText={
                  hasError("trackLink") ? formState.errors.trackLink[0] : null
                }
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
      <Grid item xs={12} className="buttonContainer">
        <div
          onClick={() => setCurrentStep("secondStep")}
          className="buttonTextContainer"
        >
          <p className="buttonText">Back</p>
        </div>
        <div onClick={handleSubmission} className="buttonTextContainer">
          <p className="buttonText">Submit Project</p>
        </div>
      </Grid>
    </Grid>
  );
};

export default ThirdStep;
