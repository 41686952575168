import { ButtonPink, LoadingOnPage } from "@Components";
import { useRouter } from "@Hooks";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { GlobalMUIStyles } from "@Styled/AppStyle";
import { SignupMUIStyles } from "@Styled/Signup.style";
import { RESTjavaTest } from "@Utils";
import React, { useState } from "react";

const SelectAccType = (props) => {
    const Router = useRouter();
    const { isVisible, mainFormState, setMainFormState, setNextStep } = props;
    const GlobalStyle = GlobalMUIStyles({ color: "cadetblue" });
    const SignupStyle = SignupMUIStyles();
    // eslint-disable-next-line
    const [isPageLoading, setIsPageLoading] = useState(false);
    // eslint-disable-next-line
    const [fullName, setFullName] = useState(null);

    const handleChange = event => {
        event.persist();
        setMainFormState({
            ...mainFormState,
            entityDetails: {
                ...mainFormState.entityDetails,
                fullName: event.target.value
            }
        });
    };


    const createArtistAcc = async () => {

        let body = {
            fullName: mainFormState.entityDetails.fullName,
            userId: mainFormState.entityDetails.userId
        }
        await RESTjavaTest.post(`api/v1/artists/create`, body)
            .then(answer => {
                setMainFormState({
                    ...mainFormState,
                    entityDetails: {
                        artistId: answer.data.id,
                        fullName: mainFormState.entityDetails.fullName
                    },
                });
                setNextStep(5); // navigate to artist document upload
            })
            .catch(err => {
                if (err && err.response && err.response.data) {
                    const { message } = err.response.data;
                    console.log(`Error`, message)
                }
            });
    }

    const createInvestorAcc = async () => {
        let body = {
            fullName: mainFormState.entityDetails.fullName,
            userId: mainFormState.entityDetails.userId
        }
        await RESTjavaTest.post(`api/v1/investors/create`, body)
            .then(answer => {
                Router.push("/");
            })
            .catch(err => {
                if (err && err.response && err.response.data) {
                    const { message } = err.response.data;
                    console.log(`Error`, message)
                }
            });
    }

    const createManagerAcc = async () => {
        let body = {
            fullName: mainFormState.entityDetails.fullName,
            userId: mainFormState.entityDetails.userId
        }
        await RESTjavaTest.post(`api/v1/managers/create`, body)
            .then(answer => {
                setMainFormState({
                    ...mainFormState,
                    entityDetails: {
                        managerId: answer.data.id,
                        fullName: mainFormState.entityDetails.fullName
                    },
                });
                setNextStep(4); // nav to create manager client list
            })
            .catch(err => {
                if (err && err.response && err.response.data) {
                    const { message } = err.response.data;
                    console.log(`Error`, message)
                }
            });

    }


    if (isPageLoading) {
        return <LoadingOnPage isVisible={true}></LoadingOnPage>;
    } else {
        return (
            <Grid
                container
                spacing={3}
                alignItems="center"
                justify="center"
                direction="column"
                className={`${SignupStyle.root}, ${SignupStyle.signUpArtistsWrap}`}
                style={{ display: isVisible ? "block" : "none" }}
            >
                <Grid item xl={12} lg={12} md={12} xs={12}>
                    <h1>Select your account type</h1>
                </Grid>

                <Grid item xl={12} lg={12} md={12} xs={12}>
                    <TextField
                        id="fullName"
                        name="fullName"
                        label="Full Name"
                        variant={"outlined"}
                        className={GlobalStyle.textField}
                        autocomplete="false"

                        onChange={event => {
                            handleChange(event);
                            const { value } = event.target;
                            setFullName(value);
                        }}
                        // helperText={
                        //     hasError("username") ? formState.errors.username[0] : null
                        // }
                        value={mainFormState.entityDetails.fullName || ""}
                    />
                </Grid>

                <Grid item xl={12} lg={12} md={12} xs={12}
                    className={`${SignupStyle.selectAccType}`}
                >

                    <ButtonPink
                        onClick={createArtistAcc}
                        variant={"contained"}
                        color={"primary"}
                        // disabled={!selected/ArtistsHaystack.length}
                        disableElevation
                    >
                        Artist
                    </ButtonPink>

                    <ButtonPink
                        onClick={createManagerAcc}
                        variant={"contained"}
                        color={"primary"}
                        // disabled={!selected/ArtistsHaystack.length}
                        disableElevation
                    >
                        Manager
                    </ButtonPink>

                    <ButtonPink
                        onClick={createInvestorAcc}
                        variant={"contained"}
                        color={"primary"}
                        // disabled={!selectedArtistsHaystack.length}
                        disableElevation
                    >
                        Investor
                    </ButtonPink>


                </Grid>
            </Grid>
        );
    }
};

export default SelectAccType;
