import { albumPicture } from '@Helpers/ImageRoutes/ArtistDetails';
import { artistProfileImg } from '@Helpers/ImageRoutes/ArtistDetails';

export const artistInfoDetails = {
  artistName: 'BILLIE EILISH',
  trackName: 'Bury A Friend',
  tracklist: [
    'Bury A Friend',
    'When I Was Older',
    'Bellyache',
    'Copycat',
    'Ocean Eyes',
  ],
  followers: '26.1 mil',
  artistProfileImg: artistProfileImg,
  artistBigImg: albumPicture,
  postDate: '20.10.2020'
};
