import { useEffect } from 'react';
import { closeIntervalSelector } from '@Helpers';

const useCloseIntervalSelector = (className, callback, callbackValue) => {
    useEffect(() => {
        const handleClose = (e) => {
            closeIntervalSelector(e, className, callback, callbackValue);
        };

        document.addEventListener('click', handleClose, false);

        return () => window.removeEventListener('click', handleClose, false);
        // eslint-disable-next-line
    }, [])
};

export default useCloseIntervalSelector;