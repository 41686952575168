import axios from "axios";
import { rURL, jURL, testURL } from "./config";
import AuthHeader from "@Services/AuthHeader";
import AuthHeaderUpload from "@Services/AuthHeaderUpload";

export const REST = axios.create({
  baseURL: rURL,
  timeout: 10000,
  headers: { "X-engage-initiator": "frontend", ...AuthHeader },
});

export const RESTjava = axios.create({
  baseURL: jURL,
  timeout: 10000,
  headers: { "X-engage-initiator": "frontend", ...AuthHeader },
});

export const RESTjavaTest = axios.create({
  baseURL: testURL,
  timeout: 10000,
  headers: {
    "X-engage-initiator": "frontend",
    "Content-Type": "application/json"
  },
});

export const RESTjavaInApp = axios.create({
  baseURL: testURL,
  timeout: 10000,
  headers: { "X-engage-initiator": "frontend", ...AuthHeader },
});

export const RESTjavaUpload = axios.create({
  // baseURL: jURL,
  baseUrl: testURL,
  timeout: 10000,
  headers: { "X-engage-initiator": "frontend", ...AuthHeaderUpload },
});
