import { Button, FormControl, ListItemText, MenuItem, Select } from '@material-ui/core';
import { REST } from '@Utils';
import React, { useState } from 'react';

const NotificationField = (props) => {

  const { textData, data } = props;
  const [isSelected, setIsSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)

  const [newData, setNewData] = useState();

  const handleOnClick = () => {
    setIsSelected(!isSelected)
  }

  const handleChange = (event) => {
    setNewData(event.target.value)
  }

  const handleClose = (option) => {
    setAnchorEl(null)
    setIsSelected(false)
    updateData(option)
  }

  const updateData = async (option) => {
    var NA = {
      allowNotifications: 1
    }
    var NU = {
      allowNotifications: 0
    }

    if (option === "Allowed") {
      console.log("Allowed")
      await REST.put(`/user/updateNotificationsStatus/${data}`, NA)
        .then(answer => {
          props.refreshData()
          console.log("A intrat in then pe allowed")

        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            console.log("ERROR NOTIFICATIONS Allowed: ", message)
          }
        });
    }
    if (option === "Unallowed") {
      console.log("Unallowed")
      await REST.put(`/user/updateNotificationsStatus/${data}`, NU)
        .then(answer => {
          props.refreshData()
          console.log("A intrat in then pe unallowed")
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            console.log("ERROR NOTIFICATIONS Unallowed: ", message)
          }
        });
    }
  }

  return (
    <div style={{ marginTop: '0%' }}>
      <ListItemText className="Title">{textData}</ListItemText>

      <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '69%', justifyContent: 'space-between' }}>
        <Button onClick={handleOnClick} className="EditData">EDIT</Button>

        {isSelected
          ?
          (
            <FormControl className={"Dropdown"}>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newData}
                onChange={handleChange}
                anchorEl={anchorEl}
                autoFocus={true}
              >
                <MenuItem autoFocus={true} value={"Allowed"} onClick={() => handleClose("Allowed")}>Allowed</MenuItem>
                <MenuItem value={"Unallowed"} onClick={() => handleClose("Unallowed")}>Unallowed</MenuItem>
              </Select>
            </FormControl>
          )
          :
          (
            <ListItemText className="Data">{data}</ListItemText>
          )

        }
      </div>
    </div>
  )
}


export default NotificationField;
