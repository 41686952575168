import React from "react";
import { Line } from 'react-chartjs-2';

// Function to generate random number
function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

// eslint-disable-next-line
export default props => {
  const { dataset } = props;
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "September",
      "October",
      "November"
    ],
    options: {
      legend: "none"
    },

    datasets: [
      {
        label: "Values",
        labels: [""],
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(255,51,102,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [
          randomNumber(dataset - 5, dataset + 3),
          randomNumber(dataset - 10, dataset + 9),
          randomNumber(dataset - 5, dataset + 10),
          randomNumber(dataset - 10, dataset + 11),
          randomNumber(dataset - 6, dataset + 7),
          randomNumber(dataset - 3, dataset + 4),
          randomNumber(dataset - 2, dataset + 5),
          randomNumber(dataset - 10, dataset + 6),
          randomNumber(dataset - 11, dataset + 12),
          randomNumber(dataset - 1, dataset + 2),
          dataset
        ]
      }
    ]
  };

  return (
    <div>
      <h2>Line Example</h2>
      <Line data={data} />
    </div>
  );
};
