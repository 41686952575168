import { LogoBtn, LogoRed } from "@Helpers/ImageRoutes/Logo";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import loginFormSchema from "@Schemas/loginFormSchema";
import { CenterWrap, GlobalMUIStyles } from "@Styled/AppStyle";
import { LoginMUIStyles } from "@Styled/Login.style";
import { RESTjavaTest } from "@Utils";
import lscache from "lscache";
import React, { useEffect, useState } from "react";
import validate from "validate.js";

const SignInForm = () => {
  const GlobalStyle = GlobalMUIStyles({ color: "cadetblue" });
  const LoginStyle = LoginMUIStyles();

  const [error, setError] = useState({ message: null });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, loginFormSchema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSignIn = async event => {
    event.preventDefault();
    if (formState.isValid) {
      await RESTjavaTest.post(`auth/authenticate`, formState.values)
        .then(answer => {
          setError({ message: null });
          if (answer.data) {

            console.log(`answer.data >>>>>`, answer.data)
            const { token } = answer.data;
            lscache.set("accessToken", token);
            if (answer.data.userType === "ARTIST" || answer.data.userType === "MANAGER") {
              window.location.href = "/createProject";
            } else if (answer.data.userType === "INVESTOR") {
              window.location.href = "/invest/all";
            }
          }
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            const { message } = err.response.data;
            setError({ message });
          }
        });
    } else {
      setError({ message: null });
    }
  };

  return (
    <CenterWrap>
      <form onSubmit={handleSignIn}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justify="center"
          direction="column"
          className={LoginStyle.root}
        >
          <Grid item xs={12} className="mt-3-rem">
            <img src={LogoRed} alt="Jointoo" />
            <h1>Sign-in</h1>
            {error && error.message && (
              <div className={"errorField"}>{error.message}</div>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="username"
              name="username"
              label="Username"
              variant="outlined"
              className={GlobalStyle.textField}
              onChange={handleChange}
              error={hasError("username")}
              helperText={
                hasError("username") ? formState.errors.username[0] : null
              }
              value={formState.values.username || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              className={GlobalStyle.textField}
              onChange={handleChange}
              error={hasError("password")}
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              value={formState.values.password || ""}
            />
          </Grid>
          <Grid item xs={12}>
            {formState.isValid && (
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  component="span"
                  onClick={handleSignIn}
                  disabled={!formState.isValid}
                  type="submit"
                >
                  <img src={LogoBtn} alt="" />
                </IconButton>
              </label>
            )}
          </Grid>

          <Grid item xs={12}>
            <p><a href={'/register'}>or sign-up here</a></p>
          </Grid>
        </Grid>
      </form>
    </CenterWrap>
  );
};

export default SignInForm;
