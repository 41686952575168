import { loginPlayDisabled, LogoBtn } from '@Helpers/ImageRoutes/Logo';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';

const Step9Signup = props => {
  const { setNextStep, isVisible, secondFormState, setSecondFormState } = props;

  const [photoIsUploaded, setPhotoIsUploaded] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [codedString, setCodedString] = useState(null);
  const [showIsNotImg, setShowIsNotImg] = useState(false);

  const handleNext = () => {
    getTheFile();
    if (photoIsUploaded) {
      setSecondFormState({
        ...secondFormState,
        step9: {
          profilePhoto: profilePhoto,
        },
      });
      console.log('[Step9]', secondFormState);
      setNextStep(10);
    }
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      if (file.type.match('image.*')) {
        reader.onload = () => {
          let coded = reader.result;
          setCodedString(coded);
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
          if (encoded.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          setShowIsNotImg(false);
          setPhotoIsUploaded(true);
        };
      } else {
        setShowIsNotImg(true);
        setPhotoIsUploaded(false);
      }
      reader.onerror = error => reject(error);
    });
  };

  const getTheFile = async () => {
    const file = document.querySelector('#upload-photo-profile').files[0];
    setProfilePhoto(await getBase64(file));
  };
  const fileInputClicked = event => {
    let file = event.target.files[0];
    if (file) {
      getTheFile();
      console.log('File is Selected');
    } else {
      console.log('File is gol');
    }
  };
  return (
    <Grid
      container
      spacing={3}
      alignItems={`center`}
      justify={`center`}
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <Grid item xl={12} lg={12} md={12} xs={12}>
        <h1>Investor Sign-up</h1>
      </Grid>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        xs={12}
        className={`photoVerification`}
      >
        <h4>Profile photo</h4>
        <label
          for="upload-photo-profile"
          name="photoUpload"
          style={{ cursor: 'pointer' }}
        >
          <div className={'PhotoContainerWithBorder'}>
            {showIsNotImg ? (
              <div>
                <AddIcon />
              </div>
            ) : (
              <img src={codedString} className="previewImgProfile" alt="" />
            )}
          </div>
        </label>
        <input
          id="upload-photo-profile"
          type="file"
          style={{ display: 'none' }}
          onChange={fileInputClicked}
        />{' '}
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12}>
        {showIsNotImg ? (
          <div>
            <p>Its not an image</p>
          </div>
        ) : (
          <div></div>
        )}

        {showIsNotImg ? (
          <img src={loginPlayDisabled} alt="" />
        ) : (
          <label htmlFor={'icon-button-file'}>
            <IconButton
              color={'secondary'}
              component={'span'}
              onClick={() => handleNext()}
            >
              <img src={LogoBtn} alt="" />
            </IconButton>
          </label>
        )}
      </Grid>
    </Grid>
  );
};

export default Step9Signup;
