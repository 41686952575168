import {
  Grid
} from '@material-ui/core';
import { RESTjava } from '@Utils';
import lscache from 'lscache';
import React, { useEffect, useState } from 'react';
import BankLine from './Components/BankLine';
import Header from './Components/Header';
import LogOutButton from './Components/LogOutButton';
import RightsLine from './Components/RightsLine';
import RoyaltiesLine from './Components/RoyaltiesLine';


///////////Wallet page
const AccountWallet = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [rightsData, setRightsData] = useState([]);
  const [royaltiesData, setRoyaltiesData] = useState([]);

  const [showMoreRights, setShowMoreRights] = useState(false);
  const [showMoreRoyalties, setShowMoreRoyalties] = useState(false);

  const [rightsSize, setRightsSize] = useState(3);
  const [royaltiesSize, setRoyaltiesSize] = useState(3);

  const [hasRights, setHasRights] = useState(false);
  const [hasRoyalties, setHasRoyalties] = useState(false);

  const getData = async event => {
    console.log('DATA');
    await RESTjava.get(
      `/api/invest/getUserRights?userEmail=${lscache.get('userEmail')}`
    )
      .then(answer => {
        console.log('answer', answer);
        var data = answer.data;
        var rights = [];
        var royalties = [];
        // eslint-disable-next-line
        data.map(item => {
          if (item.rights === 0) {
          } else {
            let rightsEntry = {
              id: item.id,
              title: item.invest.artist.name,
              right: item.rights,
              value: item.value,
              valueCurrency: 'USD',
            };
            rights.push(rightsEntry);
            if (rights === null) {
              setHasRights(false);
            } else if (rights.length >= 3) {
              setHasRights(true);
            }

            var royaltiesEntry = {
              id: item.id,
              title: item.invest.artist.name,
              share: item.invest.share,
              value: item.invest.offeringPrice,
              valueCurrency: 'USD',
            };

            royalties.push(royaltiesEntry);
            if (royalties === null) {
              setHasRoyalties(false);
            } else if (royalties.length >= 3) {
              setHasRoyalties(true);
            }
          }
        });
        setRightsData(rights);
        setRoyaltiesData(royalties);
        setIsPageLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error: `, message)
        }
        setHasRights(false);
        setHasRoyalties(false);
        setIsPageLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showMoreRights) {
      setRightsSize(rightsSize + 10);
    }
    setShowMoreRights(false);
    // eslint-disable-next-line
  }, [showMoreRights]);

  useEffect(() => {
    if (showMoreRoyalties) {
      setRoyaltiesSize(royaltiesSize + 10);
    }
    setShowMoreRoyalties(false);
    // eslint-disable-next-line
  }, [showMoreRoyalties]);

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <section id="account">
        <Grid container className={'Container'} spacing={0}>
          <Header />

          <BankLine />

          <RightsLine
            setShowMoreRights={setShowMoreRights}
            hasRights={hasRights}
          />

          {rightsData.slice(0, rightsSize).map(item => (
            <Grid container className={'AccountRightsEntry'}>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <div className={'rightsEntryTitle'}>{item.title}</div>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className={'rightsEntryTitle'}>{item.right}</div>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <div className={'rightsEntryTitle'}>
                  {item.value} {item.valueCurrency}/unit
                </div>
              </Grid>
            </Grid>
          ))}

          <RoyaltiesLine
            setShowMoreRoyalties={setShowMoreRoyalties}
            hasRoyalties={hasRoyalties}
          />
          {royaltiesData.slice(0, royaltiesSize).map(item => (
            <Grid container className={'AccountRightsEntry'}>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                <div className={'rightsEntryTitle'}>{item.title}</div>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className={'rightsEntryTitle'}>{item.share} %</div>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <div className={'rightsEntryTitle'}>
                  {item.value} {item.valueCurrency}
                </div>
              </Grid>
            </Grid>
          ))}
          {/* <StatementLine /> */}
          <LogOutButton />
        </Grid>
      </section>
    );
  }
};

export default AccountWallet;
