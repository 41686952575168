import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "@Styled/Theme";

export const LoginMUIStyles = makeStyles(theme => ({
  root: {
    textAlign: "center",
    "& h1": {
      textTransform: "uppercase",
      margin: `2rem 0 .5rem`,
    },
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      width: "100vw",
      justifyContent: "center",
    }

  },
  paper: {}
}));

export const Container = styled.div`
  background-color: ${theme.colors.black};
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
`;

export const Girdle = styled.div`
  position: relative;
  top: 50%;
  background-color: ${theme.colors.primary};
  display: block;
  height: 2px;
  width: 100%;
  
`;

export const SocialImg = styled.img`
  display: block;
  margin: 0 auto;
  width: 70%;

  // @media (${theme.mediaQuery.phoneAndTablet}) {
  //   width: 70%;
  // }
  //
  // @media (${theme.mediaQuery.desktopsGeneral}) {
  //   width: 55%;
  // }
`;

export const TermsPrivacyUL = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSizes.body};
  max-height: 15px;
  width: 100%;
  margin: 0 0 -30px;
  padding: 20px 0;
  bottom: 0;

  &&& li {
    list-style: none;
    padding: 0 10px;
    position: relative;

    &:first-child:before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 35%;
      height: 45%;
      border-right: 1px solid white;
    }

    a {
      color: ${theme.colors.white};
      text-decoration: none;

      &:hover {
        padding: 0 0 0.5rem;
        border-bottom: 1px solid;
      }
    }
  }
`;
