import lscache from "lscache";

const accessToken = lscache.get("accessToken");

const AuthHeader = accessToken && accessToken
  ? {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json"
  }
  : {};

export default AuthHeader;
