import {
  categoryIcon,
  filtersIcon
} from '@Helpers/ImageRoutes/ArtistDetails';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';


const MarketFilters = (props) => {

  const { handleApparel, handleAccessories, handleGifts, handleFashion, handleAuto } = props

  const [categoryOpen, setCategoryOpen] = useState(false);
  // eslint-disable-next-line
  const [filterOpen, setFilterOpen] = useState(false);

  const handleCategory = () => {
    setCategoryOpen(!categoryOpen);
  };


  const ExpandCategory = () => {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', marginRight: '118px' }}
      >
        <div className={`expanded-category`} onClick={() => handleCategory()}>
          <img src={categoryIcon} className={'category-icon'} alt="" />
          <div className={'category-text'}>
            <h6 className={'btn-label'}>CATEGORY</h6>
            <ul>
              <li className={'category-list'} onClick={handleApparel}>APPAREL</li>
              <li className={'category-list'} onClick={handleAccessories}>ACCESSORIES</li>
              <li className={'category-list'} onClick={handleGifts}>GIFTS</li>
              <li className={'category-list'} onClick={handleFashion}>FASHION</li>
              <li className={'category-list'} onClick={handleAuto}>AUTO</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const CategoryBtn = () => {
    return (
      <div className={'category-btn'} onClick={() => handleCategory()}>
        <img src={categoryIcon} className={'category-icon'} alt="" />
        <div className={'category-text'}>
          <h6 className={'btn-label'}>CATEGORY</h6>
        </div>
      </div>
    );
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={6} className={'filters-container'}>
        {categoryOpen ? <ExpandCategory /> : <CategoryBtn />}

        {filterOpen ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: '96.6px',
            }}
          >
            <div className={'expanded-filters'}
            // onClick={() => handleFilter()}
            >
              <img src={filtersIcon} className={'category-icon'} alt="" />
              <div className={'category-text'}>
                <h6 className={'btn-label'}>FILTERS</h6>
                <ul>
                  <li className={'category-list'}>RATING</li>
                  <li className={'category-list'}>PRICE</li>
                  <li className={'category-list'}>NEW</li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className={'filters-btn'}
          // onClick={() => handleFilter()}
          >
            <img src={filtersIcon} className={'category-icon'} alt="" />
            <div className={'category-text'}>
              <h6 className={'btn-label'}>FILTERS</h6>
            </div>
          </div>
        )}

      </Grid>
    </Grid>
  );
};

export default MarketFilters;
