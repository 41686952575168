import Grid from "@material-ui/core/Grid";
import commaNumber from "comma-number";
import Moment from "moment";
import React, { useState } from 'react';

const InvestTable = (props) => {

    const {
        artist,
        projectType,
        share,
        offeringPrice,
        imgSrc,
        item,
    } = props

    const [isExpanded, setIsExpanded] = useState(false);

    const handleDetails = () => {
        setIsExpanded(!isExpanded)
    }


    return (
        <Grid container className={`invest_table_container`}>

            <Grid item xs={12} className={`invest_item`} onClick={handleDetails}>
                <Grid item xs={6} style={{ display: "flex", flexDirection: "row", paddingLeft: "1rem" }}>
                    <img src={imgSrc} style={{ width: "47px", height: "47px" }} alt="" />
                    <div className={"artist"}>&nbsp;{artist}</div>
                </Grid>

                <Grid item xs={6}>
                    {item.title}
                </Grid>
            </Grid>
            {isExpanded ?
                <Grid container>
                    <Grid item xs={12} className={`invest_item`} style={{ justifyContent: "space-between" }}>
                        <Grid item x={6}>
                            <p>PROJECT NAME</p>
                        </Grid>
                        <Grid item xs={6}>
                            {item.title}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>PROJECT TYPE</p>
                        </Grid>
                        <Grid item xs={6}>
                            {projectType}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>RIGHTS OFFERED</p>
                        </Grid>
                        <Grid item xs={6}>
                            {commaNumber(item.rightsOffered)}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>RIGHTS SOLD</p>
                        </Grid>
                        <Grid item xs={6}>
                            {commaNumber(item.rightsSold)}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>SHARE</p>
                        </Grid>
                        <Grid item xs={6}>
                            {share} %
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>OFFERING PRICE</p>
                        </Grid>
                        <Grid item xs={6}>
                            $ {commaNumber(offeringPrice)} / Right
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>IRO START DATE</p>
                        </Grid>
                        <Grid item xs={6}>
                            {Moment(item.releaseDate).format("DD-MM-YYYY")}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={`invest_item`}>
                        <Grid item xs={6}>
                            <p>IRO END DATE</p>
                        </Grid>
                        <Grid item xs={6}>
                            {Moment(item.dueDate).format("DD-MM-YYYY")}
                        </Grid>
                    </Grid>
                </Grid>
                :
                null
            }
        </Grid>
    )
}

export default InvestTable;