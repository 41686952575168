import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  colors: {
    black: '#000',
    white: '#fff',
    primary: '#f22d55',
    bloodPink: '#FF3366',
    sour: '#6d0f23',
  },
  mediaQuery: {
    phoneAndTablet: 'max-width: 959px',
    desktopsGeneral: 'min-width: 960px',
  },
  fonts: {
    rooney: 'Rooney Sans',
  },
  fontSizes: {
    body: '0.8em', // 12px
    medium: '1em', // 16px
    large: '1.25em', // 20px
    title: '1.563em', // 25px
  },
  fontWeights: {
    normal: '400',
    bold: '700',
  },
  letterSpacings: {
    normal: '0',
    scattered: '1.6px',
  },
});

export { theme };
