import { ButtonPink } from "@Components/";
import { LogoRed } from "@Helpers/ImageRoutes/Logo";
import { useRouter } from "@Hooks";
import Grid from "@material-ui/core/Grid";
import SignUpFooter from "@Views/Website/register/Components/SignUpFooter";
import React from "react";


const RegisterProLanding = props => {
  const Router = useRouter();
  const redirectToPlatinumIndividual = () => {
    Router.push("/go-platinum/individual");
  };

  const redirectToPlatinumEntity = () => {
    Router.push("/go-platinum/entity");
  };

  return (
    <div>
      <Grid container spacing={3} className={`signUpLogo pt-7-rem`}>
        <Grid item xl={6} lg={6} md={6} xs={12} className={`signUpForm`}>
          {" "}
          {/* isVisible={step < 3} */}
          <img src={LogoRed} alt="Katastic" />
          <h1>Sign Up</h1>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          xs={12}
          className={`signUpForm signupPlatinumLanding`}
        >
          <h1>Go Platinum</h1>
          <h2>Account Type</h2>
          <ButtonPink
            onClick={redirectToPlatinumIndividual}
            variant={"contained"}
            color={"primary"}
            fullWidth={true}
            disableElevation
          >
            Individual
          </ButtonPink>

          <ButtonPink
            onClick={redirectToPlatinumEntity}
            variant={"contained"}
            color={"primary"}
            fullWidth={true}
            disableElevation
          >
            Legal Entity
          </ButtonPink>
        </Grid>
      </Grid>

      <SignUpFooter />
    </div>
  );
};

// RegisterProLanding.getInitialProps = async ctx => {
//   return { namespacesRequired: ['common'] };
// };

export default RegisterProLanding;
