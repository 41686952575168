import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import MediaCarouselListItem from "@Components/MediaCarouselListItem";
import { useRouter } from "@Hooks";
import { Grid } from "@material-ui/core";
import React from "react";


const MediaTopEditorsList = props => {
  const Router = useRouter();
  const { topEditorList } = props;

  const goToMedia = mediaID => {
    console.log("GOTO MEDIA DETAILS FOR INVEST ID: ", mediaID);
    Router.push({
      pathname: `/mediaItem/${mediaID}`
    });
  };

  return (
    <Grid container className="MediaTopEditorList">
      <Grid item md={12} lg={12} className="carouselContainer">
        <p className="carouselTitle">Top Editors</p>
        <Carousel
          className={"carouselName"}
          plugins={["arrows"]}
          slidesPerPage={8}
        >
          {topEditorList.map(item => (
            <MediaCarouselListItem
              item={item}
              key={item.id}
              navigateOn={goToMedia}
            />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default MediaTopEditorsList;
