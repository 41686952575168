import React from 'react';

const TotalDisplay = props => {
  const { amount, currency } = props;

  return (
    <div className={'totalAmountInput'}>
      <div className={'totalAmount'}>{amount}</div>
      <div className={'totalAmount-currency-label'}>
        <label>{currency}</label>
      </div>
    </div>
  );
};

export default TotalDisplay;
