import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import InvestMyProjectEntry from "@Components/InvestMyProjectEntry";
import InvestPortofolioRightsListHeader from "@Components/InvestPortofolioRightsListHeader";
import PortofolioTabs from "@Components/PortofolioTabs";
import { RESTjava } from "@Utils";
import lscache from "lscache";
import Chart from "./Components/Chart";

const MyProjects = (props) => {
  const [selectedTab, setSelectedTab] = useState("PROJECTS");
  const [activeList, setActiveList] = useState([]);
  const [draftList, setDraftList] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedEntryPrice, setSelectedEntryPrice] = useState(0);
  const [tableInterval, setTableInterval] = useState("1h");

  const handleTableInterval = (e) => {
    setTableInterval(e)
  }

  useEffect(() => {
    getActiveProjects();
    getDraftsProjects();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getActiveProjects();
  }, [tableInterval])

  const getActiveProjects = async (event) => {
    await RESTjava.get(
      `api/project/getMyActiveProjects?page=${0}&size=${50}&artistEmail=${lscache.get(
        "userEmail"
      )}`
    )
      .then((answer) => {
        var data = answer.data.content;

        setActiveList(data);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message:`, message)
        }
      });
  };
  const getDraftsProjects = async (event) => {
    await RESTjava.get(
      `api/project/getMyDraftProjects?page=${0}&size=${50}&artistEmail=${lscache.get(
        "userEmail"
      )}`
    )
      .then((answer) => {
        var data = answer.data.content;

        setDraftList(data);
        setIsPageLoading(false);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log(`Error message:`, message)
        }
      });
  };

  const getChartData = async (id, offeringPrice) => {
    console.log("SHOW CHART FOR INVEST: ", id, " with price: ", offeringPrice);
    setSelectedEntryPrice(offeringPrice);
  };

  console.log("LOADING: ", isPageLoading);
  console.log("WTF >>: ", activeList);

  if (isPageLoading) {
    return (
      <Grid container className={"portofolio"}>
        <Grid container style={{ height: "500px" }}>
          <Grid item xs={6}>
            <h1>PORTFOLIO</h1>
            <h5>ACCOUNT VALUE</h5>
            <PortofolioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid container>
          <InvestPortofolioRightsListHeader />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={"portofolio"}>
        <Grid container>
          <Grid item xs={6}>
            <h1>PORTFOLIO</h1>
            <h5>ACCOUNT VALUE</h5>
            <PortofolioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Grid>
          <Grid item xs={6}>
            <div className={"lineChart"}>
              {console.log("CHART DATA WITH: ", selectedEntryPrice)}
              <Chart dataset={selectedEntryPrice} />
            </div>
          </Grid>
        </Grid>
        <p>ACTIVE</p>
        <Grid container>
          <InvestPortofolioRightsListHeader forActive={true} handleTableInterval={handleTableInterval} tableInterval={tableInterval} />
          <div className={"rightsList"}>
            {activeList && activeList.map((item) => (
              <InvestMyProjectEntry
                item={item}
                id={item.id}
                key={item.id}
                artist={item.artist}
                projectType={item.projectType}
                rights={item.rights}
                value={item.value}
                valueCurrency={"USD"}
                growth={"0"}
                imgSrc={item.imgSrc}
                unitsSold={item.unitsSold}
                setSelectedEntry={setSelectedEntry}
                selectedEntry={selectedEntry}
                showChart={getChartData}
                forActive={true}
                projectTitle={item.title}
                offeringPrice={item.offeringPrice}
              />
            ))}
          </div>
        </Grid>
        <p style={{ marginTop: "50px" }}>Drafts</p>
        <Grid container>
          <InvestPortofolioRightsListHeader handleTableInterval={handleTableInterval} tableInterval={tableInterval} />
          <div className={"rightsList"}>
            {draftList.map((item) => (
              <InvestMyProjectEntry
                item={item}
                id={item.id}
                key={item.id}
                artist={item.artist}
                projectTitle={item.title}
                projectType={item.projectType}
                rights={item.rights}
                value={item.value}
                valueCurrency={"USD"}
                growth={"0"}
                imgSrc={item.imgSrc}
                setSelectedEntry={setSelectedEntry}
                selectedEntry={selectedEntry}
                showChart={getChartData}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default MyProjects;
