import React, { createContext, useReducer } from "react";
import { userProfileReducer } from './reducers/userProfileReducer';

export const UserProfileContext = createContext();

const UserProfileProvider = (props) => {

    const [userData, dispatch] = useReducer(userProfileReducer, {})

    return (
        <UserProfileContext.Provider
            value={{
                userData,
                dispatch
            }}
        >
            {props.children}
        </UserProfileContext.Provider>
    )
}

export default UserProfileProvider;