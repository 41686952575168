const min2time = (list, setDurationFunction) => {
  let MINUTES = list; //some integer
  let m = MINUTES % 60;
  let h = (MINUTES - m) / 60;
  let HHMM =
    h === 0
      ? "00:" + (m < 10 ? "0" : "") + m.toString()
      : h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
  // setDurationToShow(HHMM);
  setDurationFunction(HHMM);
};

export default min2time;
