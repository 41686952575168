import React from 'react';

import { katasticLogo } from '@Helpers/ImageRoutes/StreamerDetails/';

const Services = () => {
  return (
    <div className={'services-root'}>
      <div className={'logo-container'}>
        <img src={katasticLogo} alt="logo" className={'logo'} />
      </div>
      <div className={'info-container'}>
        <h2>KATASTIC - RIGHTS COLLECTION</h2>
        <div>
          <label className={'info'}>Info</label>
          <label className={'content'}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </label>
        </div>
      </div>
    </div>
  );
};

export default Services;
