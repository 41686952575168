import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardContent, Table } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';


export default function StocksTable(props) {
    const { data } = props;
    return (
        <>
            <Card className="card-box mb-spacing-6-x2 project-table">
                <div className="card-header">
                    <div className="card-header--title">
                        <b>Stocks list</b>
                        {/* <b>This table card has custom content</b> */}
                    </div>
                    <div className="card-header--actions">
                        <Button size="small" className="btn-primary d-40 btn-icon p-0">
                            <FontAwesomeIcon
                                icon={['far', 'lightbulb']}
                                className="font-size-lg"
                            />
                        </Button>
                    </div>
                </div>
                <CardContent className="p-0">
                    <div className="table-responsive-md">
                        <Table className="table table-hover table-striped text-nowrap mb-0 table-content">
                            <thead className="thead-light">
                                <tr className="table-head">
                                    <th style={{ width: '15%', }} className="head-item-name">Artist Name <InfoIcon /></th>
                                    <Tooltip title="Project title">
                                        <th className="text-center head-item-name">Title</th>
                                    </Tooltip>
                                    <Tooltip title="Project code">
                                        <th className="text-center head-item-name">Code  <InfoIcon /></th>
                                    </Tooltip>
                                    <Tooltip title="Project title">
                                        <th className="text-center head-item-name">Total Units <InfoIcon /></th>
                                    </Tooltip>
                                    <Tooltip title="Number of units for sale">
                                        <th className="text-center head-item-name">Available Units <InfoIcon /></th>
                                    </Tooltip>
                                    <Tooltip title="Project title">
                                        <th className="text-center head-item-name">Sold Units <InfoIcon /></th>
                                    </Tooltip>
                                    <Tooltip title="Project title">
                                        <th className="text-center head-item-name">Estimated Value<InfoIcon /></th>
                                    </Tooltip>
                                    <Tooltip title="Project title">
                                        <th className="text-center head-item-name">Price/Unit <InfoIcon /></th>
                                    </Tooltip>
                                    <Tooltip title="Percentage of estimated value to be floated/subject of IRO">
                                        <th className="text-center head-item-name">Share <InfoIcon /></th>
                                    </Tooltip>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item) => {
                                    return (
                                        <tr className="table-body">
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-icon-wrapper mr-3">
                                                        <div className="avatar-icon">
                                                            {/* <img alt="..." src={avatar2} /> */}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <a
                                                            href="#/"
                                                            onClick={(e) => e.preventDefault()}
                                                            className="font-weight-bold  item-name"
                                                            title="...">
                                                            {item.artistName}
                                                        </a>
                                                        {/* <span className="text-black-50 d-block">
                                                            UI Engineer, Apple Inc.
                                                        </span> */}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-warning h-auto py-0 px-3 item-name">
                                                    {item.projectTitle}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-warning h-auto py-0 px-3 item-name">
                                                    {item.code}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-warning h-auto py-0 px-3 item-name">
                                                    {item.numberOfUnits}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-warning h-auto py-0 px-3 item-name">
                                                    {item.numberOfUnitsAvailable}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-warning h-auto py-0 px-3 item-name">
                                                    {item.numberOfUnitsSold}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-warning h-auto py-0 px-3 item-name">
                                                    {item.estimatedValue}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-warning h-auto py-0 px-3 item-name">
                                                    {item.price}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div className="badge badge-warning h-auto py-0 px-3 item-name">
                                                    {item.percentage}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <div>
                                                    <Button
                                                        size="small"
                                                        className="btn-primary btn-icon d-40 p-0 btn-animated-icon-sm">
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className="divider" />
                    <div className="divider" />
                </CardContent>
            </Card>
        </>
    );
}
