import React from "react";
import Button from "@material-ui/core/Button";
import { GlobalMUIStyles } from "@Styled/AppStyle";

export const ButtonPink = props => {
  const GlobalStyle = GlobalMUIStyles({ notCentered: props.notCentered });

  return (
    <Button className={GlobalStyle.pinkButton} {...props}>
      {props.children}
    </Button>
  );
};
