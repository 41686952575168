import { FormControl, ListItemText, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';

const AccountTypeField = (props) => {

  const { textData, data, setData } = props;
  const [isSelected, setIsSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)

  const handleChange = (event) => {
    const { value } = event.target
    setData(value);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setIsSelected(false)
  }

  return (
    <div style={{ marginTop: '0%' }}>
      <ListItemText className="Title">{textData}</ListItemText>

      <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '69%', justifyContent: 'space-between' }}>
        {/* <Button onClick={handleOnClick}  className="EditData">EDIT</Button> */}

        {isSelected
          ?
          (
            <FormControl className={"Dropdown"}>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data}
                onChange={handleChange}
                anchorEl={anchorEl}
                autoFocus={true}
              >
                <MenuItem autoFocus={true} value={"Basic"} onClick={handleClose}>Basic</MenuItem>
                <MenuItem value={"Premium"} onClick={handleClose}>Premium</MenuItem>
              </Select>
            </FormControl>
          )
          :
          (
            <ListItemText className="Data">{data}</ListItemText>
          )

        }
      </div>

    </div>
  )
}


export default AccountTypeField;
