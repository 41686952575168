import Grid from "@material-ui/core/Grid";
import commaNumber from "comma-number";
import Moment from "moment";
import React from "react";

const InvestPreview = (props) => {
  const {
    id,
    artist,
    projectType,
    share,
    offeringPrice,
    imgSrc,
    goToInvestDetails,
    item,
  } = props;

  return (
    <Grid
      container
      spacing={0}
      className={"invest"}
      onClick={() => goToInvestDetails(id)}
    >
      <Grid item xs={5} md={1} className={"artistDetails"}>
        <img src={imgSrc} style={{ width: "47px", height: "47px" }} alt="" />
        <div className={"artist"}>&nbsp;{artist}</div>
      </Grid>
      <Grid item xs={5} md={1} className={"projectType"}>
        {item.title}
      </Grid>
      <Grid item xs={5} md={1} className={"projectType"}>
        {projectType}
      </Grid>
      <Grid item xs={5} md={1} className={"rights"}>
        {commaNumber(item.rightsOffered)}
      </Grid>
      <Grid item xs={5} md={1} className={"rights"}>
        {commaNumber(item.rightsSold)}
      </Grid>
      <Grid item xs={5} md={1} className={"share"}>
        {share} %
      </Grid>
      <Grid item xs={5} md={1} className={"offeringPrice"}>
        $ {commaNumber(offeringPrice)} / Right
      </Grid>
      <Grid item xs={5} md={1} className={"offeringPrice"}>
        {Moment(item.releaseDate).format("DD-MM-YYYY")}
      </Grid>
      <Grid item xs={5} md={1} className={"offeringPrice"}>
        {Moment(item.dueDate).format("DD-MM-YYYY")}
      </Grid>
    </Grid>
  );
};
export default InvestPreview;
