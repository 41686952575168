import React from 'react';
import MediaForYou from '../mediaForYou';

const Media = () => {
  return (
    <MediaForYou />
  );
};

export default Media;
