import { ValidationImage } from "@Helpers/ImageRoutes/Assets";
import { Input, InputAdornment } from "@material-ui/core";
import { GlobalMUIStyles } from "@Styled/AppStyle";
import React, { useEffect, useState } from "react";

const BankAccountForm = props => {
  const GlobalStyle = GlobalMUIStyles({ color: "cadetblue" });
  const {
    setCardDetails,
    fullName,
    cardNumber,
    expireDate,
    cvc,
    setCardNumber,
    setFullName,
    setCvc,
    setExpireDate,
  } = props;

  // eslint-disable-next-line
  const [name, setName] = useState("");
  // eslint-disable-next-line
  const [isNameValid, setIsNameValid] = useState(false);
  const [isCreditNumberValid, setIsCreditNumberValid] = useState(false);
  const [isExpireDateValid, setIsExpireDateValid] = useState(false);
  const [isCvcValid, setIsCvcValid] = useState(false);

  useEffect(() => {

    setCardNumber(cardNumber.replace(/\s/g, "").trim());
    if (name !== "" && isCreditNumberValid && isExpireDateValid && isCvcValid) {
      let details = {
        cardNumber: cardNumber,
        cvc: cvc,
        expireDate: expireDate,
        fullName: fullName
      };
      setCardDetails(details);
    }
    // eslint-disable-next-line
  }, [fullName, isCreditNumberValid, isExpireDateValid, isCvcValid]);

  const handleOnChange = value => {
    value = value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    if (value.length === 19) {
      setCardNumber(value);
      setIsCreditNumberValid(true);
    }
  };

  return (
    <div className={"CreditCardForm"}>
      <div>
        <div>
          <div className={"CreditTitle"}>
            <p>Credit card information</p>
          </div>
        </div>
        <div className={"CreditContainter"}>
          <div className={"InputRow"}>
            <div>
              <div className={"CreditSubtitle"}>
                <p>Full Name</p>
              </div>

              {isNameValid ? (
                <div className={"Input"}>
                  <Input
                    placeholder={fullName}
                    size={"small"}
                    variant="outlined"
                    fullWidth={true}
                    className={GlobalStyle.modalTextInput}
                    onChange={event => {
                      const { value } = event.target;

                      setFullName(value);
                      // () => {checkIfValid(value)}
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <img src={ValidationImage} alt="" />
                      </InputAdornment>
                    }
                  />
                </div>
              ) : (
                <div className={"Input"}>
                  <Input
                    placeholder={fullName}
                    size={"small"}
                    variant="outlined"
                    fullWidth={true}
                    className={GlobalStyle.modalTextInput}
                    onChange={event => {
                      const { value } = event.target;
                      // () => {setFullName(value)}
                      // () => {checkIfValid(value)}
                      setFullName(value);
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div>
            <div className={"CreditSubtitle"}>
              <p>Card Number</p>
            </div>
            {isCreditNumberValid ? (
              <div className={"Input"}>
                <Input
                  inputProps={{
                    maxLength: 19
                  }}
                  placeholder={cardNumber.replace(/(.{4})/g, "$1 ").trim()}
                  size={"small"}
                  variant="outlined"
                  fullWidth={true}
                  className={GlobalStyle.modalTextInput}
                  onChange={event => {
                    const { value } = event.target;

                    setCardNumber(value);

                    //reformatInputField(event);
                    event.target.value = event.target.value
                      .replace(/(.{4})/g, "$1 ")
                      .trim();
                    if (event.target.value.length !== 19) {
                      setIsCreditNumberValid(false);
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={ValidationImage} alt="" />
                    </InputAdornment>
                  }
                />
              </div>
            ) : (
              <div className={"Input"}>
                <Input
                  inputProps={{
                    maxLength: 19
                  }}
                  placeholder={cardNumber.replace(/(.{4})/g, "$1 ").trim()}
                  size={"small"}
                  variant="outlined"
                  fullWidth={true}
                  className={GlobalStyle.modalTextInput}
                  onChange={event => {
                    const { value } = event.target;
                    handleOnChange(value);
                  }}
                />
              </div>
            )}
          </div>
          <div className={"BankAccountRow"}>
            <div className={"Column"}>
              <div className={"CreditSubtitle"}>
                <p>Expire Date</p>
              </div>
              {isExpireDateValid ? (
                <div className={"SmallInput"}>
                  <Input
                    inputProps={{
                      maxLength: 5
                    }}
                    size={"small"}
                    variant="outlined"
                    placeholder={expireDate}
                    fullWidth={true}
                    className={GlobalStyle.modalTextInput}
                    onChange={event => {
                      const { value } = event.target;
                      setExpireDate(value);
                      if (value.length <= 3) {
                        event.target.value = event.target.value
                          .replace(/[^\dA-Z]/g, "")
                          .replace(/(.{2})/g, "$1/ ")
                          .trim();
                      }
                      if (value.length !== 5) {
                        setIsExpireDateValid(false);
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <img src={ValidationImage} alt="" />
                      </InputAdornment>
                    }
                  />
                </div>
              ) : (
                <div className={"SmallInput"}>
                  <Input
                    inputProps={{
                      maxLength: 5
                    }}
                    size={"small"}
                    variant="outlined"
                    placeholder={expireDate}
                    fullWidth={true}
                    className={GlobalStyle.modalTextInput}
                    onChange={event => {
                      const { value } = event.target;
                      setExpireDate(value);
                      if (value.length <= 3) {
                        event.target.value = event.target.value
                          .replace(/[^\dA-Z]/g, "")
                          .replace(/(.{2})/g, "$1/")
                          .trim();
                      }
                      if (value.length === 5) {
                        setIsExpireDateValid(true);
                        setExpireDate(value);
                      }
                    }}
                  />
                </div>
              )}
            </div>
            &nbsp; &nbsp; &nbsp;
            <div className={"Column"}>
              <div className={"CreditSubtitle"}>
                <p>CVC</p>
              </div>
              {isCvcValid ? (
                <div className={"SmallInput"}>
                  <Input
                    inputProps={{
                      maxLength: 3
                    }}
                    maximum={3}
                    size={"small"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder={cvc}
                    className={GlobalStyle.modalTextInput}
                    onChange={event => {
                      const { value } = event.target;
                      setCvc(value);
                      console.log(value);
                      event.target.value = event.target.value
                        .replace(/[^\dA-Z]/g, "")
                        .trim();
                      if (event.target.value.length !== 3) {
                        setIsCvcValid(false);
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <img src={ValidationImage} alt="" />
                      </InputAdornment>
                    }
                  />
                </div>
              ) : (
                <div className={"SmallInput"}>
                  <Input
                    maximum={3}
                    inputProps={{
                      maxLength: 3
                    }}
                    size={"small"}
                    variant="outlined"
                    fullWidth={true}
                    placeholder={cvc}
                    className={GlobalStyle.modalTextInput}
                    onChange={event => {
                      const { value } = event.target;
                      setCvc(value);
                      console.log(value);
                      event.target.value = event.target.value
                        .replace(/[^\dA-Z]/g, "")
                        .trim();
                      if (value.length === 3) {
                        setIsCvcValid(true);
                        setCvc(value);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccountForm;
