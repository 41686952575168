import { ButtonPink } from '@Components/ButtonPink';
import React from 'react';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

const DecisionButtonsStep = props => {
  const {
    isVisible,
    setNextStep,
    licenseIsValid,
    publicAppearanceIsValid,
    docIsValid,
    doc2IsValid,
  } = props;

  return (
    <div style={{ display: `${isVisible ? `block` : `none`}` }}>
      <ButtonPink
        onClick={() => setNextStep(1)}
        variant={'contained'}
        color={'primary'}
        fullWidth={true}
        disableElevation
      >
        Certificate of incorporation
        {licenseIsValid && <DoneOutlineIcon className={`upArrow`} />}
      </ButtonPink>

      <ButtonPink
        onClick={() => setNextStep(2)}
        variant={'contained'}
        color={'primary'}
        fullWidth={true}
        disableElevation
      >
        Board resolution for opening current account
        {publicAppearanceIsValid && <DoneOutlineIcon className={`upArrow`} />}
      </ButtonPink>

      <ButtonPink
        onClick={() => setNextStep(3)}
        variant={'contained'}
        color={'primary'}
        fullWidth={true}
        disableElevation
      >
        MOA & AOA
        {docIsValid && <DoneOutlineIcon className={`upArrow`} />}
      </ButtonPink>

      <ButtonPink
        onClick={() => setNextStep(4)}
        variant={'contained'}
        color={'primary'}
        fullWidth={true}
        disableElevation
      >
        Share holding patter of the company
        {doc2IsValid && <DoneOutlineIcon className={`upArrow`} />}
      </ButtonPink>
    </div>
  );
};

export default DecisionButtonsStep;
