import { GlobalMUIStyles } from "@Styled/AppStyle";
import commaNumber from "comma-number";
import React, { useState } from "react";
import NumberFormat from "react-number-format";

const BuyModalContent = (props) => {
  const GlobalStyle = GlobalMUIStyles({ color: "cadetblue" });
  const [rightsValue, setRightsValue] = useState(0);

  const {
    offeringPrice,
    setRightsAmout,
    rightsAmount,
  } = props;

  return (
    <div className={"buyModalContent"}>
      <div>
        <p>NUMBER OF RIGHTS</p>

        <NumberFormat
          thousandSeparator={true}
          id="estimatedValue"
          name="estimatedValue"
          className={GlobalStyle.modalTextInput}
          placeholder="e.g. 100,000"
          // prefix={"$ "}
          value={rightsAmount}
          onValueChange={(values) => {
            const { floatValue } = values;

            setRightsAmout(floatValue);
            setRightsValue(floatValue * offeringPrice);
          }}
        />
        <p>RIGHTS VALUE</p>
        <h5>&nbsp;&nbsp; $ {commaNumber(rightsValue)}</h5>
      </div>
    </div>
  );
};

export default BuyModalContent;
