import { SideMenu } from "@Components/SideMenu";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
import { Sidebar, Topbar } from '../Components';


const WithSideMenu = props => {

  const {
    currentPage,
    children,
    asPath,
  } = props;

  const theme = useTheme();

  const [openSidebar, setOpenSidebar] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const shouldOpenSidebar = isDesktop ? false : openSidebar;
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  return (
    <Grid container className={"pageGrid"}>
      {isDesktop &&
        <Grid item className={"pageGrid__menu"} md={2}>
          <SideMenu currentPage={currentPage} asPath={asPath} />
        </Grid>
      }

      {!isDesktop &&
        <>
          <Topbar onSidebarOpen={handleSidebarOpen} />
          <Sidebar onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={isDesktop ? "persistent" : "temporary"}
            currentPage={currentPage}
            asPath={asPath}
          />
        </>
      }
      <Grid item className={"pageGrid__content"} md={10} xs={12}>
        {/*<GlobalNav currentPage={currentPage} updateWallet={updateWallet} />*/}
        <div className={"content"}>{children}</div>
      </Grid>
    </Grid >
  );
};

export default WithSideMenu;
