import Grid from "@material-ui/core/Grid";
import commaNumber from "comma-number";
import React from "react";

const InvestPortofolioRightsEntry = (props) => {
  const {
    id,
    forActive,
    item,
    setSelectedEntry,
    selectedEntry,
    showChart,
    handleProjectId
  } = props;

  const handleSelect = () => {
    setSelectedEntry(id);
    showChart(id);
    if (window.location.pathname === "/portofolio-rights") {
      handleProjectId(id)
    }
  };

  if (selectedEntry === id) {
    return (
      <Grid
        container
        className={"selectedInvestPortofolioRightsEntry"}
        onClick={() => handleSelect()}
      >
        <Grid item xs={2} style={{ textAlign: "start" }}>
          <img src={item && item.artist.imageUrl} style={{ width: "47px", height: "47px" }} alt="" />
          {item && item.artist.name}
        </Grid>
        <Grid item xs={2}>
          {item && item.title}
        </Grid>
        <Grid item xs={forActive ? 1 : 2} style={{ textAlign: "center" }} className={"rights"}>
          {item && item.projectType}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }} className={"rights"}>
          {/* {rights} */}
          {commaNumber(item && item.numberOfRightsSold)}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }} className={"value"}>
          $ {commaNumber(item && item.pricePerUnit)}
        </Grid>
        {forActive ? (
          <Grid item xs={1} style={{ textAlign: "center" }} className={"value"}>
            {commaNumber(item && item.numberOfUnitsSold)}
          </Grid>
        ) : null}
        {forActive ? (
          <Grid item xs={1} style={{ textAlign: "center" }} className={"value"}>
            $ {commaNumber(item && item.pricePerUnit)}
          </Grid>
        ) : null}

      </Grid>
    );
  } else {
    return (
      <Grid
        container
        className={"investPortofolioRightsEntry"}
        onClick={() => handleSelect()}
      >
        <Grid item xs={2} style={{ textAlign: "start" }}>
          <img src={item && item.artist.imageUrl} style={{ width: "47px", height: "47px" }} alt="" />
          {item && item.artist.name}
        </Grid>
        <Grid item xs={2} >
          {item && item.title}
        </Grid>
        <Grid item xs={forActive ? 1 : 2} style={{ textAlign: "center" }} className={"rights"}>
          {item && item.projectType}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }} className={"rights"}>
          {commaNumber(item && item.numberOfRightsSold)}
        </Grid>
        <Grid item xs={2} style={{ textAlign: "center" }} className={"value"}>
          $ {commaNumber(item && item.pricePerUnit)}
        </Grid>
        {forActive ? (
          <Grid item xs={1} style={{ textAlign: "center" }} className={"value"}>
            {commaNumber(item && item.numberOfUnitsSold)}
          </Grid>
        ) : null}
        {forActive ? (
          <Grid item xs={1} style={{ textAlign: "center" }} className={"value"}>
            $ {commaNumber(item && item.pricePerUnit)}
          </Grid>
        ) : null}

      </Grid>
    );
  }
};

export default InvestPortofolioRightsEntry;
