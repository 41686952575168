import { routes } from "@Helpers";

import * as V from "@Views";

routes.add("/login", V.Login, "Login", false);
routes.add("/logout", V.Logout, "Logout", false);
routes.add("/register", V.Register, "Register", false);
routes.add("/terms", V.Terms, "Terms", false);
routes.add("/privacy", V.Privacy, "Privacy", false);
routes.add("/privacy", V.Privacy, "Privacy", false);
routes.add("/account/profile", V.AccountProfile, "Account Profile");
routes.add("/account/wallet", V.AccountWallet, "Account Wallet");
routes.add(
  "/wallet/statement",
  V.AccountWalletStatement,
  "Account Wallet Statement"
);
routes.add("/invest/project", V.InvestProjects, "Invest Projects");
routes.add("/invest/all", V.InvestAll, "Invest All");
routes.add("/invest/album", V.InvestAlbum, "Invest Album");
routes.add("/invest/career", V.InvestCareer, "Invest Career");
routes.add("/invest/song", V.InvestSong, "Invest Song");
routes.add("/invest/tour", V.InvestTour, "Invest Tour");
routes.add("/invest/new", V.InvestNew, "Invest New");
routes.add("/invest/recommendedInvests", V.InvestBestValue, "Invest Best Value");
routes.add(
  "/invest/bestPerformers",
  V.InvestBestPerformers,
  "Invest Best Performers"
);
routes.add("/invest-into/:entry", V.Invest, "Invest");
routes.add("/trade", V.Trade, "Trade");
routes.add(
  "/portofolio-rights",
  V.InvestPortofolioRights,
  "Invest Portfolio Rights"
);
routes.add(
  "/portofolio-royalties",
  V.InvestPortofolioRoyalties,
  "Invest Portfolio Royalties"
);
routes.add("/premium-content", V.PremiumContent, "Premium Content");
routes.add("/watchlist", V.InvestWatch, "Invest Watch");
routes.add(
  "/go-platinum/individual",
  V.RegisterProIndividual,
  "Register Pro Individual"
);
routes.add("/go-platinum/entity", V.RegisterProEntity, "Register Pro Entity");
routes.add("/go-platinum", V.RegisterProLanding, "Register Pro Landing");
routes.add("/media", V.Media, "Media");
routes.add("/mediaForYou", V.MediaForYou, "Media for you");
routes.add("/mediaEditorsPick", V.MediaEditorsPick, "Media Editor pick");
routes.add("/mediaTopStories", V.MediaTopStories, "Media Top Stories");
routes.add("/market", V.Market, "Market");
routes.add("/cart", V.MarketCart, "Market Cart");
routes.add("/services", V.MarketServices, "Market Services");
routes.add("/label", V.ServicesLabel, "Services Label");
routes.add("/producer", V.ServicesProducer, "Services Producer");
routes.add("/filmStudio", V.ServicesFilmStudio, "Services Film Studio");
routes.add(
  "/eventOrganizer",
  V.ServicesEventOrganizer,
  "Services Event Organizer"
);
routes.add("/seeAllTracks", V.SeeAllTracks, "See All Tracks");
routes.add("/seeAllEvents", V.SeeAllEvents, "See All Events");
routes.add("/marketServices2", V.MarketServices2, "Market Services 2");
routes.add("/marketServices1/:entry", V.MarketServices1, "Market Services 1");
routes.add("/events", V.MarketEvents, "Market Events");
routes.add("/eventsCart", V.EventsCart, "Events Cart");
routes.add("/createProject", V.CreateProject, "Create Project");
routes.add("/portofolio-myProjects", V.MyProjects, "My Projects");
routes.add(
  "/portfolio-projects/active",
  V.PortfolioProjectsActive,
  "Active Portfolio Projects"
);
routes.add(
  "/portfolio-projects/drafts",
  V.PortfolioProjectsDrafts,
  "Drafts Portfolio Projects"
);
routes.add(
  "/portfolio-projects/:entry",
  V.PortfolioProjectsEntry,
  "Portfolio Project"
);
routes.add("/artistProjects", V.ArtistProjects, "Artist Projects");
routes.add("/artistStocks", V.ArtistStocks, "Artist Stocks");

routes.add("/", V.Home, "Home", false);

export default routes.entries;
